import React, { useEffect } from 'react';
import './ConfirmPasswordPage.scss';
import { useTranslation, Trans } from 'react-i18next';
import HookedField from '../../../components/HookedField';
import { FormProvider, useForm } from 'react-hook-form';
import { FieldsTypes } from 'components/HookedField/types';
import { IConfirmationResetPasswordData } from 'store/auth/types';
import Button from 'components/ui/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Icon from '../../../components/ui/Icon';
import { IconsTypes } from '../../../components/ui/Icon/types';
import { confirmPasswordSchema } from 'utils/shemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { confirmationResetPassword, resetPassword } from 'store/auth/authSlice';
import { useTimer, useAppSelector, useAppDispatch } from 'hooks';
import { getResendTime } from '../../../utils/functions';
import Timer from 'components/ui/Timer';
import { Spin } from 'antd';

const ConfirmPasswordPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { resendDate, confirmationResetPasswordLoading, resetPasswordLoading } = useAppSelector(
    (state) => state.auth,
  );

  const [resendTime, setResendTime] = useTimer({
    startTime: getResendTime(resendDate),
  });

  const email = searchParams.get('email');
  const code = searchParams.get('code');

  const methods = useForm<IConfirmationResetPasswordData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    resolver: yupResolver(confirmPasswordSchema),
    defaultValues: {
      email: email || '',
      code: code || '',
    },
  });

  useEffect(() => {
    if (!resendDate) {
      return;
    }
    // @ts-ignore
    setResendTime(getResendTime(resendDate));
  }, [resendDate]);

  useEffect(() => {
    if (!email) {
      navigate('/auth/reset-password');
    }
  }, [email]);

  const submitHandler = async (data: IConfirmationResetPasswordData) => {
    await dispatch(confirmationResetPassword({ data, navigate }));
  };

  const resendCodeHandler = async () => {
    if (!email) {
      return;
    }
    await dispatch(resetPassword({ data: { email }, navigate }));
  };

  const {
    formState: { isValid },
  } = methods;

  return (
    <div className='confirmPasswordPage'>
      <h2 className='confirmPasswordPage-title'>{t('CONFIRM_PASSWORD.TITLE')}</h2>
      <div className='confirmPasswordPage-description'>
        <Trans
          i18nKey='CONFIRM_PASSWORD.DESCRIPTION'
          components={{
            p: <p></p>,
            br: <br />,
          }}
          values={{ email }}
        />
      </div>
      <FormProvider {...methods}>
        <Spin spinning={confirmationResetPasswordLoading}>
          <form className='form' autoComplete='off' onSubmit={methods.handleSubmit(submitHandler)}>
            <HookedField
              name='code'
              type={FieldsTypes.VERIFICATION}
              label={t('CONFIRM_PASSWORD.FIELDS.CODE')}
              placeholder=''
              validChars='0-9'
              length={6}
              autoFocus={true}
            />
            <Button
              className='confirmPasswordPage-btn'
              htmlType='submit'
              type='primary'
              fullWidth={true}
              disabled={!isValid}
            >
              {t('CONFIRM_PASSWORD.BUTTON_SUBMIT_NAME')}
            </Button>
          </form>
        </Spin>
      </FormProvider>

      <div className='confirmPasswordPage-resendBox'>
        <p className='confirmPasswordPage-resendDescription'>
          {t('CONFIRM_PASSWORD.RESEND_DESCRIPTION')}
        </p>
        <Spin spinning={resetPasswordLoading}>
          <Button
            className='confirmPasswordPage-resendBtn'
            type='link-secondary'
            disabled={resendTime !== 0}
            onClick={resendCodeHandler}
          >
            {t('CONFIRM_PASSWORD.BUTTON_RESEND_NAME')}
            {resendTime !== 0 ? <Timer time={resendTime as number} /> : null}
          </Button>
        </Spin>
      </div>
    </div>
  );
};

export default ConfirmPasswordPage;
