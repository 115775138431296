import React from 'react';
import { IUser } from '../../../../../utils/types';
import { getUserFullName } from '../../../../../utils/functions';
import Badge from '../../../../../components/ui/Badge';
import { BadgeTypes } from '../../../../../components/ui/Badge/types';

import './UserItem.scss';

interface IUserItemProps {
  user: IUser;
  status?: {
    text: string;
    type: BadgeTypes;
  };
}

const UserItem: React.FC<IUserItemProps> = ({ user, status }) => {
  return (
    <div className='userItem'>
      <div className='userItem-text'>
        <div className='userItem-name'>{getUserFullName(user)}</div>
        <div className='userItem-email'>{user.email}</div>
      </div>
      {status ? (
        <div className='userItem-status'>
          <Badge text={status.text} type={status.type} />
        </div>
      ) : null}
    </div>
  );
};

export default UserItem;
