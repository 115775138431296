import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import DocumentTypesService from './documentTypesService';

import { IDocumentType } from '../../utils/types';
import { IGetDocumentTypesData } from './types';
import { toast } from 'react-toastify';
import i18n from '../../i18n';

interface IDocumentsState {
  types: IDocumentType[];
  getTypesLoading: boolean;
}

const initialState = {
  types: [],
  getTypesLoading: false,
} as IDocumentsState;

export const getDocumentTypes = createAsyncThunk(
  'documentTypes/getDocumentsTypes',
  async (data: IGetDocumentTypesData, thunkAPI) => {
    try {
      const response = await DocumentTypesService.getDocumentTypes(data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

const documentTypesSlice = createSlice({
  name: 'documentTypes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getDocumentTypes
    builder
      .addCase(getDocumentTypes.pending, (state: IDocumentsState) => {
        state.getTypesLoading = true;
      })
      .addCase(getDocumentTypes.fulfilled, (state: IDocumentsState, action) => {
        state.types = action.payload;
        state.getTypesLoading = false;
      })
      .addCase(getDocumentTypes.rejected, (state: IDocumentsState, action) => {
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.getTypesLoading = false;
      });
  },
});

export default documentTypesSlice.reducer;
