import React, { ChangeEvent, FC } from 'react';
import { Input } from 'antd';
import './Search.scss';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';
import { IconsTypes } from '../Icon/types';

interface ISearchProps {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const Search: FC<ISearchProps> = ({ onChange }) => {
  const { t } = useTranslation();
  return (
    <div className='searchWrapper'>
      <Input
        autoFocus
        placeholder={t('USERS.SEARCH.PLACEHOLDER')}
        onChange={onChange}
        className='search'
        prefix={<Icon icon={IconsTypes.SEARCH} />}
      />
    </div>
  );
};
