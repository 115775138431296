import React, { useRef, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PaginationProps, Space } from 'antd';
// eslint-disable-next-line no-duplicate-imports
import { Spin, Table } from 'antd';
import { IPageTable } from './types';
import Icon from '../ui/Icon';
import { IconsTypes } from '../ui/Icon/types';
import en from '../../global/translations/en.json';

import './PageTable.scss';
import Badge from '../ui/Badge';
import Button from '../ui/Button';
import Search from 'components/ui/Search';

const PageTable: FC<IPageTable> = ({
  columns,
  data,
  pagination,
  onRow,
  header,
  onChange,
  loading,
  onSearch,
  filters: { filters, onRemove, translationKey } = {},
}) => {
  const { t } = useTranslation();
  type dataItem = (typeof data)[0];
  const pageRef = useRef<HTMLDivElement>(null);

  const scrollContentToTop = () => {
    if (pageRef.current === null) {
      return;
    }
    const tableWrapper = pageRef.current.querySelector('.ant-table');
    tableWrapper && tableWrapper.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === 'prev') {
      return (
        <a className='pagination-button'>
          <Icon icon={IconsTypes.ARROW_LEFT} size={24} />
          <span>{t('BUTTONS.PREVIOUS')}</span>
        </a>
      );
    }
    if (type === 'next') {
      return (
        <a className='pagination-button'>
          <span>{t('BUTTONS.NEXT')}</span>
          <Icon icon={IconsTypes.ARROW_RIGHT} size={24} />
        </a>
      );
    }
    return originalElement;
  };

  return (
    <div className='pageTable' ref={pageRef}>
      {header && (
        <div className='pageTable-header'>
          <div className='pageTable-header-text'>
            <h4 className='pageTable-header-title'>
              {header.title}
              {header?.meta && (
                <Spin spinning={loading} size='small'>
                  <Badge className='pageTable-header-meta' text={header.meta} />
                </Spin>
              )}
            </h4>
            {header?.description ? (
              <div className='pageTable-header-description'>{header.description}</div>
            ) : null}
          </div>
          <Space>
            {onSearch && <Search onChange={onSearch} />}
            {header?.button && React.cloneElement(header.button)}
          </Space>
        </div>
      )}
      {Boolean(filters?.length) && (
        <div className='pageTable-filters'>
          <Button onClick={() => onRemove?.()} className='pageTable-filters-btn' type='tag-inverse'>
            {t('BUTTONS.CLEAR_ALL')}
          </Button>
          {filters?.map(({ filter, value }) => (
            <Button
              onClick={() => onRemove?.({ [filter]: undefined })}
              key={value}
              className='pageTable-filters-btn'
              type='tag'
            >
              <span>
                {translationKey ? `${t(`${translationKey}.${value}` as keyof typeof en)}` : value}
              </span>
              <Icon viewBox='0 0 10 10' size={10} icon={IconsTypes.CLOSE} />
            </Button>
          ))}
        </div>
      )}
      <Table<dataItem>
        onRow={onRow}
        columns={columns}
        dataSource={data}
        tableLayout='fixed'
        pagination={{
          ...pagination,
          hideOnSinglePage: true,
          itemRender,
          position: ['bottomCenter'],
          showSizeChanger: false,
        }}
        rowKey={(record) => record.id}
        onChange={(paginate, sort, filter) => {
          scrollContentToTop();
          onChange?.(paginate, sort, filter);
        }}
        loading={loading}
        scroll={{ y: 100 }}
        className='pageTable-table'
      />
    </div>
  );
};

export default PageTable;
