import React, { FC, useEffect, useMemo, useState } from 'react';
import { Image, Upload } from 'antd';
import Icon from '../../ui/Icon';
import { IconsTypes } from '../../ui/Icon/types';
import { useTranslation } from 'react-i18next';
import './AvatarUploader.scss';
import { useFormContext, useFormState } from 'react-hook-form';
import { get } from 'lodash';
import { beforeAvatarUpload } from './functions';
import ErrorMessage from '../../ui/ErrorMessage';
import { RcFile } from 'antd/es/upload/interface';
import { getBase64 } from '../../../utils/functions';
import ImgCrop from 'antd-img-crop';
import { IUpdateProfileCoverData } from '../../../store/profile/types';

interface IAvatarUploader {
  name: string;
  onSubmit: (data: IUpdateProfileCoverData) => void;
}

const AvatarUploader: FC<IAvatarUploader> = ({ name, onSubmit }) => {
  const { t } = useTranslation();

  const { register, setValue, getValues, setError, handleSubmit } = useFormContext();
  const { errors } = useFormState();
  const value = getValues(name) as IUpdateProfileCoverData['file'];
  const [previewFile, setPreviewFile] = useState<string>();

  const fileList = useMemo(
    () =>
      value
        ? [
            {
              uid: typeof value === 'object' ? value.uid : value,
              name: typeof value === 'object' ? value.name : value,
              originFileObj: typeof value === 'object' ? value : undefined,
              url: typeof value === 'string' ? value : undefined,
            },
          ]
        : [],
    [value],
  );
  const error = get(errors, name);

  const errorHandler = (message: string) => {
    setError(name, { message: message }, { shouldFocus: true });
  };

  const setValueHandler = (file: IUpdateProfileCoverData['file']) => {
    setValue(name, file, { shouldValidate: true, shouldTouch: true, shouldDirty: true });
    handleSubmit(onSubmit)();
  };

  const previewHandler = (preview: boolean) => {
    if (!preview) return setPreviewFile(undefined);
    if (!fileList[0]) return;

    if (fileList[0].originFileObj) {
      getBase64(fileList[0].originFileObj, (file) => setPreviewFile(file));
    } else {
      setPreviewFile(fileList[0].url);
    }
  };

  useEffect(() => {
    register(name);
  }, []);

  return (
    <div className='avatar-uploader-wrapper'>
      <ImgCrop
        rotate
        beforeCrop={(file) => beforeAvatarUpload(file, errorHandler)}
        shape='round'
        modalOk={t('BUTTONS.SAVE')}
        modalCancel={t('BUTTONS.CANCEL')}
        modalTitle={t('PROFILE.CROP_IMAGE_TITLE')}
        modalClassName='avatar-uploader-cropper'
      >
        <Upload
          name={name}
          multiple={false}
          listType='picture-card'
          className='avatar-uploader'
          accept='.jpeg,.jpg,.png,.heic'
          beforeUpload={(file) => beforeAvatarUpload(file, errorHandler)}
          customRequest={({ file, onSuccess }) => {
            setValueHandler(file as RcFile);
            onSuccess?.('OK');
          }}
          onPreview={() => previewHandler(true)}
          onRemove={() => setValueHandler(undefined)}
          fileList={fileList}
        >
          {!value && (
            <div>
              <Icon icon={IconsTypes.PLUS} />
              <p className='avatar-uploader-text'>{t('BUTTONS.UPLOAD')}</p>
            </div>
          )}
        </Upload>
      </ImgCrop>
      {error?.message && (
        <ErrorMessage className='avatar-error' message={error.message as string} />
      )}
      <Image
        style={{ display: 'none' }}
        preview={{
          visible: Boolean(previewFile),
          destroyOnClose: true,
          src: previewFile,
          onVisibleChange: previewHandler,
        }}
      />
    </div>
  );
};

export default AvatarUploader;
