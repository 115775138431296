import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import NotFoundImage from './components/NotFoundImage/NotFoundImage';
import './NotFoundPage.scss';
import Button from 'components/ui/Button';

const NotFoundPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className='notFoundPage'>
      <div className='notFoundPage-container'>
        <div className='notFoundPage-imgBox'>
          <NotFoundImage isInversed={false} />
        </div>
        <h2 className='notFoundPage-title'>{t('NOT_FOUND.TITLE')}</h2>
        <Trans
          i18nKey='NOT_FOUND.DESCRIPTION'
          components={{
            br: <br />,
            p: <p className='notFoundPage-description'></p>,
          }}
        />
        <Button onClick={() => navigate('/main/documents')}>{t('NOT_FOUND.BUTTON_NAME')}</Button>
      </div>
    </div>
  );
};

export default NotFoundPage;
