import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { IDataType, IDocumentsFilter, IDocumentsOptions } from '../../../../store/documents/types';
import { Card, Input, Radio, Space, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {
  DocumentSignedStatuses,
  DocumentStatuses,
  DocumentVerificationStatuses,
} from '../../../../utils/types';
import Badge from '../../../../components/ui/Badge';
import { Link, NavigateFunction } from 'react-router-dom';
import {
  getDocumentSignBadge,
  getDocumentStatusBadge,
  getDocumentVerificationBadge,
  getFormattedDate,
} from '../../../../utils/functions';
import { MONTH_FORMAT } from '../../../../utils/constants';
import Button from '../../../../components/ui/Button';
import Icon from '../../../../components/ui/Icon';
import { IconsTypes } from '../../../../components/ui/Icon/types';
import { TFunction } from 'react-i18next';
import { TFiltersChangeHandler, TTableChangeHandler } from '../types';
import { remindUserModal } from './modals';
import en from '../../../../global/translations/en.json';
const { Text } = Typography;

export const getDocsColumns = (
  t: TFunction,
  navigate: NavigateFunction,
  tableChangeHandler: TTableChangeHandler,
  filtersChangeHandler: TFiltersChangeHandler,
  filters: IDocumentsFilter | null,
  { sorter, search }: IDocumentsOptions,
): ColumnsType<IDataType> => [
  {
    title: t('DOCUMENTS.LABELS.FILE_NAME'),
    key: 'name',
    dataIndex: 'name',
    sorter: true,
    sortOrder: sorter?.field === 'name' ? sorter.order : null,
    filterDropdown: () => {
      return (
        <div className='documentsPage-dropdown'>
          <Input
            autoFocus
            placeholder='Type name here'
            value={search}
            onChange={(e) => {
              tableChangeHandler({
                search: e.currentTarget.value,
              });
            }}
          />
        </div>
      );
    },
    filterIcon: () => <SearchOutlined />,
    render: (_, document) => (
      <div className='pageTable-item pageTable-item--filename'>
        {document?.name && (
          <div className='pageTable-item-title'>
            <Text ellipsis={{ tooltip: document.name }}>{document.name}</Text>
          </div>
        )}
        {document?.type?.name && (
          <div className='pageTable-item-caption'>
            <Text ellipsis={{ tooltip: document.type.name }}>{document.type.name}</Text>
          </div>
        )}
      </div>
    ),
    shouldCellUpdate: () => false,
  },
  {
    title: t('DOCUMENTS.LABELS.STATUS'),
    dataIndex: 'status',
    key: 'status',
    filterDropdown: ({ confirm }) => {
      return (
        <Card size='small'>
          <Radio.Group
            value={filters?.status}
            onChange={(e) => {
              filtersChangeHandler({ status: e.target.value });
              confirm();
            }}
          >
            <Space direction='vertical'>
              {Object.keys(DocumentStatuses).map((status) => (
                <Radio key={status} value={status}>
                  {`${t(`DOCUMENTS.FILTERS.${status}` as keyof typeof en)}`}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Card>
      );
    },
    render: (status) => {
      return <Badge {...getDocumentStatusBadge({ status, t })} />;
    },
    shouldCellUpdate: () => false,
  },
  {
    title: t('DOCUMENTS.LABELS.VERIFICATION_STATUS'),
    dataIndex: 'verificationStatus',
    key: 'verificationStatus',
    filterDropdown: ({ confirm }) => {
      return (
        <Card size='small'>
          <Radio.Group
            value={filters?.verificationStatus}
            onChange={(e) => {
              filtersChangeHandler({ verificationStatus: e.target.value });
              confirm();
            }}
          >
            <Space direction='vertical'>
              {Object.keys(DocumentVerificationStatuses).map((status) => (
                <Radio key={status} value={status}>
                  {`${t(`DOCUMENTS.FILTERS.${status}` as keyof typeof en)}`}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Card>
      );
    },
    render: (verificationStatus) => {
      return <Badge {...getDocumentVerificationBadge({ status: verificationStatus, t })} />;
    },
    shouldCellUpdate: () => false,
  },
  {
    title: t('DOCUMENTS.LABELS.VERIFICATOR'),
    dataIndex: 'supervisor',
    key: 'supervisor',
    render: (supervisor) => {
      if (!supervisor) {
        return null;
      }
      return (
        <Link to={`/main/documents/user/${supervisor.id}`}>
          <Text ellipsis={{ tooltip: supervisor.email }}>{supervisor.email}</Text>
        </Link>
      );
    },
    shouldCellUpdate: () => false,
  },
  {
    title: t('DOCUMENTS.LABELS.SIGNATURE_STATUS'),
    dataIndex: 'signStatus',
    key: 'signStatus',
    filterDropdown: ({ confirm }) => {
      return (
        <Card size='small'>
          <Radio.Group
            value={filters?.signedStatus}
            onChange={(e) => {
              filtersChangeHandler({ signedStatus: e.target.value });
              confirm();
            }}
          >
            <Space direction='vertical'>
              {Object.keys(DocumentSignedStatuses).map((status) => (
                <Radio key={status} value={status}>
                  {`${t(`DOCUMENTS.FILTERS.${status}` as keyof typeof en)}`}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Card>
      );
    },
    render: (signStatus) => {
      return <Badge {...getDocumentSignBadge({ status: signStatus, t })} />;
    },
    shouldCellUpdate: () => false,
  },
  {
    title: t('DOCUMENTS.LABELS.OWNER'),
    dataIndex: 'owner',
    key: 'owner',
    render: (owner) => {
      if (!owner) {
        return null;
      }
      return (
        <Link to={`/main/documents/user/${owner.id}`}>
          <Text ellipsis={{ tooltip: owner.email }}>{owner.email}</Text>
        </Link>
      );
    },
    shouldCellUpdate: () => false,
  },
  {
    title: t('DOCUMENTS.LABELS.UPDATED'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (date) => getFormattedDate(date, MONTH_FORMAT),
    sorter: true,
    sortOrder: sorter?.field === 'updatedAt' ? sorter.order : null,
    shouldCellUpdate: () => false,
  },
  {
    title: t('DOCUMENTS.LABELS.ACTIONS'),
    key: 'actions',
    render: (_, record) => {
      return (
        <Space>
          <Button justIcon={true} onClick={() => navigate(`/main/documents/view/${record.id}`)}>
            <Icon icon={IconsTypes.SHOW} size={20} />
          </Button>
          {/* FOR FUTURE FEATURES */}
          {/* <Button justIcon={true} onClick={() => remindUserModal(record.id, t)}>*/}
          {/*  <Icon icon={IconsTypes.REMINDER} size={20} viewBox='0 0 20 20' />*/}
          {/* </Button>*/}
        </Space>
      );
    },
    shouldCellUpdate: () => false,
  },
];
