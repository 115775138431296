import React from 'react';
import classNames from 'classnames';
import './Button.scss';
import { Spin } from 'antd';

interface IButtonProps {
  children: React.ReactNode;
  onClick?: (args?: any) => void;
  className?: string;
  type?:
    | 'primary'
    | 'secondary'
    | 'link'
    | 'error'
    | 'link-secondary'
    | 'error-outline'
    | 'link-inverse'
    | 'tag'
    | 'tag-inverse';
  htmlType?: 'button' | 'submit';
  justIcon?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  spinning?: boolean;
}

const Button: React.FC<IButtonProps> = ({
  children,
  onClick,
  className,
  type = 'primary',
  htmlType = 'button',
  disabled = false,
  justIcon = false,
  fullWidth,
  spinning = false,
}) => {
  const classes = classNames({
    btn: true,
    [className as string]: !!className,
    [`btn--${type}`]: true,
    ['btn--fullWidth']: fullWidth,
    ['btn--justIcon']: justIcon,
  });

  return (
    <Spin spinning={spinning}>
      <button className={classes} onClick={onClick} type={htmlType} disabled={disabled}>
        {children}
      </button>
    </Spin>
  );
};

export default Button;
