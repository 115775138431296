import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import HookedField from '../HookedField/HookedField';
import { FieldsTypes } from 'components/HookedField/types';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { IUser, UserRoles } from '../../utils/types';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '../ui/Button';
import { Col, Row, Spin } from 'antd';

import './UserForm.scss';
import { userSchema } from './schema';
import { IUserFormFields } from './types';
import { createUser, updateUser } from 'store/users/usersSlice';

interface IUserFormProps {
  defaultValues?: IUser | null;
  onCloseModal: () => void;
  id?: string;
  resetId?: () => void;
}

const UserForm: FC<IUserFormProps> = ({ defaultValues, onCloseModal, id, resetId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { updateUserLoading } = useAppSelector((state) => state.users);
  const isAddForm = Boolean(!id);

  const roleOptions = [
    { value: UserRoles.USER, label: t('ROLES.USER') },
    { value: UserRoles.ADMIN, label: t('ROLES.ADMIN') },
  ];

  const initialValues = {
    name: defaultValues?.name || '',
    lastName: defaultValues?.lastName || '',
    email: defaultValues?.email || '',
    role: defaultValues?.role || UserRoles.USER,
  };

  const methods = useForm<IUserFormFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    resolver: yupResolver(userSchema),
    defaultValues: initialValues,
  });

  const {
    formState: { errors, isDirty, isValid },
  } = methods;

  useEffect(() => {
    const fieldWithError = document.querySelector('.invalid');
    if (!fieldWithError) {
      return;
    }
    fieldWithError.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }, [errors]);

  const resetForm = () => {
    methods.reset(initialValues);
  };

  const submitHandler = async (data: IUserFormFields) => {
    if (isAddForm) {
      await dispatch(createUser({ data }));
      onCloseModal();
      resetForm();
      return;
    }
    if (!id) {
      return;
    }
    await dispatch(updateUser({ data: { id, ...data } }));
    onCloseModal();
    resetId?.();
  };

  const cancelHandler = () => {
    if (isDirty) resetForm();
    onCloseModal();
  };

  return (
    <div className='userForm'>
      <FormProvider {...methods}>
        <Spin spinning={updateUserLoading}>
          <form className='form' autoComplete='off' onSubmit={methods.handleSubmit(submitHandler)}>
            <HookedField name='name' type={FieldsTypes.TEXT} label={t('LABELS.USER_NAME')} />
            <HookedField
              name='lastName'
              type={FieldsTypes.TEXT}
              label={t('LABELS.USER_LAST_NAME')}
            />
            <HookedField name='email' label={t('LABELS.EMAIL')} type={FieldsTypes.TEXT} />
            <HookedField
              name='role'
              type={FieldsTypes.SELECT}
              label={t('LABELS.USER_ROLE')}
              options={roleOptions}
              placeholder={t('LABELS.USER_ROLE')}
            />
            {isAddForm ? (
              <Row gutter={16}>
                <Col span={12}>
                  <Button type='secondary' fullWidth={true} onClick={cancelHandler}>
                    {t('BUTTONS.CANCEL')}
                  </Button>
                </Col>
                <Col span={12}>
                  <Button htmlType='submit' fullWidth={true} type='primary' disabled={!isValid}>
                    {t('BUTTONS.SAVE')}
                  </Button>
                </Col>
              </Row>
            ) : (
              <Row gutter={16}>
                <Col span={12}>
                  <Button type='secondary' fullWidth={true} onClick={resetForm} disabled={!isDirty}>
                    {t('BUTTONS.RESET')}
                  </Button>
                </Col>
                <Col span={12}>
                  <Button htmlType='submit' fullWidth={true} type='primary' disabled={!isDirty}>
                    {t('BUTTONS.SAVE')}
                  </Button>
                </Col>
              </Row>
            )}
          </form>
        </Spin>
      </FormProvider>
    </div>
  );
};

export default UserForm;
