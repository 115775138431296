import { TFunction } from 'react-i18next';

export const getTableHeading = ({ id, t }: { id?: string; t: TFunction }) => {
  switch (id) {
    case 'unverified':
      return {
        title: t('DOCUMENTS.UNVERIFIED.TITLE'),
        description: t('DOCUMENTS.UNVERIFIED.DESCRIPTION'),
      };
    case 'rejected':
      return {
        title: t('DOCUMENTS.REJECTED.TITLE'),
        description: t('DOCUMENTS.REJECTED.DESCRIPTION'),
      };
    case 'archived':
      return {
        title: t('DOCUMENTS.ARCHIVED.TITLE'),
        description: t('DOCUMENTS.ARCHIVED.DESCRIPTION'),
      };
    case 'outcome':
      return {
        title: t('DOCUMENTS.CREATED_BY_ME.TITLE'),
      };
    case 'income':
      return {
        title: t('DOCUMENTS.NEED_TO_SIGN.TITLE'),
      };
    default:
      return { title: t('DOCUMENTS.ALL.TITLE'), description: t('DOCUMENTS.ALL.DESCRIPTION') };
  }
};
