import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Dropdown, Menu, Row, Space, Switch } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { logOut } from '../../../store/auth/authSlice';
import { getUserAbbreviation } from '../../../utils/functions';
import { setTheme, toggleIsCollapsedSidebar } from '../../../store/app/appSlice';
import { ThemeTypes } from '../../../utils/types';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import Button from 'components/ui/Button';
import Icon from 'components/ui/Icon';
import { IconsTypes } from 'components/ui/Icon/types';

import './Header.scss';

const Header = () => {
  const { t } = useTranslation();
  const { theme, isCollapsedSidebar } = useAppSelector((state) => state.app);
  const { user } = useAppSelector((state) => state.profile);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const menu = {
    items: [
      {
        key: '1',
        label: (
          <Link to='/main/profile' replace={true}>
            <div className='dropdown-content'>
              {user && (
                <Avatar src={user.cover} className='header-actions-avatar' size='large'>
                  {getUserAbbreviation(user)}
                </Avatar>
              )}
              <p className='dropdown-text'>
                {user?.name} {user?.lastName}
                <br />
                <span className='dropdown-text-email'>{user?.email}</span>
              </p>
            </div>
          </Link>
        ),
      },
      {
        key: '2',
        label: (
          <Button
            className='header-actions-logout-btn'
            type='link-secondary'
            onClick={() => {
              dispatch(logOut({ navigate }));
            }}
          >
            <Icon className='header-actions-logout-btn-icon' icon={IconsTypes.LOGOUT} />
            {t('BUTTONS.LOG_OUT')}
          </Button>
        ),
      },
    ],
  };

  // const onChangeTheme = (checked: boolean) => {
  //   dispatch(setTheme(checked ? ThemeTypes.DARK : ThemeTypes.LIGHT));
  // };

  return (
    <header className='header'>
      <Row justify='space-between' style={{ marginBottom: '5px' }}>
        <div className='header-additional'>
          <Button
            className='header-button'
            type='primary'
            onClick={() => dispatch(toggleIsCollapsedSidebar())}
          >
            {isCollapsedSidebar ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
          {/* <Switch
          className='header-switcher'
          checked={theme === ThemeTypes.DARK}
          onChange={onChangeTheme}
          checkedChildren={t('THEMES.DARK')}
          unCheckedChildren={t('THEMES.LIGHT')}
        /> */}
        </div>
        <Space className='header-actions' size={24}>
          <Button onClick={() => navigate('/main/documents/add')} type='secondary'>
            <Icon icon={IconsTypes.PLANE} size={16} />
            <span>{t('DOCUMENTS.ADD_DOC')}</span>
          </Button>
          {user && (
            <Dropdown menu={menu} placement='bottomLeft' overlayStyle={{ justifySelf: 'center' }}>
              <Avatar src={user.cover} className='header-actions-avatar' size='large'>
                {getUserAbbreviation(user)}
              </Avatar>
            </Dropdown>
          )}
        </Space>
      </Row>
    </header>
  );
};
export default Header;
