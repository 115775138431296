import { showConfirmModal, showNoControlsModal } from '../../../../../utils/functions';
import { TFunction, Trans } from 'react-i18next';
import {
  archiveDocument,
  deleteDocument,
  rejectDocument,
} from '../../../../../store/documents/documentsSlice';
import HistoryItem from '../HistoryItem';
import React, { RefObject } from 'react';
import { AppDispatch } from '../../../../../store';
import { NavigateFunction } from 'react-router-dom';
import { TDocumentVersions } from '../../../../../store/documents/types';
import { IDocument } from '../../../../../utils/types';

export const rejectDocumentConfirm = (
  t: TFunction,
  dispatch: AppDispatch,
  navigate: NavigateFunction,
  id?: string,
) => {
  showConfirmModal({
    title: t('MODALS.DOCUMENT_REJECT.TITLE'),
    content: (
      <Trans
        i18nKey='MODALS.DOCUMENT_REJECT.CONTENT'
        components={{
          br: <br />,
        }}
      />
    ),
    okText: t('MODALS.DOCUMENT_REJECT.OK_TEXT'),
    onOk: () => {
      if (!id) {
        return;
      }
      dispatch(rejectDocument({ data: { id }, navigate }));
    },
  });
};

export const archiveDocumentConfirm = (
  t: TFunction,
  dispatch: AppDispatch,
  navigate: NavigateFunction,
  id?: string,
) => {
  showConfirmModal({
    title: t('MODALS.DOCUMENT_ARCHIVE.TITLE'),
    content: (
      <Trans
        i18nKey='MODALS.DOCUMENT_ARCHIVE.CONTENT'
        components={{
          br: <br />,
        }}
      />
    ),
    okText: t('MODALS.DOCUMENT_ARCHIVE.OK_TEXT'),
    onOk: () => {
      if (!id) {
        return;
      }
      dispatch(archiveDocument({ data: { id }, navigate }));
    },
  });
};

export const deleteDocumentConfirm = (
  t: TFunction,
  dispatch: AppDispatch,
  navigate: NavigateFunction,
  id?: string,
) => {
  showConfirmModal({
    title: t('MODALS.DOCUMENT_DELETE.TITLE'),
    content: (
      <Trans
        i18nKey='MODALS.DOCUMENT_DELETE.CONTENT'
        components={{
          br: <br />,
        }}
      />
    ),
    okText: t('MODALS.DOCUMENT_DELETE.OK_TEXT'),
    onOk: () => {
      if (!id) {
        return;
      }
      dispatch(deleteDocument({ data: { id }, navigate }));
    },
  });
};

export const uploadDocumentFileConfirm = (
  t: TFunction,
  dispatch: AppDispatch,
  navigate: NavigateFunction,
  uploaderRef: RefObject<HTMLInputElement>,
  id?: string,
) => {
  showConfirmModal({
    title: t('MODALS.DOCUMENT_UPLOAD_FILE.TITLE'),
    content: (
      <Trans
        i18nKey='MODALS.DOCUMENT_UPLOAD_FILE.CONTENT'
        components={{
          br: <br />,
        }}
      />
    ),
    okText: t('MODALS.DOCUMENT_UPLOAD_FILE.OK_TEXT'),
    onOk: () => {
      if (!id || !uploaderRef) {
        return;
      }
      uploaderRef.current?.click();
    },
  });
};

export const showVersionHistory = (
  t: TFunction,
  versions: TDocumentVersions,
  document: IDocument | null,
) => {
  if (!document) return;

  showNoControlsModal({
    title: t('MODALS.VERSION_HISTORY.TITLE'),
    content: (
      <>
        {versions.map((historyItem) => (
          <HistoryItem key={historyItem.id} history={historyItem} document={document} />
        ))}
      </>
    ),
    width: 640,
  });
};
