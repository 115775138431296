import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './AuthLayout.scss';
import Icon from '../../ui/Icon';
import { IconsTypes } from '../../ui/Icon/types';
import { Select } from 'antd';
import BackBtn from 'components/ui/BackBtn';
interface AuthLayoutProps {
  children: React.ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  const { t } = useTranslation();
  const location = window.location.href;
  const regexp = /sign-in/;
  const isSignPage = regexp.test(location);
  const currentYear = new Date().getFullYear();
  return (
    <div className='authLayout'>
      <div className='authLayout-container'>
        <header className='authLayout-header'>
          {isSignPage ? null : <BackBtn />}
          <Select
            className='authLayout-header-select'
            defaultValue='ENG'
            options={[
              {
                value: 'ENG',
                label: 'ENG',
              },
              {
                value: 'UA',
                disabled: true,
                label: 'UA',
              },
            ]}
            suffixIcon={<Icon size={24} icon={IconsTypes.SELECT} color='#130F26' />}
          />
        </header>
        <main className='authLayout-content'>{children}</main>
        <footer className='authLayout-footer'>
          <Trans
            i18nKey='AUTH_LAYOUT_FOOTER.DESCRIPTION'
            components={{
              p: <p className='authLayout-footer-description'></p>,
            }}
            values={{ currentYear }}
          />
          {/* <Button className='authLayout-footer-btn-help' type='link'>
            <Icon icon={IconsTypes.QUESTION} />
            <span>{t('AUTH_LAYOUT_FOOTER.BUTTON_NAME')}</span>
          </Button> */}
        </footer>
      </div>
    </div>
  );
};

export default AuthLayout;
