import { NavigateFunction } from 'react-router-dom';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ProfileService from './profileService';
import {
  IUpdateInvitationPasswordData,
  IUpdateProfileCoverData,
  IUpdateProfileData,
  IUpdateProfilePasswordData,
} from './types';
import { IUser } from 'utils/types';
import { toast } from 'react-toastify';
import i18n from '../../i18n';

interface IProfileState {
  user: IUser | null;
  getProfileLoading: boolean;
  updateProfileLoading: boolean;
}

const initialState = {
  user: null,
  getProfileLoading: false,
  updateProfileLoading: false,
} as IProfileState;

export const getProfile = createAsyncThunk('profile/getProfile', async (_, thunkAPI) => {
  try {
    const response = await ProfileService.getProfile({});
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const updateProfile = createAsyncThunk(
  'profile/updateProfile',
  async (data: IUpdateProfileData, thunkAPI) => {
    try {
      const response = await ProfileService.updateProfile(data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const updateProfileCover = createAsyncThunk(
  'profile/updateProfileCover',
  async ({ file }: IUpdateProfileCoverData, thunkAPI) => {
    try {
      if (file) {
        const formData = new FormData();
        formData.append('file', file, typeof file === 'object' ? file?.name : undefined);
        const response = await ProfileService.updateProfileCover(formData);
        return response.data;
      } else {
        const response = await ProfileService.updateProfileCover();
        return response.data;
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const updateProfilePassword = createAsyncThunk(
  'profile/updateProfilePassword',
  async ({ data, reset }: { data: IUpdateProfilePasswordData; reset: () => void }, thunkAPI) => {
    try {
      const response = await ProfileService.updateProfilePassword(data);
      reset();
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const updateInvitationPassword = createAsyncThunk(
  'profile/updateInvitationPassword',
  async (
    { data }: { data: IUpdateInvitationPasswordData; navigate: NavigateFunction },
    thunkAPI,
  ) => {
    try {
      const response = await ProfileService.updateInvitationPassword(data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    resetUser: (state: IProfileState) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    // getProfile
    builder
      .addCase(getProfile.pending, (state: IProfileState) => {
        state.getProfileLoading = true;
      })
      .addCase(getProfile.fulfilled, (state: IProfileState, action) => {
        state.user = action.payload;
        state.getProfileLoading = false;
      })
      .addCase(getProfile.rejected, (state: IProfileState, action) => {
        state.getProfileLoading = false;
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
      });
    // updateProfile
    builder
      .addCase(updateProfile.pending, (state: IProfileState) => {
        state.updateProfileLoading = true;
      })
      .addCase(updateProfile.fulfilled, (state: IProfileState, action) => {
        state.user = action.payload;
        state.updateProfileLoading = false;
        toast(i18n.t('NOTIFICATIONS.SUCCESS') as string, { type: 'success' });
      })
      .addCase(updateProfile.rejected, (state: IProfileState, action) => {
        state.updateProfileLoading = false;
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
      });
    // updateProfileCover
    builder
      .addCase(updateProfileCover.pending, (state) => {
        state.updateProfileLoading = true;
      })
      .addCase(updateProfileCover.fulfilled, (state, action) => {
        state.user = action.payload;
        state.updateProfileLoading = false;
        toast(i18n.t('NOTIFICATIONS.SUCCESS') as string, { type: 'success' });
      })
      .addCase(updateProfileCover.rejected, (state, action) => {
        state.updateProfileLoading = false;
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
      });
    // updateProfilePassword
    builder
      .addCase(updateProfilePassword.pending, (state: IProfileState) => {
        state.updateProfileLoading = true;
      })
      .addCase(updateProfilePassword.fulfilled, (state: IProfileState, action) => {
        state.user = action.payload;
        state.updateProfileLoading = false;
        toast(i18n.t('NOTIFICATIONS.SUCCESS') as string, { type: 'success' });
      })
      .addCase(updateProfilePassword.rejected, (state: IProfileState, action) => {
        state.updateProfileLoading = false;
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
      });
    // updateInvitationPassword
    builder
      .addCase(updateInvitationPassword.pending, (state: IProfileState) => {
        state.updateProfileLoading = true;
      })
      .addCase(updateInvitationPassword.fulfilled, (state: IProfileState, action) => {
        const { navigate } = action.meta.arg;
        state.user = action.payload;
        state.updateProfileLoading = false;
        navigate('/auth/sign-in');
      })
      .addCase(updateInvitationPassword.rejected, (state: IProfileState, action) => {
        state.updateProfileLoading = false;
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
      });
  },
});

export const { resetUser } = profileSlice.actions;

export default profileSlice.reducer;
