import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { resetPasswordSchema } from 'utils/shemas';
import { yupResolver } from '@hookform/resolvers/yup';
import HookedField from '../../../components/HookedField';
import { FieldsTypes } from 'components/HookedField/types';
import { IResetPasswordData } from 'store/auth/types';
import './ResetPasswordPage.scss';
import { resetPassword } from 'store/auth/authSlice';
import Button from 'components/ui/Button';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Spin } from 'antd';
import Heading from 'components/ui/Heading';

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { resetPasswordLoading } = useAppSelector((state) => state.auth);

  const methods = useForm<IResetPasswordData>({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: yupResolver(resetPasswordSchema),
  });

  const submitHandler = async ({ email }: IResetPasswordData) => {
    await dispatch(resetPassword({ data: { email }, navigate }));
  };

  const {
    formState: { isValid },
  } = methods;

  return (
    <div className='resetPasswordPage'>
      <div className='resetPasswordPage-container'>
        <h2 className='resetPasswordPage-title'>{t('RESET_PASSWORD.TITLE')}</h2>
        <p className='resetPasswordPage-description'>{t('RESET_PASSWORD.DESCRIPTION')}</p>
        <FormProvider {...methods}>
          <Spin spinning={resetPasswordLoading}>
            <form
              className='resetPasswordPage-form'
              autoComplete='off'
              onSubmit={methods.handleSubmit(submitHandler)}
            >
              <HookedField
                name='email'
                type={FieldsTypes.TEXT}
                label={t('LABELS.EMAIL')}
                placeholder='ein-des-ein@ein-des-ein.com'
              />
              <Button htmlType='submit' fullWidth={true} disabled={!isValid}>
                {t('RESET_PASSWORD.BUTTON_NAME')}
              </Button>
            </form>
          </Spin>
        </FormProvider>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
