import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AuthService from './authService';
import storage, { KEEP_LOG_IN_KEY, RESEND_TIME_KEY } from '../../utils/storage';
import {
  ISignInData,
  ISignUpData,
  IResetPasswordData,
  IConfirmationResetPasswordData,
  ICreateNewPasswordData,
  IConfirmInvitationData,
  ISendEmailData,
} from './types';
import { NavigateFunction } from 'react-router-dom';
import { toast } from 'react-toastify';
import i18n from '../../i18n';
import dayjs from 'dayjs';

interface IAuthState {
  isLoggedIn: boolean;
  signInLoading: boolean;
  signUpLoading: boolean;
  logOutLoading: boolean;
  refreshTokensLoading: boolean;
  resetPasswordLoading: boolean;
  confirmationResetPasswordLoading: boolean;
  createNewPasswordLoading: boolean;
  confirmInvitationLoading: boolean;
  sendEmailLoading: boolean;
  resendDate: string;
}

const initialState = {
  isLoggedIn: Boolean(storage.getFromStorage('token')),
  signInLoading: false,
  logOutLoading: false,
  refreshTokensLoading: false,
  resetPasswordLoading: false,
  confirmationResetPasswordLoading: false,
  createNewPasswordLoading: false,
  confirmInvitationLoading: false,
  sendEmailLoading: false,
  resendDate: storage.getFromStorage(RESEND_TIME_KEY),
} as IAuthState;

export const signIn = createAsyncThunk(
  'auth/signIn',
  async ({ data }: { data: ISignInData; navigate: NavigateFunction }, thunkAPI) => {
    try {
      const { email, password } = data;
      const response = await AuthService.signIn({ email, password });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const signUp = createAsyncThunk(
  'auth/signUp',
  async ({ data }: { data: ISignUpData; navigate: NavigateFunction }, thunkAPI) => {
    try {
      const { email, password } = data;
      const response = await AuthService.signUp({ email, password });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const logOut = createAsyncThunk(
  'auth/logOut',
  async (props: { navigate: NavigateFunction }, thunkAPI) => {
    try {
      const refreshToken = storage.getFromStorage('refreshToken');

      if (!refreshToken) {
        return thunkAPI.rejectWithValue('No refresh token');
      }
      const response = await AuthService.logOut({ refreshToken });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const refreshTokens = createAsyncThunk(
  'auth/refreshTokens',
  async (props: { navigate: NavigateFunction }, thunkAPI) => {
    try {
      const refreshToken = storage.getFromStorage('refreshToken');
      if (!refreshToken) {
        return thunkAPI.rejectWithValue('No refresh token');
      }
      const response = await AuthService.refreshTokens({ refreshToken });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const resetPassword = createAsyncThunk(
  'auth/reset-password/send/email',
  async ({ data }: { data: IResetPasswordData; navigate: NavigateFunction }, thunkAPI) => {
    try {
      const { email } = data;
      const response = await AuthService.resetPassword({ email });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const confirmationResetPassword = createAsyncThunk(
  '/auth/validate/email/code',
  async (
    { data }: { data: IConfirmationResetPasswordData; navigate: NavigateFunction },
    thunkAPI,
  ) => {
    try {
      const { email, code } = data;
      const response = await AuthService.confirmationResetPassword({ email, code });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const createNewPassword = createAsyncThunk(
  '/auth/reset-password',
  async ({ data }: { data: ICreateNewPasswordData; navigate: NavigateFunction }, thunkAPI) => {
    try {
      const { email, password, code } = data;
      const response = await AuthService.createNewPassword({ email, password, code });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);
export const confirmInvitation = createAsyncThunk(
  '/auth/invitation/password',
  async ({ data }: { data: IConfirmInvitationData; navigate: NavigateFunction }, thunkAPI) => {
    try {
      const { email, password, oldPassword } = data;
      const response = await AuthService.confirmInvitation({ email, password, oldPassword });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const sendEmail = createAsyncThunk(
  '/auth/send/email',
  async ({ data }: { data: ISendEmailData; navigate: NavigateFunction }, thunkAPI) => {
    try {
      const response = await AuthService.sendEmail(data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // sign in
    builder
      .addCase(signIn.pending, (state: IAuthState) => {
        state.signInLoading = true;
      })
      .addCase(signIn.fulfilled, (state: IAuthState, action) => {
        const { token, refreshToken } = action.payload;
        const { navigate } = action.meta.arg;
        storage.setToStorage('token', token);
        storage.setToStorage('refreshToken', refreshToken);
        state.signInLoading = false;
        state.isLoggedIn = true;
        navigate('/main/documents');
      })
      .addCase(signIn.rejected, (state: IAuthState, action) => {
        storage.removeFromStorage('token');
        storage.removeFromStorage('refreshToken');
        if (typeof action.payload === 'string') {
          toast(i18n.t(`NOTIFICATIONS.${action.payload}`) as string, { type: 'error' });
          state.signInLoading = false;
          state.isLoggedIn = false;
          return;
        }
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.signInLoading = false;
        state.isLoggedIn = false;
      });

    // sign up
    builder
      .addCase(signUp.pending, (state: IAuthState) => {
        state.signUpLoading = true;
      })
      .addCase(signUp.fulfilled, (state: IAuthState, action) => {
        const { token, refreshToken } = action.payload;
        const { navigate } = action.meta.arg;
        storage.setToStorage('token', token);
        storage.setToStorage('refreshToken', refreshToken);
        state.signUpLoading = false;
        state.isLoggedIn = true;
        navigate('/main/documents');
      })
      .addCase(signUp.rejected, (state: IAuthState, action) => {
        storage.removeFromStorage('token');
        storage.removeFromStorage('refreshToken');
        if (typeof action.payload === 'string') {
          toast(i18n.t(`NOTIFICATIONS.${action.payload}`) as string, { type: 'error' });
          state.signUpLoading = false;
          state.isLoggedIn = false;
          return;
        }
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.signUpLoading = false;
        state.isLoggedIn = false;
      });

    // log out
    builder
      .addCase(logOut.pending, (state: IAuthState) => {
        state.logOutLoading = true;
      })
      .addCase(logOut.fulfilled, (state: IAuthState, action) => {
        const { navigate } = action.meta.arg;
        storage.removeFromStorage('token');
        storage.removeFromStorage('refreshToken');
        state.isLoggedIn = false;
        state.logOutLoading = false;
        navigate('/sign-in');
      })
      .addCase(logOut.rejected, (state: IAuthState, action) => {
        const { navigate } = action.meta.arg;
        navigate('/sign-in');
        storage.removeFromStorage('token');
        storage.removeFromStorage('refreshToken');
        if (typeof action.payload === 'string') {
          toast(i18n.t(`NOTIFICATIONS.${action.payload}`) as string, { type: 'error' });
          state.isLoggedIn = false;
          state.logOutLoading = false;
          return;
        }
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.isLoggedIn = false;
        state.logOutLoading = false;
      });

    // refresh tokens
    builder
      .addCase(refreshTokens.pending, (state: IAuthState) => {
        state.refreshTokensLoading = true;
      })
      .addCase(refreshTokens.fulfilled, (state: IAuthState, action) => {
        const { token, refreshToken } = action.payload;
        storage.setToStorage('token', token);
        storage.setToStorage('refreshToken', refreshToken);
        state.refreshTokensLoading = false;
        state.isLoggedIn = true;
      })
      .addCase(refreshTokens.rejected, (state: IAuthState, action) => {
        const { navigate } = action.meta.arg;
        navigate('/sign-in');
        storage.removeFromStorage('token');
        storage.removeFromStorage('refreshToken');
        if (typeof action.payload === 'string') {
          toast(i18n.t(`NOTIFICATIONS.${action.payload}`) as string, { type: 'error' });
          state.refreshTokensLoading = false;
          state.isLoggedIn = false;
          return;
        }
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.refreshTokensLoading = false;
        state.isLoggedIn = false;
      });
    // reset password
    builder
      .addCase(resetPassword.pending, (state: IAuthState) => {
        state.resetPasswordLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state: IAuthState, action) => {
        toast(i18n.t('NOTIFICATIONS.CODE_SEND') as string, { type: 'success' });
        const { navigate } = action.meta.arg;
        const resendDate = dayjs().toString();
        state.resendDate = resendDate;
        storage.setToStorage(RESEND_TIME_KEY, resendDate);
        state.resetPasswordLoading = false;
        navigate(`/auth/confirm-password?email=${action.meta.arg.data.email}`);
      })
      .addCase(resetPassword.rejected, (state: IAuthState, action) => {
        const errors = action.payload as string[];
        console.log('action.payload', action.payload);
        if (typeof action.payload === 'string') {
          toast(i18n.t(`NOTIFICATIONS.${action.payload}`) as string, { type: 'error' });
          state.resetPasswordLoading = false;
          return;
        }
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.resetPasswordLoading = false;
      });

    // confirmation reset password
    builder
      .addCase(confirmationResetPassword.pending, (state: IAuthState) => {
        state.confirmationResetPasswordLoading = true;
      })
      .addCase(confirmationResetPassword.fulfilled, (state: IAuthState, action) => {
        const { navigate } = action.meta.arg;
        state.confirmationResetPasswordLoading = false;
        navigate(
          `/auth/create-password?email=${action.meta.arg.data.email}&code=${action.meta.arg.data.code}`,
        );
      })
      .addCase(confirmationResetPassword.rejected, (state: IAuthState, action) => {
        if (typeof action.payload === 'string') {
          toast(i18n.t(`NOTIFICATIONS.${action.payload}`) as string, { type: 'error' });
          state.confirmationResetPasswordLoading = false;
          return;
        }
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.confirmationResetPasswordLoading = false;
      });
    // create new password
    builder
      .addCase(createNewPassword.pending, (state: IAuthState) => {
        state.createNewPasswordLoading = true;
      })
      .addCase(createNewPassword.fulfilled, (state: IAuthState, action) => {
        toast(i18n.t('NOTIFICATIONS.NEW_PASSWORD_SAVED') as string, { type: 'success' });
        const { navigate } = action.meta.arg;
        state.createNewPasswordLoading = false;
        navigate('/auth/sign-in');
      })
      .addCase(createNewPassword.rejected, (state: IAuthState, action) => {
        if (typeof action.payload === 'string') {
          toast(i18n.t(`NOTIFICATIONS.${action.payload}`) as string, { type: 'error' });
          state.createNewPasswordLoading = false;
          return;
        }
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.createNewPasswordLoading = false;
      });
    // confirm invitation
    builder
      .addCase(confirmInvitation.pending, (state: IAuthState) => {
        state.confirmInvitationLoading = true;
      })
      .addCase(confirmInvitation.fulfilled, (state: IAuthState, action) => {
        toast(i18n.t('NOTIFICATIONS.NEW_PASSWORD_SAVED') as string, { type: 'success' });
        const { navigate } = action.meta.arg;
        state.confirmInvitationLoading = false;
        navigate('/auth/sign-in');
      })
      .addCase(confirmInvitation.rejected, (state: IAuthState, action) => {
        if (typeof action.payload === 'string') {
          toast(i18n.t(`NOTIFICATIONS.${action.payload}`) as string, { type: 'error' });
          state.confirmInvitationLoading = false;
          return;
        }
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.confirmInvitationLoading = false;
      });
    // send email
    builder
      .addCase(sendEmail.pending, (state: IAuthState) => {
        state.sendEmailLoading = true;
      })
      .addCase(sendEmail.fulfilled, (state: IAuthState, action) => {
        toast(i18n.t('NOTIFICATIONS.SUCCESS') as string, { type: 'success' });
        const { navigate } = action.meta.arg;
        state.sendEmailLoading = false;
        navigate('/auth/sign-in');
      })
      .addCase(sendEmail.rejected, (state: IAuthState, action) => {
        if (typeof action.payload === 'string') {
          toast(i18n.t(`NOTIFICATIONS.${action.payload}`) as string, { type: 'error' });
          state.sendEmailLoading = false;
          return;
        }
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.sendEmailLoading = false;
      });
  },
});

export default authSlice.reducer;
