import {
  DocumentSignedStatuses,
  DocumentStatuses,
  DocumentVerificationStatuses,
  IDocument,
  IFile,
  ISigner,
  ISupervisor,
} from '../../utils/types';
import { TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';

export interface IDataType extends IDocument {
  key?: string;
  render?: (arg0: string, arg1: string, arg2: string) => JSX.Element;
}

export interface IDocumentsOptions {
  pagination: TablePaginationConfig;
  filters: Record<string, FilterValue> | null;
  sorter: SorterResult<IDataType> | null;
  search: string;
}

export interface IUserDocumentsOptions extends IDocumentsOptions {
  id: string;
}
export enum DocumentFlows {
  OUTCOME = 'OUTCOME',
  INCOME = 'INCOME',
}

export enum DocumentStatusFlow {
  GENERAL_DOCUMENT_STATUS = 'GENERAL_DOCUMENT_STATUS',
  STATUS_AS_SIGNER = 'STATUS_AS_SIGNER',
}

export interface IDocumentsFilter {
  verificationStatus?: DocumentVerificationStatuses | DocumentVerificationStatuses[];
  signedStatus?: DocumentSignedStatuses;
  status?: DocumentStatuses;
  flow?: DocumentFlows;
  documentStatusFlow?: DocumentStatusFlow;
}

export interface IGetDocumentsData {
  page: number;
  take: number;
  typeId?: string;
  status?: DocumentStatuses;
  verificationStatus?: DocumentVerificationStatuses;
  signedStatus?: DocumentSignedStatuses;
  search?: string;
  selection?: string[];
  asc?: string[];
  desc?: string[];
}

export interface IGetUserDocumentsData extends IGetDocumentsData {
  id: string;
}

export interface IDocumentsStats {
  income: number;
  unverified: number;
}

export interface ICreateDocumentData {
  file: any;
  name: string;
  typeId: string;
  signers: string;
  supervisorId?: string;
  signWithSignature: boolean;
  signWithPicture: boolean;
  isAlreadySigned: boolean;
}

export interface ISignDocumentData {
  id: string;
  file: any;
}

export interface IRejectDocumentData {
  id: string;
}

export interface IGetDocumentData {
  id: string;
  selection?: string[];
}

export interface IUpdateDocumentData {
  id: string;
  name?: string;
  type?: string;
  status?: 'ARCHIVED' | 'DELETED' | 'ACTIVE';
  deletedAt?: string;
  signers?: ISigner[];
  supervisors?: ISupervisor[];
}

export interface IDeleteDocumentData {
  id: string;
}

export interface IArchiveDocumentData {
  id: string;
}

export interface IDownloadDocumentData {
  id: string;
}

export interface IUpdateFileData {
  id: string;
  file: any;
}

export interface IUpdateDocumentVerificationStatus {
  id: string;
  verificationStatus: DocumentVerificationStatuses;
}

export interface IGetDocumentVersionsData {
  id: string;
  selection?: string[];
  asc?: string[];
  desc?: string[];
  page?: number;
  take?: number;
  createdAt?: string[];
}

export interface IDocumentVersion {
  id: string;
  documentId: string;
  createdAt: string;
  updatedAt: string;
  verificationAt: string;
  fileId: string;
  verificationStatus: DocumentVerificationStatuses;
  file: IFile;
  signers: ISigner[];
}

export type TDocumentVersions = IDocumentVersion[];

export interface IViewDocumentData {
  id: string;
}
