import {
  DocumentSignedStatuses,
  DocumentStatuses,
  DocumentVerificationStatuses,
} from '../../../../utils/types';
import {
  DocumentFlows,
  DocumentStatusFlow,
  IDocumentsFilter,
} from '../../../../store/documents/types';

export const FILTERS_TO_PAGE: Record<string, IDocumentsFilter> = {
  unverified: {
    verificationStatus: [DocumentVerificationStatuses.PENDING, DocumentVerificationStatuses.VIEWED],
  },
  rejected: {
    signedStatus: DocumentSignedStatuses.REJECTED,
    documentStatusFlow: DocumentStatusFlow.GENERAL_DOCUMENT_STATUS,
  },
  archived: { status: DocumentStatuses.ARCHIVED },
  income: {
    signedStatus: DocumentSignedStatuses.UNSIGNED,
    flow: DocumentFlows.INCOME,
    verificationStatus: [DocumentVerificationStatuses.NONE, DocumentVerificationStatuses.APPROVED],
  },
  outcome: { flow: DocumentFlows.OUTCOME },
};
