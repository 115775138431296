import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import HookedField from '../../../../../../components/HookedField';
import { FieldsTypes } from '../../../../../../components/HookedField/types';
import { IUpdateProfilePasswordData } from '../../../../../../store/profile/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { changePasswordSchema, profileDeleteModalConfig } from '../../../constants';
import { updateProfilePassword } from '../../../../../../store/profile/profileSlice';
import { showConfirmModal } from '../../../../../../utils/functions';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../../hooks';
import Button from '../../../../../../components/ui/Button';

const AccountSettings = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const changePasswordForm = useForm<IUpdateProfilePasswordData>({
    mode: 'onSubmit',
    shouldFocusError: true,
    resolver: yupResolver(changePasswordSchema),
  });

  const updatePasswordHandler = (data: IUpdateProfilePasswordData) =>
    dispatch(updateProfilePassword({ data, reset: changePasswordForm.reset }));

  const showDeleteModal = () => showConfirmModal(profileDeleteModalConfig);

  return (
    <>
      <div className='profile-info'>
        <FormProvider {...changePasswordForm}>
          <form
            className='form'
            autoComplete='off'
            onSubmit={changePasswordForm.handleSubmit(updatePasswordHandler)}
          >
            <h4 className='title'>{t('PROFILE.UPDATE_YOUR_PASSWORD')}</h4>
            <div className='profile-info-container'>
              <HookedField
                name='oldPassword'
                type={FieldsTypes.PASSWORD}
                label={t('LABELS.OLD_PASSWORD')}
                placeholder={t('LABELS.OLD_PASSWORD')}
              />
              <HookedField
                name='password'
                type={FieldsTypes.PASSWORD}
                label={t('LABELS.NEW_PASSWORD')}
                placeholder={t('LABELS.NEW_PASSWORD')}
              />
              <Button htmlType='submit' fullWidth>
                {t('BUTTONS.SAVE_CHANGES')}
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
      {/* <div className='profile-info'>
        <h4 className='title'>{t('PROFILE.REMOVE_ACCOUNT')}</h4>
        <div className='profile-info-container'>
          <p className='profile-label profile-label-delete-info'>{t('PROFILE.DELETE_INFO')}</p>
          <p className='profile-label profile-label-delete-info'>{t('PROFILE.DELETE_INFO_2')}</p>
          <Button onClick={showDeleteModal} type='error' fullWidth>
            {t('PROFILE.DELETE_ACCOUNT')}
          </Button>
        </div>
      </div> */}
    </>
  );
};

export default AccountSettings;
