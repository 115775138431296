import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import DocumentsService from './documentsService';
import { IDocument } from '../../utils/types';
import { toast } from 'react-toastify';
import i18n from '../../i18n';
import { RootState } from '../index';
import {
  IArchiveDocumentData,
  ICreateDocumentData,
  IDeleteDocumentData,
  IDocumentsFilter,
  IDocumentsOptions,
  IDocumentsStats,
  IGetDocumentData,
  IGetDocumentVersionsData,
  IRejectDocumentData,
  ISignDocumentData,
  IUpdateDocumentVerificationStatus,
  IUpdateFileData,
  IUserDocumentsOptions,
  IViewDocumentData,
  TDocumentVersions,
} from './types';
import { NavigateFunction } from 'react-router-dom';

export interface IPdfOptions {
  numPages?: number | null;
  pageNumber: number;
}

export const initialOptions: IDocumentsOptions = {
  pagination: {
    total: 0,
    current: 1,
    pageSize: 10,
  },
  filters: null,
  sorter: {
    field: 'updatedAt',
    order: 'descend',
  },
  search: '',
};

interface IDocumentsState {
  documents: IDocument[];
  document: IDocument | null;
  options: IDocumentsOptions;
  stats: IDocumentsStats | null;
  filter: IDocumentsFilter | null;
  versions: TDocumentVersions;
  getDocumentsLoading: boolean;
  getDocumentsStatsLoading: boolean;
  deleteDocumentLoading: boolean;
  getDocumentLoading: boolean;
  rejectDocumentLoading: boolean;
  uploadFileLoading: boolean;
  signDocumentLoading: boolean;
  updateDocumentVerificationStatusLoading: boolean;
  createDocumentLoading: boolean;
  getDocumentVersionsLoading: boolean;
  pdf: IPdfOptions;
}

const initialState = {
  documents: [],
  document: null,
  options: initialOptions,
  stats: null,
  filter: null,
  versions: [],
  getDocumentsLoading: false,
  getDocumentsStatsLoading: false,
  deleteDocumentLoading: false,
  getDocumentLoading: false,
  rejectDocumentLoading: false,
  uploadFileLoading: false,
  signDocumentLoading: false,
  updateDocumentVerificationStatusLoading: false,
  createDocumentLoading: false,
  getDocumentVersionsLoading: false,
  pdf: { numPages: null, pageNumber: 1 },
} as IDocumentsState;

export const getDocuments = createAsyncThunk(
  'documents/getDocuments',
  async (data: IDocumentsOptions, thunkAPI) => {
    try {
      const { documents: state } = thunkAPI.getState() as RootState;
      const { pagination, sorter, filters, search } = data;
      const options: any = {};
      if (sorter) {
        const { field, order } = sorter;
        if (order === 'ascend') {
          options.asc = [field];
        } else if (order === 'descend') {
          options.desc = [field];
        }
      }
      if (search) {
        options.search = search;
      }
      thunkAPI.dispatch(getDocumentsStats());
      const response = await DocumentsService.getDocuments({
        page: pagination.current,
        take: pagination.pageSize,
        ...options,
        ...state.filter,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const getUserDocuments = createAsyncThunk(
  'documents/users',
  async (data: IUserDocumentsOptions, thunkAPI) => {
    try {
      const { documents: state } = thunkAPI.getState() as RootState;
      const { pagination, sorter, filters, search, id } = data;
      const options: any = {};
      if (sorter) {
        const { field, order } = sorter;
        if (order === 'ascend') {
          options.asc = [field];
        } else if (order === 'descend') {
          options.desc = [field];
        }
      }
      if (search) {
        options.search = search;
      }
      const response = await DocumentsService.getUserDocuments({
        page: pagination.current,
        take: pagination.pageSize,
        id,
        ...options,
        ...state.filter,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const getDocumentsStats = createAsyncThunk(
  'documents/getDocumentsStats',
  async (_, thunkAPI) => {
    try {
      const response = await DocumentsService.getDocumentsStats();
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const getDocument = createAsyncThunk(
  'documents/getDocument',
  async (data: IGetDocumentData, thunkAPI) => {
    try {
      const response = await DocumentsService.getDocument(data);
      thunkAPI.dispatch(getDocumentsStats());
      thunkAPI.dispatch(getDocumentVersions({ id: data.id, desc: ['createdAt'] }));
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const deleteDocument = createAsyncThunk(
  'documents/deleteDocument',
  async (
    { data, navigate }: { data: IDeleteDocumentData; navigate?: NavigateFunction },
    thunkAPI,
  ) => {
    try {
      const response = await DocumentsService.deleteDocument(data);
      if (!navigate) {
        const { documents: state } = thunkAPI.getState() as RootState;
        const { options } = state;
        thunkAPI.dispatch(getDocuments(options));
      }
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const archiveDocument = createAsyncThunk(
  'documents/archiveDocument',
  async (
    { data, navigate }: { data: IArchiveDocumentData; navigate?: NavigateFunction },
    thunkAPI,
  ) => {
    try {
      const response = await DocumentsService.archiveDocument(data);
      if (!navigate) {
        const { documents: state } = thunkAPI.getState() as RootState;
        const { options } = state;
        thunkAPI.dispatch(getDocuments(options));
      }
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const rejectDocument = createAsyncThunk(
  'documents/rejectDocument',
  async ({ data }: { data: IRejectDocumentData; navigate?: NavigateFunction }, thunkAPI) => {
    try {
      const response = await DocumentsService.rejectDocument(data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const updateFile = createAsyncThunk(
  'documents/updateFile',
  async (data: IUpdateFileData, thunkAPI) => {
    try {
      const response = await DocumentsService.updateFile(data);
      const { documents: state } = thunkAPI.getState() as RootState;
      const { document } = state;
      if (document) {
        thunkAPI.dispatch(getDocument({ id: document.id }));
      }
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const signDocument = createAsyncThunk(
  'documents/signDocument',
  async (data: ISignDocumentData, thunkAPI) => {
    try {
      const response = await DocumentsService.signDocument(data);
      const { documents: state } = thunkAPI.getState() as RootState;
      const { document } = state;
      if (document) {
        thunkAPI.dispatch(getDocument({ id: document.id }));
      }
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const signDocumentWithPicture = createAsyncThunk(
  'documents/signDocument',
  async (data: ISignDocumentData, thunkAPI) => {
    try {
      const response = await DocumentsService.signDocumentWithPicture(data);
      const { documents: state } = thunkAPI.getState() as RootState;
      const { document } = state;
      if (document) {
        thunkAPI.dispatch(getDocument({ id: document.id }));
      }
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const updateDocumentVerificationStatus = createAsyncThunk(
  'documents/updateDocumentVerificationStatus',
  async (data: IUpdateDocumentVerificationStatus, thunkAPI) => {
    try {
      const response = await DocumentsService.updateDocumentVerificationsStatus(data);
      const { documents: state } = thunkAPI.getState() as RootState;
      const { document } = state;
      if (document) {
        thunkAPI.dispatch(getDocument({ id: document.id }));
      }
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const createDocument = createAsyncThunk(
  'documents/createDocument',
  async ({ data }: { data: ICreateDocumentData; navigate: NavigateFunction }, thunkAPI) => {
    try {
      const response = await DocumentsService.createDocument(data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const getDocumentVersions = createAsyncThunk(
  'documents/getDocumentVersions',
  async (data: IGetDocumentVersionsData, thunkAPI) => {
    try {
      const response = await DocumentsService.getDocumentVersions(data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const viewDocument = createAsyncThunk(
  'documents/viewDocument',
  async (data: IViewDocumentData, thunkAPI) => {
    try {
      await DocumentsService.viewDocument(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

// export const updateDocument = createAsyncThunk(
//   'documents/updateDocument',
//   async (
//     { id, name, type, status, deletedAt, signers, supervisors }: IUpdateDocumentData,
//     thunkAPI,
//   ) => {
//     try {
//       const response = await DocumentsService.updateDocument({
//         id,
//         name,
//         type,
//         status,
//         deletedAt,
//         signers,
//         supervisors,
//       });
//       const { documents: state } = thunkAPI.getState() as RootState;
//       const { take, page } = state;
//       thunkAPI.dispatch(getDocuments({ take, page }));
//       return response.data;
//     } catch (error: any) {
//       return thunkAPI.rejectWithValue(error.response.data.message);
//     }
//   },
// );

// export const downloadDocument = createAsyncThunk(
//   'documents/downloadDocument',
//   async ({ id }: IDownloadDocumentData, thunkAPI) => {
//     try {
//       const response = await DocumentsService.downloadDocument({
//         id,
//       });
//       return response.data;
//     } catch (error: any) {
//       return thunkAPI.rejectWithValue(error.response.data.message);
//     }
//   },
// );

const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setDocumentsOptions: (
      state: IDocumentsState,
      action: PayloadAction<Partial<IDocumentsOptions>>,
    ) => {
      state.options = { ...state.options, ...action.payload };
    },
    resetDocumentsOptions: (state: IDocumentsState) => {
      state.options = initialOptions;
    },
    setDocumentsFilter: (
      state: IDocumentsState,
      action: PayloadAction<IDocumentsFilter | null>,
    ) => {
      state.filter = action.payload;
      state.options = initialOptions;
    },
    resetDocumentsFilter: (state: IDocumentsState) => {
      state.filter = null;
    },
    resetCurrentDocument: (state: IDocumentsState) => {
      state.document = null;
    },
    setPdfOptions: (
      state: IDocumentsState,
      { payload: pdfOptions }: PayloadAction<IPdfOptions>,
    ) => {
      state.pdf = { ...state.pdf, ...pdfOptions };
    },
    resetDocuments: (state) => {
      const { stats } = state;
      return { ...initialState, stats };
    },
  },
  extraReducers: (builder) => {
    // getDocuments
    builder
      .addCase(getDocuments.pending, (state: IDocumentsState) => {
        state.getDocumentsLoading = true;
      })
      .addCase(getDocuments.fulfilled, (state: IDocumentsState, action) => {
        console.log('getDocuments.fulfilled', action.payload);
        const { result, total } = action.payload;
        const options = action.meta.arg;
        state.documents = result;
        state.options.pagination.total = total;
        state.getDocumentsLoading = false;
      })
      .addCase(getDocuments.rejected, (state: IDocumentsState, action) => {
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.getDocumentsLoading = false;
      });
    // getDocumentsStats
    builder
      .addCase(getDocumentsStats.pending, (state: IDocumentsState) => {
        state.getDocumentsStatsLoading = true;
      })
      .addCase(getDocumentsStats.fulfilled, (state: IDocumentsState, action) => {
        state.stats = action.payload;
        state.getDocumentsStatsLoading = false;
      })
      .addCase(getDocumentsStats.rejected, (state: IDocumentsState, action) => {
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.getDocumentsStatsLoading = false;
      });
    // getDocument
    builder
      .addCase(getDocument.pending, (state: IDocumentsState) => {
        state.getDocumentLoading = true;
      })
      .addCase(getDocument.fulfilled, (state: IDocumentsState, action) => {
        console.log('getDocument.fulfilled payload', action.payload);
        state.document = action.payload;
        state.getDocumentLoading = false;
      })
      .addCase(getDocument.rejected, (state: IDocumentsState, action) => {
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.getDocumentLoading = false;
      });
    // getUserDocuments
    builder
      .addCase(getUserDocuments.pending, (state: IDocumentsState) => {
        state.getDocumentsLoading = true;
      })
      .addCase(getUserDocuments.fulfilled, (state: IDocumentsState, action) => {
        console.log('getUserDocuments.fulfilled', action.payload);
        const { result, total } = action.payload;
        const options = action.meta.arg;
        state.documents = result;
        state.options.pagination.total = total;
        state.getDocumentsLoading = false;
      })
      .addCase(getUserDocuments.rejected, (state: IDocumentsState, action) => {
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.getDocumentsLoading = false;
      });
    // deleteDocument
    builder
      .addCase(deleteDocument.pending, (state: IDocumentsState) => {
        state.deleteDocumentLoading = true;
      })
      .addCase(deleteDocument.fulfilled, (state: IDocumentsState, action) => {
        console.log('deleteDocument.fulfilled payload', action.payload);
        const { navigate } = action.meta.arg;
        toast(i18n.t('NOTIFICATIONS.DOCUMENT_DELETED') as string, { type: 'success' });
        state.deleteDocumentLoading = false;
        navigate && navigate('/main/documents');
      })
      .addCase(deleteDocument.rejected, (state: IDocumentsState, action) => {
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.deleteDocumentLoading = false;
      });
    // archiveDocument
    builder
      .addCase(archiveDocument.pending, (state: IDocumentsState) => {
        state.deleteDocumentLoading = true;
      })
      .addCase(archiveDocument.fulfilled, (state: IDocumentsState, action) => {
        console.log('deleteDocument.fulfilled payload', action.payload);
        const { navigate } = action.meta.arg;
        toast(i18n.t('NOTIFICATIONS.DOCUMENT_ARCHIVED') as string, { type: 'success' });
        state.deleteDocumentLoading = false;
        navigate && navigate('/main/documents/archived');
      })
      .addCase(archiveDocument.rejected, (state: IDocumentsState, action) => {
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.deleteDocumentLoading = false;
      });
    // rejectDocument
    builder
      .addCase(rejectDocument.pending, (state: IDocumentsState) => {
        state.rejectDocumentLoading = true;
      })
      .addCase(rejectDocument.fulfilled, (state: IDocumentsState, action) => {
        console.log('rejectDocument.fulfilled payload', action.payload);
        const { navigate } = action.meta.arg;
        state.rejectDocumentLoading = false;
        navigate && navigate('/main/documents/rejected');
      })
      .addCase(rejectDocument.rejected, (state: IDocumentsState, action) => {
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.rejectDocumentLoading = false;
      });
    // updateFile
    builder
      .addCase(updateFile.pending, (state: IDocumentsState) => {
        state.uploadFileLoading = true;
      })
      .addCase(updateFile.fulfilled, (state: IDocumentsState, action) => {
        console.log('updateFile.fulfilled payload', action.payload);
        state.uploadFileLoading = false;
      })
      .addCase(updateFile.rejected, (state: IDocumentsState, action) => {
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.uploadFileLoading = false;
      });
    // signDocument
    builder
      .addCase(signDocument.pending, (state: IDocumentsState) => {
        state.signDocumentLoading = true;
      })
      .addCase(signDocument.fulfilled, (state: IDocumentsState, action) => {
        console.log('signDocument.fulfilled payload', action.payload);
        state.signDocumentLoading = false;
      })
      .addCase(signDocument.rejected, (state: IDocumentsState, action) => {
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.signDocumentLoading = false;
      });
    // updateDocumentVerificationStatus
    builder
      .addCase(updateDocumentVerificationStatus.pending, (state: IDocumentsState) => {
        state.updateDocumentVerificationStatusLoading = true;
      })
      .addCase(updateDocumentVerificationStatus.fulfilled, (state: IDocumentsState, action) => {
        console.log('updateDocumentVerificationStatus.fulfilled payload', action.payload);
        state.updateDocumentVerificationStatusLoading = false;
      })
      .addCase(updateDocumentVerificationStatus.rejected, (state: IDocumentsState, action) => {
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.updateDocumentVerificationStatusLoading = false;
      });
    // createDocument
    builder
      .addCase(createDocument.pending, (state: IDocumentsState) => {
        state.createDocumentLoading = true;
      })
      .addCase(createDocument.fulfilled, (state: IDocumentsState, action) => {
        const { navigate } = action.meta.arg;
        toast(i18n.t('NOTIFICATIONS.DOCUMENT_CREATED') as string, { type: 'success' });
        state.createDocumentLoading = false;
        navigate && navigate('/main/documents');
      })
      .addCase(createDocument.rejected, (state: IDocumentsState, action) => {
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.createDocumentLoading = false;
      });
    // getDocumentVersions
    builder
      .addCase(getDocumentVersions.pending, (state) => {
        state.getDocumentVersionsLoading = true;
      })
      .addCase(getDocumentVersions.fulfilled, (state, action) => {
        console.log('getDocumentVersions.fulfilled', action.payload);
        const { result } = action.payload;
        state.versions = result;
        state.getDocumentVersionsLoading = false;
      })
      .addCase(getDocumentVersions.rejected, (state: IDocumentsState, action) => {
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.getDocumentVersionsLoading = false;
      });
    // // updateDocument
    // builder
    //   .addCase(updateDocument.pending, (state: IDocumentsState) => {
    //     state.error = '';
    //     state.loading = true;
    //   })
    //   .addCase(updateDocument.fulfilled, (state: IDocumentsState, action) => {
    //     console.log('updateDocument.fulfilled payload', action.payload);
    //     state.error = '';
    //     state.loading = false;
    //   })
    //   .addCase(updateDocument.rejected, (state: IDocumentsState, action) => {
    //     if (action.payload) {
    //       state.error = action.payload as string;
    //     }
    //     state.loading = false;
    //   });
    // // downloadDocument
    // builder
    //   .addCase(downloadDocument.pending, (state: IDocumentsState) => {
    //     state.error = '';
    //     state.loading = true;
    //   })
    //   .addCase(downloadDocument.fulfilled, (state: IDocumentsState, action) => {
    //     console.log('downloadDocument.fulfilled payload', action.payload);
    //     state.error = '';
    //     state.loading = false;
    //   })
    //   .addCase(downloadDocument.rejected, (state: IDocumentsState, action) => {
    //     if (action.payload) {
    //       state.error = action.payload as string;
    //     }
    //     state.loading = false;
    //   });
  },
});

export const {
  resetDocumentsOptions,
  setDocumentsOptions,
  setDocumentsFilter,
  resetDocumentsFilter,
  resetCurrentDocument,
  setPdfOptions,
  resetDocuments,
} = documentsSlice.actions;

export default documentsSlice.reducer;
