import React from 'react';
import Sidebar from '../Sidebar';
import { useAppSelector } from '../../../hooks';
import { Layout } from 'antd';
import Header from '../Header';

import './MainLayout.scss';

const { Sider, Content } = Layout;

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const { isCollapsedSidebar } = useAppSelector((state) => state.app);

  return (
    <Layout hasSider className='mainLayout'>
      <Sider
        className='mainLayout-aside'
        width={280}
        trigger={null}
        collapsedWidth={80}
        collapsible
        collapsed={isCollapsedSidebar}
      >
        <Sidebar />
      </Sider>
      <Layout>
        <Header />
        <Content className='mainLayout-content'>{children}</Content>
      </Layout>
    </Layout>
  );
};
export default MainLayout;
