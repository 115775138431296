import * as yup from 'yup';
import i18n from '../../../i18n';

export const documentSchema = yup.object().shape({
  file: yup.mixed().required(i18n.t('ERRORS.REQUIRED')),
  name: yup.string().required(i18n.t('ERRORS.REQUIRED')),
  typeId: yup.string().required(i18n.t('ERRORS.REQUIRED')),
  signers: yup
    .array()
    .of(yup.string())
    .required(i18n.t('ERRORS.REQUIRED'))
    .min(2, i18n.t('ERRORS.ARRAY_MIN', { min: 2 })),
  verify: yup.boolean(),
  supervisorId: yup.string().when('verify', {
    is: true,
    then: yup.string().required(i18n.t('ERRORS.REQUIRED')),
  }),
});

export const documentSchemaWithSign = yup.object().shape({
  file: yup.mixed().required(i18n.t('ERRORS.REQUIRED')),
  name: yup.string().required(i18n.t('ERRORS.REQUIRED')),
  typeId: yup.string().required(i18n.t('ERRORS.REQUIRED')),
  signers: yup
    .array()
    .of(yup.string())
    .required(i18n.t('ERRORS.REQUIRED'))
    .min(2, i18n.t('ERRORS.ARRAY_MIN', { min: 2 })),
  verify: yup.boolean(),
  supervisorId: yup.string().when('verify', {
    is: true,
    then: yup.string().required(i18n.t('ERRORS.REQUIRED')),
  }),
  signature: yup.array().ensure().min(1, i18n.t('ERRORS.SIGNATURE_OPTIONS_REQUIRED')),
});
