import qs from 'qs';
import axios from 'axios';
import {
  IGetProfileData,
  IUpdateProfileData,
  IUpdateProfilePasswordData,
  IUpdateInvitationPasswordData,
} from './types';

class ProfileService {
  getProfile = ({ selection }: IGetProfileData) =>
    axios.get('auth/profile', {
      params: { selection },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  updateProfile = (data: IUpdateProfileData) => axios.patch('auth/profile', data);
  updateProfileCover = (data?: FormData) =>
    axios.patch('auth/profile/cover', data, { headers: { 'Content-Type': 'multipart/form-data' } });
  updateProfilePassword = ({ password, oldPassword }: IUpdateProfilePasswordData) =>
    axios.patch('auth/profile/password', {
      password,
      oldPassword,
    });
  updateInvitationPassword = ({ email, password, oldPassword }: IUpdateInvitationPasswordData) =>
    axios.patch('auth/invitation/password', {
      email,
      password,
      oldPassword,
    });
}

export default new ProfileService();
