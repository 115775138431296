import React, { useEffect } from 'react';
import useResizeObserver from 'use-resize-observer';
import { Document, Page, pdfjs, PDFPageProxy } from 'react-pdf';
import { Space, Spin } from 'antd';
import Button from '../ui/Button';
import { IconsTypes } from '../ui/Icon/types';
import Icon from '../ui/Icon';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks';

import './PdfViewer.scss';
import { setPdfOptions } from '../../store/documents/documentsSlice';
import { IParentSize } from '../../utils/functions';

interface IPdfViewerProps {
  file: File | string;
  removeHandler?: () => void;
  onResize?: (options: IParentSize) => void;
  children?: React.ReactNode;
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer: React.FC<IPdfViewerProps> = ({ file, removeHandler, onResize, children }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { ref, width = 1, height = 1 } = useResizeObserver<HTMLCanvasElement>({ round: (n) => n });
  const { numPages, pageNumber } = useAppSelector((state) => state.documents.pdf);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    dispatch(
      setPdfOptions({
        numPages,
        pageNumber: 1,
      }),
    );
  };

  const changePage = (offset: number) => {
    dispatch(
      setPdfOptions({
        pageNumber: pageNumber + offset,
      }),
    );
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  useEffect(() => {
    onResize && onResize({ width, height });
  }, [width, height]);

  useEffect(() => {
    dispatch(
      setPdfOptions({
        pageNumber: 1,
        numPages: null,
      }),
    );
  }, []);

  return (
    <div className='pdfViewer' style={{ height }}>
      <Document
        file={file}
        loading={<Spin />}
        noData={t('DOCUMENTS.UPLOAD_NO_DATA')}
        error={t('DOCUMENTS.UPLOAD_ERROR')}
        externalLinkRel='noopener noreferrer nofollow'
        externalLinkTarget='_blank'
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page
          width={width}
          pageNumber={pageNumber}
          canvasRef={ref}
          // onLoadSuccess={pageLoadingHandler}
        />
        {numPages && numPages > 1 ? (
          <div className='pdfViewer-controls'>
            <Button type='link' justIcon={true} disabled={pageNumber <= 1} onClick={previousPage}>
              <Icon icon={IconsTypes.ARROW_LEFT} />
            </Button>
            <span>
              Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
            </span>
            <Button
              type='link'
              justIcon={true}
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              <Icon icon={IconsTypes.ARROW_RIGHT} />
            </Button>
          </div>
        ) : null}
        <Space className='pdfViewer-actions'>
          {typeof file === 'string' ? (
            <Button
              type='primary'
              justIcon={true}
              onClick={() => {
                window.open(file, '_blank', 'noopener,noreferrer');
              }}
            >
              <Icon icon={IconsTypes.SHOW} size={20} />
            </Button>
          ) : null}
          {removeHandler ? (
            <Button type='error' justIcon={true} onClick={removeHandler}>
              <Icon icon={IconsTypes.BUCKET} size={20} />
            </Button>
          ) : null}
        </Space>
      </Document>
      {children}
    </div>
  );
};

export default PdfViewer;
