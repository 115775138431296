import * as yup from 'yup';
import i18n from '../i18n';

const FILE_SIZE = 1024 * 1024;
const SUPPORTED_FORMATS = ['application/pdf'];
export const signInSchema = yup.object().shape({
  email: yup.string().required('This field is required').email(),
  password: yup.string().required('This field is required'),
});

export const documentSchema = yup.object().shape({
  name: yup.string().required('This field is required'),
  type: yup.string().required('This field is required'),
  signer1: yup.string().required('This field is required'),
  signer2: yup.string().required('This field is required'),
  supervisor: yup.string(),
});

export const nameSchema = yup
  .string()
  .min(2, i18n.t('ERRORS.STRING_MIN', { min: 2 }))
  .max(32, i18n.t('ERRORS.STRING_MAX', { max: 32 }))
  .required(i18n.t('ERRORS.REQUIRED'));

export const lastNameSchema = yup
  .string()
  .min(2, i18n.t('ERRORS.STRING_MIN', { min: 2 }))
  .max(32, i18n.t('ERRORS.STRING_MAX', { max: 32 }))
  .required(i18n.t('ERRORS.REQUIRED'));

export const emailSchema = yup
  .string()
  .email(i18n.t('ERRORS.EMAIL'))
  .required(i18n.t('ERRORS.REQUIRED'));

export const passwordSchema = yup
  .string()
  .min(8, 'Password should be at least 8 characters long')
  .required('This field is required');

export const resetPasswordSchema = yup.object().shape({
  email: yup.string().required('This field is required').email(),
});

export const confirmPasswordSchema = yup.object().shape({
  email: yup.string().required('This field is required').email(),
  code: yup.string().required('This field is required'),
});
