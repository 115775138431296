import React from 'react';
import { Space, Typography } from 'antd';
import { IUser, UserRoles, UserStatuses } from '../../../../utils/types';
import Badge from '../../../../components/ui/Badge';
import { getFormattedDate } from '../../../../utils/functions';
import { MONTH_FORMAT } from '../../../../utils/constants';
import Button from '../../../../components/ui/Button';
import Icon from '../../../../components/ui/Icon';
import { IconsTypes } from '../../../../components/ui/Icon/types';
import { TFunction } from 'react-i18next';
import { IDataType } from '../../../../store/documents/types';
import { ColumnsType } from 'antd/es/table';
import { BadgeTypes } from 'components/ui/Badge/types';
import { IUsersOptions } from 'store/users/types';
const { Text } = Typography;

export const getUsersColumns = (
  t: TFunction,
  { sorter }: IUsersOptions,
  user: IUser | null,
  editUserModalHandler: (arg0: string) => void,
  deleteUserConfirm: (arg0: string) => void,
): ColumnsType<IDataType> => [
  {
    title: t('USERS.LABELS.NAME'),
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    sortOrder: sorter?.field === 'name' ? sorter.order : null,
  },
  {
    title: t('USERS.LABELS.LAST_NAME'),
    dataIndex: 'lastName',
    key: 'lastName',
    sorter: true,
    sortOrder: sorter?.field === 'lastName' ? sorter.order : null,
  },
  {
    title: t('USERS.LABELS.EMAIL'),
    dataIndex: 'email',
    key: 'email',
    render: (email) => {
      if (!email.length) {
        return null;
      }
      return <Text ellipsis={{ tooltip: email }}>{email}</Text>;
    },
  },
  {
    title: t('USERS.LABELS.STATUS'),
    dataIndex: 'status',
    key: 'status',
    render: (status) => {
      let type = BadgeTypes.PRIMARY;
      switch (status) {
        case UserStatuses.PENDING:
          type = BadgeTypes.SECONDARY;
          break;
        case UserStatuses.ACTIVATED:
          type = BadgeTypes.SUCCESS;
          break;
        case UserStatuses.DEACIVATED:
          type = BadgeTypes.ERROR;
          break;
      }
      return <Badge text={status} type={type} />;
    },
  },
  {
    title: t('USERS.LABELS.REGISTRATION'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (date) => getFormattedDate(date, MONTH_FORMAT),
    sorter: true,
    sortOrder: sorter?.field === 'updatedAt' ? sorter.order : null,
    defaultSortOrder: 'descend',
  },
  ...(user?.role !== UserRoles.USER
    ? [
        {
          title: 'Actions',
          key: 'actions',
          render: (_: any, record: IDataType) => (
            <Space>
              <Button justIcon={true} onClick={() => editUserModalHandler(record.id)}>
                <Icon icon={IconsTypes.EDIT} size={20} />
              </Button>
              <Button justIcon={true} onClick={() => deleteUserConfirm(record.id)}>
                <Icon icon={IconsTypes.BUCKET} size={20} />
              </Button>
            </Space>
          ),
        },
      ]
    : []),
];
