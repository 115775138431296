import React, { useState } from 'react';
import classNames from 'classnames';
import { BadgeTypes } from './types';
import { Typography } from 'antd';

const { Text } = Typography;

import './Badge.scss';

interface IBadgeProps {
  text: string;
  type?: BadgeTypes;
  className?: string;
}

const Badge: React.FC<IBadgeProps> = ({ text, type = BadgeTypes.PRIMARY, className }) => {
  const [ellipsis, setEllipsis] = useState(false);
  const classes = classNames({
    badge: true,
    [`badge--${type}` as string]: !!type,
    [className as string]: !!className,
  });
  return (
    <Text
      ellipsis={{
        tooltip: ellipsis ? text : undefined,
        onEllipsis: (ellipsis) => setEllipsis(ellipsis),
      }}
      className={classes}
    >
      {text}
    </Text>
  );
};

export default Badge;
