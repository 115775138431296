import React, { FC } from 'react';
import { DocumentVerificationStatuses } from '../../../../../utils/types';
import { Space } from 'antd';
import Button from '../../../../../components/ui/Button';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import { useTranslation } from 'react-i18next';
import { updateDocumentVerificationStatus } from '../../../../../store/documents/documentsSlice';
import { useParams } from 'react-router-dom';

interface ISupervisorButtons {
  onSetVerificationStatus: (verificationStatus: DocumentVerificationStatuses) => void;
}

const SupervisorButtons: FC<ISupervisorButtons> = ({ onSetVerificationStatus }) => {
  const { t } = useTranslation();

  const { user } = useAppSelector((state) => state.profile);
  const { document, updateDocumentVerificationStatusLoading } = useAppSelector(
    (state) => state.documents,
  );

  if (!user || !document) {
    return null;
  }

  const isSupervisor = user.id === document.supervisor?.id;
  const isDocumentVerifyPending =
    document.verificationStatus === DocumentVerificationStatuses.PENDING ||
    document.verificationStatus === DocumentVerificationStatuses.VIEWED;

  return (
    <>
      {isSupervisor && isDocumentVerifyPending ? (
        <>
          <p className='documentViewPage-roleText'>{t('DOCUMENT_VIEW_PAGE.SUPERVISOR.TEXT')}</p>
          <Space size={12}>
            <Button
              type='primary'
              onClick={() => onSetVerificationStatus(DocumentVerificationStatuses.APPROVED)}
              spinning={updateDocumentVerificationStatusLoading}
            >
              {t('BUTTONS.VERIFY')}
            </Button>
            <Button
              type='error'
              onClick={() => onSetVerificationStatus(DocumentVerificationStatuses.NOT_APPROVED)}
              spinning={updateDocumentVerificationStatusLoading}
            >
              {t('BUTTONS.REJECT')}
            </Button>
          </Space>
        </>
      ) : null}
    </>
  );
};

export default SupervisorButtons;
