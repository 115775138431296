import React from 'react';
import { useTranslation } from 'react-i18next';
import HookedField from '../../../components/HookedField';
import { FormProvider, useForm } from 'react-hook-form';
import { FieldsTypes } from 'components/HookedField/types';
import { IConfirmInvitationData } from 'store/auth/types';
import Button from 'components/ui/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { confirmInvitationSchema } from './schema';
import { confirmInvitation } from 'store/auth/authSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Spin } from 'antd';

import './ConfirmInvitationPage.scss';

const ConfirmInvitationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const { confirmInvitationLoading } = useAppSelector((state) => state.auth);

  const email = searchParams.get('email');
  const oldPassword = searchParams.get('password');

  const methods = useForm<IConfirmInvitationData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    resolver: yupResolver(confirmInvitationSchema),
    defaultValues: {
      email: email || '',
      password: '',
      oldPassword: oldPassword || '',
    },
  });

  const submitHandler = async (data: IConfirmInvitationData) => {
    await dispatch(confirmInvitation({ data, navigate }));
  };

  const {
    formState: { isValid },
  } = methods;

  return (
    <div className='confirmInvitationPage'>
      <h2 className='confirmInvitationPage-title'>{t('CONFIRM_INVITATION.TITLE')}</h2>
      <p className='confirmInvitationPage-description'>{t('CONFIRM_INVITATION.DESCRIPTION')}</p>
      <FormProvider {...methods}>
        <Spin spinning={confirmInvitationLoading}>
          <form
            className='confirmInvitationPage-form'
            autoComplete='off'
            onSubmit={methods.handleSubmit(submitHandler)}
          >
            <HookedField
              name='email'
              type={FieldsTypes.TEXT}
              label={t('CONFIRM_INVITATION.FIELDS.EMAIL')}
              placeholder='Email'
            />
            <HookedField
              name='password'
              type={FieldsTypes.PASSWORD}
              label={t('CONFIRM_INVITATION.FIELDS.CREATE_PASSWORD')}
              placeholder={t('CONFIRM_INVITATION.FIELDS.PASSWORD_PLACEHOLDER')}
            />
            <HookedField
              name='repeatPassword'
              type={FieldsTypes.PASSWORD}
              label={t('CONFIRM_INVITATION.FIELDS.REPEAT_PASSWORD')}
              placeholder={t('CONFIRM_INVITATION.FIELDS.PASSWORD_PLACEHOLDER')}
            />
            <Button fullWidth={true} htmlType='submit' disabled={!isValid}>
              {t('CONFIRM_INVITATION.BUTTON_SUBMIT_NAME')}
            </Button>
          </form>
        </Spin>
      </FormProvider>
    </div>
  );
};

export default ConfirmInvitationPage;
