import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import HookedField from '../../../components/HookedField';
import { signInSchema } from 'utils/shemas';
import { FieldsTypes } from 'components/HookedField/types';
import { ISignInData } from 'store/auth/types';
import Button from 'components/ui/Button';
import './SignInPage.scss';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import { signIn } from '../../../store/auth/authSlice';
import { Spin } from 'antd';

const SignInPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { signInLoading } = useAppSelector((state) => state.auth);

  const methods = useForm<ISignInData>({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: yupResolver(signInSchema),
  });

  const submitHandler = async (data: ISignInData) => {
    dispatch(signIn({ data, navigate }));
  };

  const {
    formState: { isValid },
  } = methods;

  return (
    <div className='signInPage'>
      <div className='signInPage-container'>
        <h2 className='signInPage-title'>{t('SIGN_IN.TITLE')}</h2>
        <FormProvider {...methods}>
          <Spin spinning={signInLoading}>
            <form
              className='signInPage-form'
              autoComplete='off'
              onSubmit={methods.handleSubmit(submitHandler)}
            >
              <HookedField
                name='email'
                type={FieldsTypes.TEXT}
                label={t('LABELS.EMAIL')}
                placeholder='ein-des-ein@ein-des-ein.com'
              />
              <HookedField
                name='password'
                type={FieldsTypes.PASSWORD}
                label={t('LABELS.PASSWORD')}
                placeholder='password'
              />
              <div className='signInPage-btnForgot-box'>
                <Button
                  className='signInPage-btn-forgot'
                  type='link-inverse'
                  onClick={() => navigate('/auth/reset-password')}
                >
                  {t('SIGN_IN.BUTTON_FORGET_NAME')}
                </Button>
              </div>
              <Button
                className='signInPage-btn-submit'
                fullWidth={true}
                htmlType='submit'
                disabled={!isValid}
              >
                {t('SIGN_IN.BUTTON_SUBMIT_NAME')}
              </Button>
              <div className='signInPage-btnAcc-box'>
                <p className='signInPage-acc-description'>{t('SIGN_IN.ACC_DESCRIPTION')}</p>
                <Button
                  className='signInPage-btn-ask'
                  type='link-secondary'
                  onClick={() => navigate('/auth/ask-us')}
                >
                  {t('SIGN_IN.BUTTON_ASK_NAME')}
                </Button>
              </div>
            </form>
          </Spin>
        </FormProvider>
      </div>
    </div>
  );
};

export default SignInPage;
