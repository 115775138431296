import React, { FC } from 'react';

import './HistoryItem.scss';
import { Col, Row, Space } from 'antd';
import Button from '../../../../../components/ui/Button';
import Icon from '../../../../../components/ui/Icon';
import { IconsTypes } from '../../../../../components/ui/Icon/types';
import { useTranslation } from 'react-i18next';
import Badge from '../../../../../components/ui/Badge';
import { IDocumentVersion } from '../../../../../store/documents/types';
import { IDocument } from '../../../../../utils/types';
import {
  getDocumentSignBadge,
  getDocumentVerificationBadge,
  getFormattedDate,
} from '../../../../../utils/functions';
import JsFileDownloader from 'js-file-downloader';

interface IHistoryItem {
  history: IDocumentVersion;
  document: IDocument;
}

const HistoryItem: FC<IHistoryItem> = ({ history, document }) => {
  const { t } = useTranslation();

  return (
    <div className='history-item'>
      <div className='history-item-block history-item-header'>
        <div className='history-item-title-wrapper'>
          <Icon className='history-item-doc-icon' icon={IconsTypes.DOCUMENTS} />
          <div>
            <div className='history-item-title'>
              {document.type.name} {document.name}
            </div>
            <div className='history-item-subtitle'>
              {getFormattedDate(history.createdAt, 'MMM DD, YYYY')}
            </div>
          </div>
        </div>
        <Space size={16}>
          <Button
            onClick={() => window.open(history.file.src, '_blank', 'noopener,noreferrer')}
            justIcon
            type='link'
          >
            <Icon icon={IconsTypes.SHOW} />
          </Button>
          <Button
            onClick={() => {
              new JsFileDownloader({ url: history.file.src });
            }}
          >
            <Icon icon={IconsTypes.DOWNLOAD} />
            <span>{t('BUTTONS.DOWNLOAD')}</span>
          </Button>
        </Space>
      </div>
      {document.supervisor && (
        <div className='history-item-block'>
          <Row className='history-item-block-row' align='middle'>
            <Col className='history-item-info-label' span={6}>
              {t('DOCUMENTS.LABELS.VERIFICATOR')}:
            </Col>
            <Col className='history-item-info-content' span={6}>
              {document.supervisor.name} {document.supervisor.lastName}
            </Col>
            <Col span={6}>
              <Badge {...getDocumentVerificationBadge({ status: history.verificationStatus, t })} />
            </Col>
            <Col className='history-item-info-date' span={6}>
              {history.verificationAt &&
                getFormattedDate(history.verificationAt, 'MMM DD, YYYY HH:mm')}
            </Col>
          </Row>
        </div>
      )}
      <div className='history-item-block'>
        {history.signers.map((signer, index) => {
          return (
            <Row key={index} className='history-item-block-row' align='middle'>
              <Col className='history-item-info-label' span={6}>
                {!index && `${t('DOCUMENTS.LABELS.SIGNERS')}:`}
              </Col>
              <Col className='history-item-info-content' span={6}>
                {signer.user.name} {signer.user.lastName}
              </Col>
              <Col span={6}>
                <Badge {...getDocumentSignBadge({ status: signer.signature?.status, t })} />
              </Col>
              <Col className='history-item-info-date' span={6}>
                {signer.signature?.updatedAt &&
                  getFormattedDate(signer.signature.updatedAt, 'MMM DD, YYYY HH:mm')}
              </Col>
            </Row>
          );
        })}
      </div>
    </div>
  );
};

export default HistoryItem;
