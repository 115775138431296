import React from 'react';
import './Timer.scss';
import Icon from '../Icon';
import { IconsTypes } from '../Icon/types';
import { useTranslation } from 'react-i18next';

interface ITimer {
  time: number;
}

const Timer: React.FC<ITimer> = ({ time }) => {
  const { t } = useTranslation();
  return (
    <div className='timer'>
      <Icon className='timer-icon' icon={IconsTypes.TIMER} size={20} />
      <span className='timer-text'>{time}</span>
    </div>
  );
};

export default Timer;
