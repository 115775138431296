import { UserRoles } from '../utils/types';
import { IProtectedRoute } from './types';
import DocumentsPage from '../pages/Main/DocumentsPage';
import ProfilePage from '../pages/Main/ProfilePage';
import UsersPage from '../pages/Main/UsersPage';
import DocumentAddPage from '../pages/Main/DocumentAddPage';
import DocumentViewPage from '../pages/Main/DocumentViewPage';
import SignInPage from '../pages/Auth/SignInPage';
import ResetPasswordPage from '../pages/Auth/ResetPasswordPage';
import CreatePasswordPage from 'pages/Auth/CreatePasswordPage';
import ConfirmPasswordPage from 'pages/Auth/ConfirmPasswordPage';
import ConfirmInvitationPage from 'pages/Auth/ConfirmInvitationPage';
import UserDocumentsPage from '../pages/Main/UserDocumentsPage';
import AskUsPage from '../pages/Auth/AskUsPage';

export const authProtectedRoutes: IProtectedRoute[] = [
  {
    path: '/documents',
    component: DocumentsPage,
    roles: [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.USER],
    rerender: true,
  },
  {
    path: '/documents/:id',
    component: DocumentsPage,
    roles: [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.USER],
    rerender: true,
  },
  {
    path: '/documents/add',
    component: DocumentAddPage,
    roles: [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.USER],
  },
  {
    path: '/documents/view/:id',
    component: DocumentViewPage,
    roles: [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.USER],
  },
  {
    path: '/documents/user/:id',
    component: UserDocumentsPage,
    roles: [UserRoles.SUPER_ADMIN, UserRoles.ADMIN],
    rerender: true,
  },
  {
    path: '/profile',
    component: ProfilePage,
    roles: [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.USER],
  },
  {
    path: '/users',
    component: UsersPage,
    roles: [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.USER],
  },
];

export const publicRoutes = [
  {
    path: '/sign-in',
    component: SignInPage,
  },
  {
    path: '/reset-password',
    component: ResetPasswordPage,
  },
  {
    path: '/confirm-password',
    component: ConfirmPasswordPage,
  },
  {
    path: '/create-password',
    component: CreatePasswordPage,
  },
  {
    path: '/confirm-invitation',
    component: ConfirmInvitationPage,
  },
  {
    path: '/ask-us',
    component: AskUsPage,
  },
];
