import React from 'react';
import { Collapse } from 'antd';
import { IUser } from '../../../../../utils/types';
import UserItem from '../UserItem';
import { useTranslation } from 'react-i18next';

import './Owner.scss';

const { Panel } = Collapse;

interface ISupervisorProps {
  owner: IUser;
}

const Owner: React.FC<ISupervisorProps> = ({ owner }) => {
  const { t } = useTranslation();

  return (
    <Collapse activeKey={['1']} className='supervisor'>
      <Panel header={t('DOCUMENT_VIEW_PAGE.OWNER.TITLE')} key='1' showArrow={false}>
        <UserItem user={owner} />
      </Panel>
    </Collapse>
  );
};

export default Owner;
