import { configureStore } from '@reduxjs/toolkit';
import profileReducer from './profile/profileSlice';
import usersReducer from './users/usersSlice';
import authReducer from './auth/authSlice';
import documentsReducer from './documents/documentsSlice';
import documentTypesReducer from './documentsTypes/documentTypesSlice';
import appReducer from './app/appSlice';
import chatReducer from './chat/chatSlice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    profile: profileReducer,
    users: usersReducer,
    documents: documentsReducer,
    documentTypes: documentTypesReducer,
    chat: chatReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
