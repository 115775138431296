import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Heading from '../../../components/ui/Heading';

import './DocumentAddPage.scss';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { documentSchema, documentSchemaWithSign } from './schema';
import { IDocumentDetailForm } from './types';
import { Space, Spin } from 'antd';
import HookedField from '../../../components/HookedField';
import { FieldsTypes } from '../../../components/HookedField/types';
import { useAppDispatch, useAppSelector, useDebounce } from '../../../hooks';
import { getUsers } from '../../../store/users/usersSlice';
import { getDocumentTypes } from '../../../store/documentsTypes/documentTypesSlice';
import Button from '../../../components/ui/Button';
import UploadPdf from '../../../components/form/UploadPdf';
import PdfViewer from '../../../components/PdfViewer';
import { createDocument } from '../../../store/documents/documentsSlice';
import { useNavigate } from 'react-router-dom';
import { UserStatus } from 'store/users/types';
import { useUnsavePrompt } from 'hooks/useUnsavePrompt';
import { UserRoles } from '../../../utils/types';

const DocumentAddPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce<string>(searchValue, 500);

  const { users, getUsersLoading } = useAppSelector((state) => state.users);
  const { user } = useAppSelector((state) => state.profile);
  const { types, getTypesLoading } = useAppSelector((state) => state.documentTypes);
  const { createDocumentLoading } = useAppSelector((state) => state.documents);
  const [setBlock] = useUnsavePrompt({});

  const isPossibleToSign = !!process.env.REACT_APP_FORM_URL;

  const methods = useForm<IDocumentDetailForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    resolver: yupResolver(isPossibleToSign ? documentSchemaWithSign : documentSchema),
  });

  const {
    formState: { isValid, isDirty },
  } = methods;

  const {
    watch,
    formState: { errors },
  } = methods;

  const verifyWatch = watch('verify');
  const fileWatch = watch('file');
  const isAlreadySigned = watch('isAlreadySigned');

  useEffect(() => {
    const fieldWithError = document.querySelector('.invalid');
    if (!fieldWithError) {
      return;
    }
    fieldWithError.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }, [errors]);

  useEffect(() => {
    refreshUsers();
    dispatch(getDocumentTypes({}));
  }, []);

  const refreshUsers = (search?: string) => {
    dispatch(
      getUsers({
        pagination: {
          current: 1,
          pageSize: 50,
        },
        filters: null,
        sorter: {
          field: 'updatedAt',
          order: 'descend',
        },
        search,
        status: UserStatus.ACTIVATED,
      }),
    );
  };

  useEffect(() => {
    if (debouncedSearchValue.length === 0) {
      refreshUsers();
    }
    if (debouncedSearchValue.length < 3) {
      return;
    }
    refreshUsers(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const changeHandler = async (tags: string[], option: any, setValue: (value: any) => void) => {
    if (!setValue) {
      return;
    }
    setValue(tags);
  };

  const submitHandler = ({
    name,
    typeId,
    file,
    signers,
    verify,
    supervisorId,
    signature,
    isAlreadySigned,
  }: IDocumentDetailForm) => {
    const signWithSignature = isPossibleToSign ? signature.includes('signWithSignature') : false;
    const signWithPicture = isPossibleToSign ? signature.includes('signWithPicture') : true;

    dispatch(
      createDocument({
        data: {
          file,
          name,
          typeId,
          signers: signers.join(','),
          supervisorId,
          signWithSignature,
          signWithPicture,
          isAlreadySigned,
        },
        navigate,
      }),
    );
    setBlock(false);
  };

  return (
    <div className='documentAddPage'>
      <Heading title={t('DOCUMENT_ADD_PAGE.HEADING.TITLE')} withBackBtn />
      <FormProvider {...methods}>
        <Spin spinning={createDocumentLoading}>
          <form
            className='form'
            autoComplete='off'
            onSubmit={methods.handleSubmit(submitHandler)}
            onChange={() => setBlock(true)}
          >
            <div className='documentAddPage-grid'>
              <div className='documentAddPage-file'>
                {fileWatch ? (
                  <PdfViewer file={fileWatch} removeHandler={() => methods.resetField('file')} />
                ) : (
                  <UploadPdf name='file' />
                )}
              </div>
              <div className='documentAddPage-info'>
                <div className='documentAddPage-info-fields'>
                  <h4 className='documentAddPage-info-title'>{t('DOCUMENT_ADD_PAGE.DETAILS')}</h4>
                  <HookedField
                    type={FieldsTypes.TEXT}
                    name='name'
                    label={t('DOCUMENT_ADD_PAGE.FIELDS.NAME')}
                  />
                  <HookedField
                    name='typeId'
                    type={FieldsTypes.SELECT}
                    label={t('DOCUMENT_ADD_PAGE.FIELDS.TYPE')}
                    placeholder={t('DOCUMENT_ADD_PAGE.FIELDS.TYPE_PLACEHOLDER')}
                    options={types.map(({ id, name }) => ({ value: id, label: name }))}
                  />
                  <HookedField
                    name='signers'
                    type={FieldsTypes.SELECT_TAGS}
                    label={t('DOCUMENT_ADD_PAGE.FIELDS.SIGNERS')}
                    options={users.map(({ id, email }) => ({ value: id, label: email }))}
                    optionFilterProp='label'
                    onSelect={() => setSearchValue('')}
                    customChangeTags={changeHandler}
                    onSearch={setSearchValue}
                    loading={getUsersLoading}
                  />
                  {user && user.role === UserRoles.ADMIN && (
                    <HookedField
                      name='isAlreadySigned'
                      type={FieldsTypes.CHECKBOX}
                      placeholder={t('DOCUMENT_ADD_PAGE.FIELDS.IS_ALREADY_SIGNED')}
                    />
                  )}
                  {!isAlreadySigned && (
                    <HookedField
                      name='verify'
                      type={FieldsTypes.CHECKBOX}
                      placeholder={t('DOCUMENT_ADD_PAGE.FIELDS.NEED_TO_VERIFY')}
                    />
                  )}

                  {verifyWatch && (
                    <HookedField
                      name='supervisorId'
                      type={FieldsTypes.SELECT}
                      label={t('DOCUMENT_ADD_PAGE.FIELDS.SUPERVISOR')}
                      options={users.map(({ id, email }) => ({ value: id, label: email }))}
                    />
                  )}
                  {isPossibleToSign ? (
                    <>
                      <p className='documentAddPage-info-text'>{t('DOCUMENT_ADD_PAGE.TEXT')}</p>
                      <HookedField
                        hidden={!isPossibleToSign}
                        name='signature'
                        type={FieldsTypes.RADIOS}
                        options={[
                          {
                            label: t('DOCUMENT_ADD_PAGE.FIELDS.SIGN_WITH_A_SIGNATURE'),
                            value: 'signWithSignature',
                          },
                          {
                            label: t('DOCUMENT_ADD_PAGE.FIELDS.SIGN_WITH_A_PICTURE'),
                            value: 'signWithPicture',
                          },
                        ]}
                      />
                    </>
                  ) : null}
                  <Space>
                    <Button type='secondary' onClick={() => methods.reset()} disabled={!isDirty}>
                      {t('BUTTONS.RESET')}
                    </Button>
                    <Button type='primary' htmlType='submit' disabled={!isValid}>
                      {t('BUTTONS.SAVE')}
                    </Button>
                  </Space>
                </div>
              </div>
            </div>
          </form>
        </Spin>
      </FormProvider>
    </div>
  );
};

export default DocumentAddPage;
