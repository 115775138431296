import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import HookedField from '../../../../../../components/HookedField';
import { FieldsTypes } from '../../../../../../components/HookedField/types';
import { Input } from 'antd';
import { IUpdateProfileData } from '../../../../../../store/profile/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { changeProfileSchema } from '../../../constants';
import { updateProfile } from '../../../../../../store/profile/profileSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../components/ui/Button';

const BasicInfo = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.profile);

  const changeProfileForm = useForm<IUpdateProfileData>({
    mode: 'onSubmit',
    shouldFocusError: true,
    resolver: yupResolver(changeProfileSchema),
    defaultValues: {
      name: user?.name || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
    },
  });

  const updateProfileHandler = (data: IUpdateProfileData) => dispatch(updateProfile(data));

  return (
    <div className='profile-info'>
      <FormProvider {...changeProfileForm}>
        <form
          className='form'
          autoComplete='off'
          onSubmit={changeProfileForm.handleSubmit(updateProfileHandler)}
        >
          <h4 className='title'>{t('PROFILE.PERSONAL_INFO')}</h4>
          <div className='profile-info-container'>
            <HookedField
              name='name'
              type={FieldsTypes.TEXT}
              label={t('LABELS.NAME')}
              placeholder={t('LABELS.NAME')}
            />
            <HookedField
              name='lastName'
              type={FieldsTypes.TEXT}
              label={t('LABELS.LAST_NAME')}
              placeholder={t('LABELS.LAST_NAME')}
            />
            <HookedField
              name='email'
              label={t('LABELS.EMAIL')}
              type={FieldsTypes.TEXT}
              placeholder={t('LABELS.EMAIL')}
            />
            <div className='profile-info-input-container'>
              <p className='profile-label'>{t('LABELS.ROLE')}</p>
              <Input className='profile-input' disabled defaultValue={user?.role} />
            </div>
            <Button htmlType='submit' fullWidth>
              {t('BUTTONS.SAVE_CHANGES')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default BasicInfo;
