import axios from 'axios';
import { ICreateChatData, IGetChatsData, IGetChatMessagesData, IDeleteChatData } from './types';
import qs from 'qs';

class ChatService {
  createChat = (data: ICreateChatData) => axios.post('chats/personal', data);
  getChats = (params: IGetChatsData) =>
    axios.get('chats', {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  getChatMessages = ({ id, ...params }: IGetChatMessagesData) =>
    axios.get(`chats/${id}/messages`, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  deleteChat = ({ id }: IDeleteChatData) => axios.delete(`chats/${id}`);
}

export default new ChatService();
