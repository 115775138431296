import { Dayjs } from 'dayjs';

export enum FieldsTypes {
  TEXT = 'TEXT',
  TEXTAREA = 'TEXTAREA',
  PASSWORD = 'PASSWORD',
  SELECT = 'SELECT',
  SELECT_TAGS = 'SELECT_TAGS',
  CHECKBOX = 'CHECKBOX',
  CHECKBOXES = 'CHECKBOXES',
  RADIOS = 'RADIOS',
  NUMBER = 'NUMBER',
  TIME = 'TIME',
  TIME_RANGE = 'TIME_RANGE',
  PHONE = 'PHONE',
  VERIFICATION = 'VERIFICATION',
  DATE_PICKER = 'DATE_PICKER',
}

export interface HookedFieldProps {
  name: string;
  type: keyof typeof FieldsTypes;
  label?: string;
  caption?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  defaultValue?: any;
  options?: {
    value: string;
    label: string;
  }[];
  showSearch?: boolean;
  beforeUpload?: () => void;
  showCount?: boolean;
  maxLength?: number;
  bordered?: boolean;
  addonBefore?: string;
  addonAfter?: string;
  inOneRow?: boolean;
  min?: number;
  max?: number;
  mode?: 'multiple' | 'tags';
  maxTagCount?: number;
  onSelect?: (value: string, option: any) => void;
  onSearch?: (value: string) => void;
  loading?: boolean;
  optionFilterProp?: string;
  customChangeTags?: (tags: string[], option: any, setValue: (value: any) => void) => void;
  length?: number;
  autoFocus?: boolean;
  validChars?: string;
  hidden?: boolean;
  disabledDate?: ((date: Dayjs) => boolean) | undefined;
}

export interface FieldProps {
  value?: any;
  onChange?: (value: any) => void;
}
