import { emailSchema, lastNameSchema, nameSchema, passwordSchema } from 'utils/shemas';
import * as yup from 'yup';

export const profileDeleteModalConfig = {
  title: 'Delete profile?',
  content: 'If you are sure of your decision, press the red button.',
  okText: 'Delete',
};

export const changeProfileSchema = yup.object().shape({
  name: nameSchema,
  lastName: lastNameSchema,
  email: emailSchema,
});

export const changePasswordSchema = yup.object().shape({
  oldPassword: passwordSchema,
  password: passwordSchema,
});
