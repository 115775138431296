import React from 'react';
import { Collapse } from 'antd';
import { ISigner, SignatureStatuses } from '../../../../../utils/types';
import UserItem from '../UserItem';
import { useTranslation } from 'react-i18next';

import './SignersList.scss';
import { BadgeTypes } from '../../../../../components/ui/Badge/types';

const { Panel } = Collapse;

interface ISignersProps {
  signers: ISigner[];
}

const SignersList: React.FC<ISignersProps> = ({ signers }) => {
  const { t } = useTranslation();

  const getSignerStatus = (status: SignatureStatuses) => {
    if (!status) {
      return {
        text: t('DOCUMENT_VIEW_PAGE.SIGNERS.UNSIGNED'),
        type: BadgeTypes.ERROR,
      };
    }
    let type = BadgeTypes.PRIMARY;
    if (status === SignatureStatuses.SIGNED) {
      type = BadgeTypes.SUCCESS;
    } else if (status === SignatureStatuses.REJECTED) {
      type = BadgeTypes.ERROR;
    } else if (status === SignatureStatuses.UNSIGNED) {
      type = BadgeTypes.ERROR;
    } else if (status === SignatureStatuses.VIEWED) {
      type = BadgeTypes.SECONDARY;
    }
    return {
      text: t(`DOCUMENT_VIEW_PAGE.SIGNERS.${status}`),
      type,
    };
  };

  return (
    <Collapse className='signersList' defaultActiveKey={['1']} expandIconPosition='end'>
      <Panel header={t('DOCUMENT_VIEW_PAGE.SIGNERS.TITLE', { total: signers.length })} key='1'>
        {signers.map((signer) => {
          return (
            <UserItem
              key={signer.id}
              user={signer.user}
              status={getSignerStatus(signer.signature?.status)}
            />
          );
        })}
      </Panel>
    </Collapse>
  );
};

export default SignersList;
