import React, { Dispatch, forwardRef, RefObject, SetStateAction } from 'react';
import Button from '../../../../../components/ui/Button';
import { Trans, useTranslation } from 'react-i18next';
import SignatureDrawer from '../../../../../components/SignatureDrawer';
import Modal from '../../../../../components/ui/Modal';
import ReactSignatureCanvas from 'react-signature-canvas';

interface IDrawerModal {
  isDrawerOpen: boolean;
  onSetDrawerOpen: Dispatch<SetStateAction<boolean>>;
  canvasRef: RefObject<ReactSignatureCanvas>;
  onAddSignatureOnPage: () => void;
  onSetSignatureSrc: Dispatch<SetStateAction<string>>;
  arePagesEmpty: boolean;
}

const DrawerModal = forwardRef<ReactSignatureCanvas, IDrawerModal>(
  (
    {
      isDrawerOpen,
      onSetDrawerOpen,
      canvasRef,
      onAddSignatureOnPage,
      onSetSignatureSrc,
      arePagesEmpty,
    },
    ref,
  ) => {
    const { t } = useTranslation();

    const onOkModalSignaturePicture = () => {
      const isEmpty = canvasRef.current?.isEmpty();
      if (isEmpty) {
        onSetDrawerOpen(false);
        return;
      }
      const signatureSrc = canvasRef.current?.toDataURL();

      if (!signatureSrc) {
        return;
      }
      if (arePagesEmpty) {
        onAddSignatureOnPage();
      }
      onSetSignatureSrc(signatureSrc);
      onSetDrawerOpen(false);
    };

    const onUploadSignatureDrawer = (signatureUrl: string) => {
      onSetDrawerOpen(false);
      onSetSignatureSrc(signatureUrl);
      onAddSignatureOnPage();
    };

    return (
      <Modal
        width='max-content'
        open={isDrawerOpen}
        title={t('MODALS.DRAW_A_SIGNATURE.TITLE')}
        onCancel={() => onSetDrawerOpen(false)}
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button type='secondary' onClick={() => canvasRef.current?.clear()}>
              {t('MODALS.DRAW_A_SIGNATURE.CONTENT.BUTTON_CLEAR')}
            </Button>
            <Button type='link-secondary' onClick={() => onSetDrawerOpen(false)}>
              {t('BUTTONS.CANCEL')}
            </Button>
            <Button onClick={onOkModalSignaturePicture}>{t('BUTTONS.SAVE')}</Button>
          </div>
        }
      >
        <Trans
          i18nKey='MODALS.DRAW_A_SIGNATURE.DESCRIPTION'
          components={{
            br: <br />,
          }}
        />
        <SignatureDrawer ref={ref} onUpload={onUploadSignatureDrawer} />
      </Modal>
    );
  },
);

DrawerModal.displayName = 'DrawerModal';

export default DrawerModal;
