import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Upload } from 'antd';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { useFormContext, useFormState } from 'react-hook-form';
import ErrorMessage from '../../ui/ErrorMessage';
import { get } from 'lodash';
import Icon from 'components/ui/Icon';
import { IconsTypes } from 'components/ui/Icon/types';

import './UploadPdf.scss';
import { useTranslation } from 'react-i18next';
import { beforeUpload } from 'utils/functions';
const { Dragger } = Upload;

interface IUploadPdfProps {
  name: string;
}

const UploadPdf: React.FC<IUploadPdfProps> = ({ name }) => {
  const { t } = useTranslation();
  const { register, setValue, clearErrors } = useFormContext();
  const { errors } = useFormState();
  const error = get(errors, name);
  useEffect(() => {
    register(name);
  }, []);

  const classes = classNames('uploadPdf', {
    'uploadPdf--error': !!error,
  });

  return (
    <>
      <div className={classes}>
        <Dragger
          name={name}
          multiple={false}
          showUploadList={false}
          accept='.pdf'
          beforeUpload={beforeUpload}
          customRequest={async ({ file, onSuccess }: UploadRequestOption<any>) => {
            setValue(name, file, { shouldDirty: true, shouldValidate: true });
            onSuccess && onSuccess('OK');
            clearErrors(name);
          }}
          onDrop={(e) => {
            console.log('Dropped files', e.dataTransfer.files);
          }}
        >
          <Icon icon={IconsTypes.UPLOAD} />
          <p className='ant-upload-text'>{t('DOCUMENTS.UPLOAD_TEXT')}</p>
          <p className='ant-upload-hint'>{t('DOCUMENTS.UPLOAD_HINT')}</p>
        </Dragger>
        {/* @ts-ignore*/}
        {error?.message && <ErrorMessage className='hookedField-error' message={error.message} />}
      </div>
    </>
  );
};

export default UploadPdf;
