import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks';
import Modal from '../../../../../components/ui/Modal';
import UserForm from 'components/UserForm';
import Heading from 'components/ui/Heading';
import { getUser, resetCurrentUser } from 'store/users/usersSlice';
import { Spin } from 'antd';

interface IEditUserModalProps {
  onCancel: () => void;
  open: boolean;
  id: string;
  resetId: () => void;
}

const EditUserModal: FC<IEditUserModalProps> = ({ open, onCancel, id, resetId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { user, getUserLoading } = useAppSelector((state) => state.users);

  useEffect(() => {
    if (!id) {
      return;
    }
    dispatch(getUser({ id }));
    return () => {
      dispatch(resetCurrentUser());
    };
  }, [id]);

  return (
    <Modal
      width='max-content'
      className='userPage-modal--edit'
      open={open}
      onCancel={onCancel}
      footer={null}
    >
      {
        <div className='userPage-modal-content--edit'>
          <Heading title={t('USER_EDIT_PAGE.HEADING.TITLE')} />
          {getUserLoading ? (
            <Spin spinning={getUserLoading}>
              <UserForm onCloseModal={onCancel} defaultValues={null} id={id} resetId={resetId} />
            </Spin>
          ) : (
            <UserForm onCloseModal={onCancel} defaultValues={user} id={id} resetId={resetId} />
          )}
        </div>
      }
    </Modal>
  );
};

export default EditUserModal;
