import React, { Dispatch, FC, SetStateAction } from 'react';
import Iframe from 'react-iframe';
import Modal from '../../../../../components/ui/Modal';

interface ISignDocumentModal {
  iFrameSrc: string;
  openSignDocument: boolean;
  onOpenSignDocument: Dispatch<SetStateAction<boolean>>;
  file: File | null;
}

const SignDocumentModal: FC<ISignDocumentModal> = ({
  iFrameSrc,
  openSignDocument,
  onOpenSignDocument,
  file,
}) => {
  return (
    <Modal width='max-content' open={openSignDocument} onCancel={() => onOpenSignDocument(false)}>
      <Iframe
        url={iFrameSrc}
        // @ts-ignore
        onLoad={(e) => {
          e.target.contentWindow?.postMessage?.({ file }, '*');
        }}
        width='750px'
        height='500px'
        name='private24'
        className=''
      />
    </Modal>
  );
};

export default SignDocumentModal;
