import React from 'react';
import classNames from 'classnames';
import { Typography } from 'antd';
import './ErrorMessage.scss';

const { Text } = Typography;

interface IErrorMessageProps {
  message: string;
  className?: string;
}

const ErrorMessage: React.FC<IErrorMessageProps> = ({ message, className }) => {
  const classes = classNames({
    errorMessage: true,
    [className as string]: !!className,
  });
  return (
    <Text ellipsis={{ tooltip: message }} className={classes}>
      {message}
    </Text>
  );
};

export default ErrorMessage;
