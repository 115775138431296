import {
  resetDocumentsFilter,
  setDocumentsFilter,
} from '../../../../store/documents/documentsSlice';
import { AppDispatch } from '../../../../store';
import { FILTERS_TO_PAGE } from '../constants/filtersToPage';

export const getDocumentsByCategory = ({
  id,
  dispatch,
}: {
  id?: string;
  dispatch: AppDispatch;
}) => {
  if (!id || !FILTERS_TO_PAGE[id]) return dispatch(resetDocumentsFilter());
  dispatch(setDocumentsFilter(FILTERS_TO_PAGE[id]));
};
