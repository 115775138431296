import { UserStatuses } from '../../utils/types';
import { TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';

export interface ICreateUserData {
  email: string;
  name: string;
  lastName: string;
  role: string;
}

export interface IGetUsersData {
  page?: number;
  take?: number;
  status?: UserStatuses;
  search?: string;
  selection?: string[];
  asc?: string[];
  desc?: string[];
}

export interface IGetUserData {
  id: string;
  selection?: string[];
}

export interface IUpdateUserData {
  id: string;
  email?: string;
  name?: string;
  lastName?: string;
  password?: string;
  role?: string;
}

export interface IDeleteUserData {
  id: string;
}

export interface IDataType {
  key?: string;
  id: string;
  name?: string;
  lastName?: string;
  email?: string;
  updatedAt?: string;
  status?: UserStatuses;
}

export interface IUsersOptions {
  pagination: TablePaginationConfig;
  filters: Record<string, FilterValue> | null;
  sorter: SorterResult<IDataType> | null;
  search?: string;
  status?: UserStatus;
}

export enum UserStatus {
  PENDING = 'PENDING',
  DEACTIVATED = 'DEACTIVATED',
  ACTIVATED = 'ACTIVATED',
}
