import axios from 'axios';
import qs from 'qs';
import {
  IArchiveDocumentData,
  ICreateDocumentData,
  IDeleteDocumentData,
  IDownloadDocumentData,
  IGetDocumentData,
  IGetDocumentsData,
  IGetDocumentVersionsData,
  IGetUserDocumentsData,
  IRejectDocumentData,
  ISignDocumentData,
  IUpdateDocumentData,
  IUpdateDocumentVerificationStatus,
  IUpdateFileData,
  IViewDocumentData,
  TDocumentVersions,
} from './types';

class DocumentsService {
  getDocuments = (params: IGetDocumentsData) =>
    axios.get('documents', {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  getDocumentsStats = () => axios.get('documents/stats');
  createDocument = (data: ICreateDocumentData) =>
    axios.post('documents', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  signDocument = ({ id, file }: ISignDocumentData) =>
    axios.post(`documents/${id}/signature`, file, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  signDocumentWithPicture = ({ id, file }: ISignDocumentData) =>
    axios.post(`documents/${id}/signature/picture`, file, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  rejectDocument = ({ id }: IRejectDocumentData) => axios.post(`documents/${id}/reject`);
  getDocument = ({ id, selection }: IGetDocumentData) =>
    axios.get(`documents/${id}`, {
      params: {
        selection,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  getUserDocuments = ({ id, ...params }: IGetUserDocumentsData) =>
    axios.get(`documents/users/${id}`, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  updateDocument = ({
    id,
    name,
    type,
    status,
    deletedAt,
    signers,
    supervisors,
  }: IUpdateDocumentData) =>
    axios.patch(`documents/${id}`, {
      name,
      type,
      status,
      deletedAt,
      signers,
      supervisors,
    });
  deleteDocument = ({ id }: IDeleteDocumentData) => axios.delete(`documents/${id}`);
  archiveDocument = ({ id }: IArchiveDocumentData) => axios.patch(`documents/${id}/archive`);
  downloadDocument = ({ id }: IDownloadDocumentData) => axios.get(`documents/${id}/archive`);
  updateFile = ({ id, file }: IUpdateFileData) =>
    axios.patch(`documents/${id}/file`, file, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  updateDocumentVerificationsStatus = ({
    id,
    verificationStatus,
  }: IUpdateDocumentVerificationStatus) =>
    axios.patch(`documents/${id}/verification-status`, { verificationStatus });
  getDocumentVersions = ({ id, ...params }: IGetDocumentVersionsData) =>
    axios.get<{ result: TDocumentVersions }>(`documents/${id}/versions`, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  viewDocument = ({ id }: IViewDocumentData) =>
    axios.post<{ result: TDocumentVersions }>(`documents/${id}/view`);
}

export default new DocumentsService();
