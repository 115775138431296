import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IChatMessage,
  ICreateChatData,
  IDeleteChatData,
  IGetChatMessagesData,
  IGetChatsData,
} from './types';
import ChatService from './chatService';
import { toast } from 'react-toastify';
import i18n from '../../i18n';

export interface IChatState {
  chats: any;
  messages?: IChatMessage[];
  totalMessages?: number;
  getChatsLoading: boolean;
  createChatLoading: boolean;
  getMessagesLoading: boolean;
  deleteChatLoading: boolean;
}

const initialState: IChatState = {
  chats: null,
  messages: [],
  getChatsLoading: false,
  createChatLoading: false,
  getMessagesLoading: false,
  deleteChatLoading: false,
};

export const createChat = createAsyncThunk(
  'chat/createChat',
  async (data: ICreateChatData, thunkAPI) => {
    try {
      const response = await ChatService.createChat(data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const getChats = createAsyncThunk('chat/getChats', async (data: IGetChatsData, thunkAPI) => {
  try {
    const response = await ChatService.getChats(data);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const getChatMessages = createAsyncThunk(
  'chat/getChatMessages',
  async (data: IGetChatMessagesData, thunkAPI) => {
    try {
      const response = await ChatService.getChatMessages(data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const deleteChat = createAsyncThunk(
  'chat/deleteChat',
  async (data: IDeleteChatData, thunkAPI) => {
    try {
      const response = await ChatService.deleteChat(data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatMessages: (state, action: PayloadAction<IChatMessage[]>) => {
      state.messages = action.payload;
    },
    pushChatMessage: (state, action: PayloadAction<IChatMessage>) => {
      if (state.messages?.find((message) => message.id === action.payload.id)) return;
      state.messages?.push(action.payload);
    },
    pushChatMessagesToStart: (state, action: PayloadAction<IChatMessage[]>) => {
      state.messages?.unshift(...action.payload);
    },
    setTotalMessages: (state, action: PayloadAction<number>) => {
      state.totalMessages = action.payload;
    },
  },
  extraReducers: (builder) => {
    // createChat
    builder
      .addCase(createChat.pending, (state: IChatState) => {
        state.createChatLoading = true;
      })
      .addCase(createChat.fulfilled, (state: IChatState, action) => {
        console.log('createChat.fulfilled', action.payload);
        state.createChatLoading = false;
      })
      .addCase(createChat.rejected, (state: IChatState, action) => {
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.createChatLoading = false;
      });
    // getChats
    builder
      .addCase(getChats.pending, (state: IChatState) => {
        state.getChatsLoading = true;
      })
      .addCase(getChats.fulfilled, (state: IChatState, action) => {
        console.log('getChats.fulfilled', action.payload);
        state.getChatsLoading = false;
      })
      .addCase(getChats.rejected, (state: IChatState, action) => {
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.getChatsLoading = false;
      });
    // getChatMessages
    builder
      .addCase(getChatMessages.pending, (state: IChatState) => {
        state.getMessagesLoading = true;
      })
      .addCase(getChatMessages.fulfilled, (state: IChatState, action) => {
        console.log('getChatMessages.fulfilled', action.payload);
        state.getMessagesLoading = false;
      })
      .addCase(getChatMessages.rejected, (state: IChatState, action) => {
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.getMessagesLoading = false;
      });
    // deleteChat
    builder
      .addCase(deleteChat.pending, (state: IChatState) => {
        state.deleteChatLoading = true;
      })
      .addCase(deleteChat.fulfilled, (state: IChatState, action) => {
        console.log('deleteChat.fulfilled', action.payload);
        state.deleteChatLoading = false;
      })
      .addCase(deleteChat.rejected, (state: IChatState, action) => {
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.deleteChatLoading = false;
      });
  },
});

export const { setChatMessages, pushChatMessage, pushChatMessagesToStart, setTotalMessages } =
  chatSlice.actions;

export default chatSlice.reducer;
