import React, { FC, RefObject } from 'react';
import { DocumentSignedStatuses, DocumentStatuses } from '../../../../../utils/types';
import { Divider, Dropdown, Space } from 'antd';
import Button from '../../../../../components/ui/Button';
import Icon from '../../../../../components/ui/Icon';
import { IconsTypes } from '../../../../../components/ui/Icon/types';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import {
  archiveDocumentConfirm,
  deleteDocumentConfirm,
  showVersionHistory,
  uploadDocumentFileConfirm,
} from '../modals/modals';
import { useNavigate, useParams } from 'react-router-dom';
import './TitleButtons.scss';
interface ITitleButtons {
  uploaderRef: RefObject<HTMLInputElement>;
}

const TitleButtons: FC<ITitleButtons> = ({ uploaderRef }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { user } = useAppSelector((state) => state.profile);
  const {
    document,
    deleteDocumentLoading,
    rejectDocumentLoading,
    signDocumentLoading,
    uploadFileLoading,
    getDocumentVersionsLoading,
    versions,
  } = useAppSelector((state) => state.documents);

  if (!user || !document) {
    return null;
  }

  const isOwner = user.id === document.owner.id;
  const isDocumentSigned = document.signStatus === DocumentSignedStatuses.SIGNED;
  const isArchived =
    document?.status === DocumentStatuses.DELETED || document?.status === DocumentStatuses.ARCHIVED;

  const versionOptionsItems = {
    items: [
      {
        label: isOwner ? (
          <Button
            type='link'
            spinning={uploadFileLoading}
            onClick={() => uploadDocumentFileConfirm(t, dispatch, navigate, uploaderRef, id)}
            disabled={rejectDocumentLoading || signDocumentLoading || deleteDocumentLoading}
          >
            <Icon icon={IconsTypes.UPLOAD} />
            <span>{t('BUTTONS.UPLOAD_NEW_VERSION')}</span>
          </Button>
        ) : null,
        key: '1',
      },
      {
        label: (
          <Button
            type='link'
            spinning={getDocumentVersionsLoading}
            onClick={() => showVersionHistory(t, versions, document)}
            disabled={getDocumentVersionsLoading}
          >
            <Icon icon={IconsTypes.HISTORY} />
            <span>{t('BUTTONS.SHOW_VERSION_HISTORY')}</span>
          </Button>
        ),
        key: '2',
      },
    ],
  };

  const documentOptionsItems = {
    items: [
      {
        label: isDocumentSigned ? (
          <Button
            type='link-secondary'
            onClick={() => archiveDocumentConfirm(t, dispatch, navigate, id)}
          >
            {t('BUTTONS.ARCHIVE')}
          </Button>
        ) : null,
        key: '1',
      },
      {
        label: (
          <Button
            type='link-secondary'
            spinning={deleteDocumentLoading}
            disabled={rejectDocumentLoading || signDocumentLoading || uploadFileLoading}
            onClick={() => deleteDocumentConfirm(t, dispatch, navigate, id)}
          >
            <Icon icon={IconsTypes.BUCKET} />
            <span>{t('BUTTONS.DELETE')}</span>
          </Button>
        ),
        key: '2',
      },
    ],
  };

  return (
    <Space className='container'>
      <Dropdown menu={versionOptionsItems}>
        <div className='dropdown dropdown-version'>
          <Icon icon={IconsTypes.ARROW_DOWN} className='dropdown-icon' />
          <Divider type='vertical' orientationMargin={0} />
          <span className='dropdown-title'>{t('DOCUMENT_VIEW_PAGE.DROPDOWN.VERSION_TITLE')}</span>
        </div>
      </Dropdown>
      {isOwner && !isArchived && (
        <Dropdown menu={documentOptionsItems}>
          <div className='dropdown dropdown-document'>
            <Icon icon={IconsTypes.ARROW_DOWN} className='dropdown-icon dropdown-icon--inverse' />
            <Divider type='vertical' orientationMargin={0} className='divider-inverse' />
            <span className='dropdown-title dropdown-title--inverse'>
              {t('DOCUMENT_VIEW_PAGE.DROPDOWN.DOCUMENT_TITLE')}
            </span>
          </div>
        </Dropdown>
      )}
    </Space>
  );
};

export default TitleButtons;
