import React from 'react';
import { Spin, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import Heading from 'components/ui/Heading';
import Loader from 'components/Loader/Loader';
import { useAppSelector } from '../../../hooks';
import BasicInfo from './components/tabs/BasicInformation';
import AdditionalInfo from './components/tabs/AdditionalInformation';
import AccountSettings from './components/tabs/AccountSettings';

import './ProfilePage.scss';

const ProfilePage = () => {
  const { t } = useTranslation();

  const { updateProfileLoading, getProfileLoading } = useAppSelector((state) => state.profile);

  if (getProfileLoading) return <Loader />;

  return (
    <div className='profile-container'>
      <Spin spinning={updateProfileLoading}>
        <Heading title={t('PROFILE.HEADING.TITLE')} withBackBtn />
        <Tabs
          defaultActiveKey={t('PROFILE.TABS.BASIC_INFO')}
          items={[
            {
              label: t('PROFILE.TABS.BASIC_INFO'),
              key: t('PROFILE.TABS.BASIC_INFO'),
              children: <BasicInfo />,
            },
            {
              label: t('PROFILE.TABS.ADDITIONAL_INFO'),
              key: t('PROFILE.TABS.ADDITIONAL_INFO'),
              children: <AdditionalInfo />,
            },
            {
              label: t('PROFILE.TABS.ACCOUNT_SETTINGS'),
              key: t('PROFILE.TABS.ACCOUNT_SETTINGS'),
              children: <AccountSettings />,
            },
          ]}
        />
      </Spin>
    </div>
  );
};

export default ProfilePage;
