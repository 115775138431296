import axios from 'axios';
import qs from 'qs';
import { IGetDocumentTypesData } from './types';

class DocumentTypesService {
  getDocumentTypes = (params: IGetDocumentTypesData) =>
    axios.get('documents/types', {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
}
export default new DocumentTypesService();
