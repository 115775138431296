import { getArrayBufferFromFile, getSignatureOptions } from '../../../../utils/functions';
import { PDFDocument } from 'pdf-lib';
import { signDocumentWithPicture } from '../../../../store/documents/documentsSlice';
import { IDocument } from '../../../../utils/types';
import { TElementsByPages } from './types';
import { AppDispatch } from '../../../../store';
import { Dispatch, SetStateAction } from 'react';

export const signWithPictureHandler = async (
  dispatch: AppDispatch,
  document: IDocument,
  signatureSrc: string,
  elementsByPages: TElementsByPages,
  onSetSignature: Dispatch<SetStateAction<string>>,
) => {
  if (!document) {
    return;
  }
  const fileBytes = await getArrayBufferFromFile(document.file.src);
  const signatureBytes = await getArrayBufferFromFile(signatureSrc);
  const pdfDoc = await PDFDocument.load(fileBytes);
  const pages = pdfDoc.getPages();

  for (const pageNum of Object.keys(elementsByPages)) {
    const page = pages[Number(pageNum) - 1];
    const parentSize = {
      width: page.getWidth(),
      height: page.getHeight(),
    };

    await Promise.all(
      elementsByPages[pageNum].map(async (element) => {
        const { x, y, height, width } = getSignatureOptions({
          parentSize,
          relativeSignatureOptions: {
            heightPercent: element.height,
            widthPercent: element.width,
            xPercent: element.x,
            yPercent: element.y,
          },
        });

        const pngImage = await pdfDoc.embedPng(signatureBytes);

        const ratio = Math.min(width / pngImage.width, height / pngImage.height);

        const ratioHeight = pngImage.height * ratio;
        const ratioWidth = pngImage.width * ratio;
        const ratioX = x + width / 2 - ratioWidth / 2;
        const ratioY = y + height / 2 - ratioHeight / 2;

        page.drawImage(pngImage, {
          x: ratioX,
          y: ratioY,
          height: ratioHeight,
          width: ratioWidth,
        });
      }),
    );
  }

  const signedDoc = await pdfDoc.save();

  const data = {
    file: new File([signedDoc], 'name.pdf', {
      type: 'application/pdf',
    }),
  };

  dispatch(signDocumentWithPicture({ id: document.id, file: data }));
  onSetSignature('');
};
