export interface ISignInData {
  email: string;
  password: string;
}

export interface ISignUpData {
  email: string;
  password: string;
}

export interface ILogOutData {
  refreshToken: string;
}

export interface IRefreshTokensData {
  refreshToken: string;
}

export interface IResetPasswordData {
  email: string;
}

export interface IConfirmationResetPasswordData {
  email: string;
  code: string;
}

export interface ICreateNewPasswordData {
  email: string;
  password: string;
  code: string;
}

export interface IConfirmInvitationData {
  email: string;
  password: string;
  oldPassword: string;
}

export enum ESendEmailSubjects {
  'REQUEST_ACCESS' = 'REQUEST_ACCESS',
  'CONTACT_US' = 'CONTACT_US',
}
export interface ISendEmailData {
  email: string;
  subject: ESendEmailSubjects;
  message?: string;
}
