import { useState, useEffect } from 'react';
import history from 'router/history';
import { showConfirmModal } from '../utils/functions';
import i18n from '../i18n';
import { Trans } from 'react-i18next';

interface IUseUnsavePrompt {
  message?: string;
}

export const useUnsavePrompt = ({
  message = i18n.t('MODALS.FORM_GO_OUT.TITLE'),
}: IUseUnsavePrompt) => {
  const [block, setBlock] = useState(false);

  useEffect(() => {
    let unblock: () => void;
    if (block) {
      unblock = history.block((tx) => {
        showConfirmModal({
          title: message,
          onOk: () => {
            unblock();
            tx.retry();
          },
          content: (
            <Trans
              i18nKey='MODALS.FORM_GO_OUT.DESCRIPTION'
              components={{
                br: <br />,
                p: <p></p>,
              }}
            />
          ),
        });
      });
    }

    return () => {
      if (typeof unblock === 'function') {
        unblock();
      }
    };
  }, [block]);

  return [setBlock];
};
