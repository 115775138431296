import { Modal as AntdModal, ModalProps } from 'antd';

interface IModalProps extends ModalProps {
  className?: string;
}

const Modal = ({
  children,
  open = false,
  onOk,
  onCancel,
  title,
  className,
  footer,
  ...props
}: IModalProps) => {
  return (
    <AntdModal
      {...props}
      open={open}
      title={title}
      onOk={onOk}
      onCancel={onCancel}
      className={className}
      footer={footer}
      centered
    >
      {children}
    </AntdModal>
  );
};

export default Modal;
