export enum IconsTypes {
  SHOW = 'SHOW',
  ARROW_LEFT = 'ARROW_LEFT',
  ARROW_RIGHT = 'ARROW_RIGHT',
  ARROW_DOWN = 'ARROW_DOWN',
  ARROW_UP = 'ARROW_UP',
  UPLOAD = 'UPLOAD',
  DOWNLOAD = 'DOWNLOAD',
  HOME = 'HOME',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  MAIL = 'MAIL',
  MAP_PICKER = 'MAP_PICKER',
  PERSON = 'PERSON',
  LOGOUT = 'LOGOUT',
  CALENDAR = 'CALENDAR',
  MORE_BUTTON = 'MORE_BUTTON',
  BUCKET = 'BUCKET',
  EDIT = 'EDIT',
  USERS = 'USERS',
  CHART = 'CHART',
  QUESTION = 'QUESTION',
  DOCUMENTS = 'DOCUMENTS',
  SELECT = 'SELECT',
  PLUS = 'PLUS',
  EXTRA_INFO = 'EXTRA_INFO',
  TIMER = 'TIMER',
  PLANE = 'PLANE',
  REMINDER = 'REMINDER',
  HISTORY = 'HISTORY',
  CLOSE = 'CLOSE',
  COPY = 'COPY',
  SEARCH = 'SEARCH',
}
