import React, { Dispatch, FC, SetStateAction } from 'react';
import { DocumentVerificationStatuses, SignatureStatuses } from '../../../../../utils/types';
import { Space } from 'antd';
import Button from '../../../../../components/ui/Button';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import { signWithPictureHandler } from '../../utils/functions';
import { TElementsByPages } from '../../utils/types';
import { rejectDocumentConfirm } from '../modals/modals';
import { useNavigate, useParams } from 'react-router-dom';

interface ISignerButtons {
  openSignDocument: boolean;
  onOpenSignDocument: Dispatch<SetStateAction<boolean>>;
  signatureSrc: string;
  onDrawerOpen: Dispatch<SetStateAction<boolean>>;
  elementsByPages: TElementsByPages;
  onSetSignatureSrc: Dispatch<SetStateAction<string>>;
  onAddSignatureOnPage: () => void;
  onSetElementsByPages: Dispatch<SetStateAction<TElementsByPages>>;
  arePagesEmpty: boolean;
}

const SignerButtons: FC<ISignerButtons> = ({
  openSignDocument,
  onOpenSignDocument,
  signatureSrc,
  onDrawerOpen,
  elementsByPages,
  onSetSignatureSrc,
  onAddSignatureOnPage,
  onSetElementsByPages,
  arePagesEmpty,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { user } = useAppSelector((state) => state.profile);
  const {
    document,
    deleteDocumentLoading,
    rejectDocumentLoading,
    signDocumentLoading,
    uploadFileLoading,
  } = useAppSelector((state) => state.documents);

  if (!user || !document) {
    return null;
  }

  const isDocumentApproved =
    document.verificationStatus === DocumentVerificationStatuses.APPROVED ||
    document.verificationStatus === DocumentVerificationStatuses.NONE;
  const signer = document.signers.find((signer) => signer.user.id === user.id);
  const isSigner = !!signer;
  const isReadyForActionByMe =
    isSigner &&
    signer?.signature?.status !== SignatureStatuses.SIGNED &&
    signer?.signature?.status !== SignatureStatuses.REJECTED;

  const onCancelSignaturePicture = () => {
    onSetSignatureSrc('');
    onSetElementsByPages({});
  };
  return (
    <>
      {isSigner && isDocumentApproved && isReadyForActionByMe ? (
        <>
          <p className='documentViewPage-roleText'>{t('DOCUMENT_VIEW_PAGE.SIGNERS.TEXT')}</p>
          <Space>
            {document.signWithSignature ? (
              <Space size={12}>
                <Button
                  type='primary'
                  onClick={() => onOpenSignDocument(!openSignDocument)}
                  disabled={deleteDocumentLoading || rejectDocumentLoading || uploadFileLoading}
                  spinning={signDocumentLoading}
                >
                  {t('BUTTONS.SIGN_DOCUMENT')}
                </Button>
                <Button
                  type='error'
                  onClick={() => rejectDocumentConfirm(t, dispatch, navigate, id)}
                  disabled={deleteDocumentLoading || signDocumentLoading || uploadFileLoading}
                  spinning={rejectDocumentLoading}
                >
                  {t('BUTTONS.REJECT')}
                </Button>
              </Space>
            ) : null}

            {document.signWithPicture &&
              (!signatureSrc ? (
                <Space size={12}>
                  <Button
                    type='primary'
                    onClick={() => onDrawerOpen(true)}
                    disabled={
                      deleteDocumentLoading ||
                      rejectDocumentLoading ||
                      uploadFileLoading ||
                      signDocumentLoading
                    }
                    spinning={signDocumentLoading}
                  >
                    {t('BUTTONS.ADD_SIGNATURE')}
                  </Button>
                  <Button
                    type='error'
                    onClick={() => rejectDocumentConfirm(t, dispatch, navigate, id)}
                    disabled={deleteDocumentLoading || signDocumentLoading || uploadFileLoading}
                    spinning={rejectDocumentLoading}
                  >
                    {t('BUTTONS.REJECT')}
                  </Button>
                </Space>
              ) : (
                <Space wrap>
                  <Space size={12}>
                    <Button
                      type='secondary'
                      onClick={onCancelSignaturePicture}
                      disabled={
                        deleteDocumentLoading ||
                        rejectDocumentLoading ||
                        uploadFileLoading ||
                        signDocumentLoading
                      }
                      spinning={signDocumentLoading}
                    >
                      {t('BUTTONS.RESET_SIGNATURE')}
                    </Button>
                    <Button
                      type='primary'
                      onClick={onAddSignatureOnPage}
                      disabled={
                        deleteDocumentLoading ||
                        rejectDocumentLoading ||
                        uploadFileLoading ||
                        signDocumentLoading
                      }
                      spinning={signDocumentLoading}
                    >
                      {t('BUTTONS.ADD_SIGNATURE')}
                    </Button>
                  </Space>
                  <Space size={12}>
                    <Button
                      type='primary'
                      onClick={() =>
                        signWithPictureHandler(
                          dispatch,
                          document,
                          signatureSrc,
                          elementsByPages,
                          onSetSignatureSrc,
                        )
                      }
                      disabled={
                        deleteDocumentLoading ||
                        rejectDocumentLoading ||
                        uploadFileLoading ||
                        signDocumentLoading ||
                        arePagesEmpty
                      }
                      spinning={signDocumentLoading}
                    >
                      {t('BUTTONS.SIGN_DOCUMENT')}
                    </Button>

                    <Button
                      type='error'
                      onClick={() => rejectDocumentConfirm(t, dispatch, navigate, id)}
                      disabled={deleteDocumentLoading || signDocumentLoading || uploadFileLoading}
                      spinning={rejectDocumentLoading}
                    >
                      {t('BUTTONS.REJECT')}
                    </Button>
                  </Space>
                </Space>
              ))}
          </Space>
        </>
      ) : null}
      {/* {isOwner || isSigner || isSupervisor ? (
          <Button type='primary'>{t('BUTTONS.CHAT')}</Button>
        ) : null} */}
    </>
  );
};

export default SignerButtons;
