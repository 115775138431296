import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PageTable from 'components/PageTable';
import { IUsersOptions } from '../../../store/users/types';
import Icon from '../../../components/ui/Icon';
import { IconsTypes } from '../../../components/ui/Icon/types';
import Heading from '../../../components/ui/Heading';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  getUsers,
  resetUsersOptions,
  setUsersOptions,
  deleteUser,
  initialOptions,
} from '../../../store/users/usersSlice';
import Button from '../../../components/ui/Button';

import './UsersPage.scss';
import { UserRoles } from '../../../utils/types';
import { showConfirmModal } from '../../../utils/functions';

import InviteUserModal from './components/InviteUserModal';
import EditUserModal from './components/EditUserModal';
import { TUserTableChangeHandler } from './types';
import { debounce } from 'lodash';
import { getUsersColumns } from './utils/getUsersColumns';

const UsersPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { user } = useAppSelector((state) => state.profile);
  const { users, getUsersLoading, options } = useAppSelector((state) => state.users);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [userIdForEditModal, setUserIdForEditModal] = useState('');

  const isUserChanges = useRef(false);

  const tableChangeHandler: TUserTableChangeHandler = ({ sorter, ...newOptions }) => {
    isUserChanges.current = true;

    dispatch(
      setUsersOptions({
        ...((sorter?.order !== options.sorter?.order ||
          sorter?.field !== options.sorter?.field) && {
          sorter: sorter?.order ? { field: sorter.field, order: sorter.order } : null,
        }),
        ...newOptions,
      }),
    );
  };

  const getUsersHandler = (options: IUsersOptions) => dispatch(getUsers(options));

  const debouncedGetUsers = useMemo(() => debounce(getUsersHandler, 400), []);
  useEffect(() => {
    if (!isUserChanges.current) return;

    debouncedGetUsers(options);
  }, [options.sorter, options.search]);

  useEffect(() => {
    if (!isUserChanges.current) return;

    getUsersHandler(options);
  }, [options.pagination.current]);

  useEffect(() => {
    getUsersHandler(initialOptions);
    return () => {
      debouncedGetUsers.cancel();
      dispatch(resetUsersOptions());
    };
  }, []);

  const deleteUserHandler = (userId: string) => {
    if (!userId) {
      return;
    }
    dispatch(deleteUser({ id: userId }));
  };

  const deleteUserConfirm = (userId: string) => {
    showConfirmModal({
      title: t('MODALS.USER_DELETE.TITLE'),
      icon: <Icon icon={IconsTypes.EXTRA_INFO} className='anticon' />,
      content: (
        <Trans
          i18nKey='MODALS.USER_DELETE.CONTENT'
          components={{
            br: <br />,
          }}
        />
      ),
      maskClosable: true,
      centered: true,
      onOk: () => deleteUserHandler(userId),
    });
  };

  const editUserModalHandler = (id: string) => {
    setUserIdForEditModal(id);
    setOpenEditModal(true);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.length <= 3) {
      dispatch(resetUsersOptions());
      return;
    }
    tableChangeHandler({
      search: e.currentTarget.value,
    });
  };

  return (
    <div className='usersPage'>
      <Heading
        title={t('USERS.HEADING.TITLE')}
        description={t('USERS.HEADING.DESCRIPTION')}
        withBackBtn
      />
      <PageTable
        header={{
          title: t('USERS.TITLE'),
          meta: t('USERS.TOTAL', { total: options.pagination.total }),
          button:
            user?.role !== UserRoles.USER ? (
              <Button onClick={() => setOpenInviteModal(true)}>
                <Icon icon={IconsTypes.PLUS} size={16} />
                <span>{t('USERS.ADD_USER')}</span>
              </Button>
            ) : null,
        }}
        columns={getUsersColumns(t, options, user, editUserModalHandler, deleteUserConfirm)}
        data={users}
        pagination={{ ...options.pagination }}
        loading={getUsersLoading}
        onChange={(pagination, filters, sorter) =>
          tableChangeHandler({ pagination, filters, sorter })
        }
        onSearch={onSearch}
      />
      <InviteUserModal onCancel={() => setOpenInviteModal(false)} open={openInviteModal} />
      <EditUserModal
        onCancel={() => setOpenEditModal(false)}
        open={openEditModal}
        id={userIdForEditModal}
        resetId={() => setUserIdForEditModal('')}
      />
    </div>
  );
};

export default UsersPage;
