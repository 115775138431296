import * as React from 'react';
import { useEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import MainLayout from '../../components/layout/MainLayout';
import withAuth from '../../hocs/withAuth';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getProfile } from '../../store/profile/profileSlice';
import NotFoundPage from '../NotFoundPage';
import { authProtectedRoutes } from '../../router';
import Loader from '../../components/Loader';

const Main = () => {
  const dispatch = useAppDispatch();

  const { user, getProfileLoading } = useAppSelector((state) => state.profile);

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  return getProfileLoading ? (
    <Loader />
  ) : user ? (
    <Routes>
      <Route path='*' element={<NotFoundPage />} />
      <Route
        path='/'
        element={
          <MainLayout>
            <Outlet />
          </MainLayout>
        }
      >
        {authProtectedRoutes.map(({ path, component, roles, rerender }) => {
          const Component = component;

          return (
            user?.role &&
            roles.includes(user?.role) && (
              <Route
                key={path}
                path={path}
                element={<Component key={rerender ? window.location.pathname : undefined} />}
              />
            )
          );
        })}
        <Route index element={<Navigate to='documents/all' />} />
      </Route>
    </Routes>
  ) : null;
};

export default withAuth(Main);
