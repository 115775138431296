import React from 'react';
import classNames from 'classnames';
import ErrorMessage from '../ErrorMessage';

import './Field.scss';

interface IFieldProps {
  children: React.ReactNode;
  className?: string;
  label?: string;
  caption?: string;
  id?: string;
  error?: string;
}

const Field: React.FC<IFieldProps> = ({ className, label, caption, id, children, error }) => {
  const classes = classNames({
    field: true,
    [className as string]: !!className,
  });

  return (
    <div className={classes}>
      <div className='field-content'>
        <div className='field-header'>
          {label && (
            <label className='field-label' htmlFor={id}>
              {label}
            </label>
          )}
        </div>
        <div className='field-wrapper'>{children}</div>
      </div>
      {caption ? <div className='field-caption'>{caption}</div> : null}
      {error ? <ErrorMessage className='field-error' message={error} /> : null}
    </div>
  );
};

export default Field;
