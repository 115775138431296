import { Card, Input, Radio, Space, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {
  DocumentSignedStatuses,
  DocumentStatuses,
  DocumentVerificationStatuses,
  ISigner,
  IUser,
  SignatureStatuses,
  UserRoles,
} from '../../../../utils/types';
import Badge from '../../../../components/ui/Badge';
import { Link, NavigateFunction } from 'react-router-dom';
import {
  getDocumentSignBadge,
  getDocumentStatusBadge,
  getDocumentVerificationBadge,
  getFormattedDate,
  getUserFullName,
} from '../../../../utils/functions';
import CheckedList from '../../../../components/ui/CheckedList';
import { MONTH_FORMAT } from '../../../../utils/constants';
import Button from '../../../../components/ui/Button';
import Icon from '../../../../components/ui/Icon';
import { IconsTypes } from '../../../../components/ui/Icon/types';
import { archiveDocumentConfirmModal, deleteDocumentConfirmModal } from './modals';
import React from 'react';
import { TFunction } from 'react-i18next';
import { TFiltersChangeHandler, TTableChangeHandler } from '../../UserDocumentsPage/types';
import { IDataType, IDocumentsFilter, IDocumentsOptions } from '../../../../store/documents/types';
import { ColumnsType } from 'antd/es/table';
import { AppDispatch } from '../../../../store';
import en from '../../../../global/translations/en.json';
const { Text } = Typography;

export const getDocsColumns = (
  t: TFunction,
  navigate: NavigateFunction,
  tableChangeHandler: TTableChangeHandler,
  filtersChangeHandler: TFiltersChangeHandler,
  filters: IDocumentsFilter | null,
  { sorter, search }: IDocumentsOptions,
  dispatch: AppDispatch,
  user: IUser | null,
  filterPage: string | undefined,
): ColumnsType<IDataType> => [
  {
    title: t('DOCUMENTS.LABELS.FILE_NAME'),
    key: 'name',
    dataIndex: 'name',
    sorter: true,
    sortOrder: sorter?.field === 'name' ? sorter.order : null,
    filterDropdown: () => {
      return (
        <div className='documentsPage-dropdown'>
          <Input
            autoFocus
            placeholder='Type name here'
            value={search}
            onChange={(e) => {
              tableChangeHandler({
                search: e.currentTarget.value,
              });
            }}
          />
        </div>
      );
    },
    filterIcon: () => <SearchOutlined />,
    shouldCellUpdate: () => false,
    render: (_, document) => (
      <div className='pageTable-item pageTable-item--filename'>
        {document?.name && (
          <div className='pageTable-item-title'>
            <Text ellipsis={{ tooltip: document.name }}>{document.name}</Text>
          </div>
        )}
        {document?.type?.name && (
          <div className='pageTable-item-caption'>
            <Text ellipsis={{ tooltip: document.type.name }}>{document.type.name}</Text>
          </div>
        )}
      </div>
    ),
  },
  {
    title: t('DOCUMENTS.LABELS.STATUS'),
    dataIndex: 'status',
    key: 'status',
    filterDropdown:
      filterPage !== 'archived'
        ? ({ confirm }) => {
            return (
              <Card size='small'>
                <Radio.Group
                  value={filters?.status}
                  onChange={(e) => {
                    filtersChangeHandler({ status: e.target.value });
                    confirm();
                  }}
                >
                  <Space direction='vertical'>
                    {Object.keys(DocumentStatuses).map((status) => (
                      <Radio key={status} value={status}>
                        {`${t(`DOCUMENTS.FILTERS.${status}` as keyof typeof en)}`}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Card>
            );
          }
        : undefined,
    shouldCellUpdate: () => false,
    render: (status) => {
      return <Badge {...getDocumentStatusBadge({ status, t })} />;
    },
  },
  {
    title: t('DOCUMENTS.LABELS.VERIFICATION_STATUS'),
    dataIndex: 'verificationStatus',
    key: 'verificationStatus',
    filterDropdown:
      filterPage !== 'unverified' && filterPage !== 'income'
        ? ({ confirm }) => {
            return (
              <Card size='small'>
                <Radio.Group
                  value={filters?.verificationStatus}
                  onChange={(e) => {
                    filtersChangeHandler({ verificationStatus: e.target.value });
                    confirm();
                  }}
                >
                  <Space direction='vertical'>
                    {Object.keys(DocumentVerificationStatuses).map((status) => (
                      <Radio key={status} value={status}>
                        {`${t(`DOCUMENTS.FILTERS.${status}` as keyof typeof en)}`}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Card>
            );
          }
        : undefined,
    shouldCellUpdate: () => false,
    render: (verificationStatus) => {
      return <Badge {...getDocumentVerificationBadge({ status: verificationStatus, t })} />;
    },
  },
  {
    title: t('DOCUMENTS.LABELS.SIGNATURE_STATUS'),
    dataIndex: 'signStatus',
    key: 'signStatus',
    shouldCellUpdate: () => false,
    filterDropdown:
      filterPage !== 'rejected' && filterPage !== 'income'
        ? ({ confirm }) => {
            return (
              <Card size='small'>
                <Radio.Group
                  value={filters?.signedStatus}
                  onChange={(e) => {
                    filtersChangeHandler({ signedStatus: e.target.value });
                    confirm();
                  }}
                >
                  <Space direction='vertical'>
                    {Object.keys(DocumentSignedStatuses).map((status) => (
                      <Radio key={status} value={status}>
                        {`${t(`DOCUMENTS.FILTERS.${status}` as keyof typeof en)}`}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Card>
            );
          }
        : undefined,
    render: (signStatus) => {
      return <Badge {...getDocumentSignBadge({ status: signStatus, t })} />;
    },
  },
  {
    title: t('DOCUMENTS.LABELS.OWNER'),
    dataIndex: 'owner',
    key: 'owner',
    shouldCellUpdate: () => false,
    render: (owner) => {
      if (!owner) {
        return null;
      }
      return user && [UserRoles.SUPER_ADMIN, UserRoles.ADMIN].includes(user.role) ? (
        <Link to={`/main/documents/user/${owner.id}`}>
          <Text ellipsis={{ tooltip: owner.email }}>{owner.email}</Text>
        </Link>
      ) : (
        <Text ellipsis={{ tooltip: owner.email }}>{owner.email}</Text>
      );
    },
  },
  {
    title: t('DOCUMENTS.LABELS.SIGNERS'),
    dataIndex: 'signers',
    key: 'signers',
    shouldCellUpdate: () => false,
    render: (signers) => {
      if (!signers.length) {
        return null;
      }
      const items = signers.map((signer: ISigner) => ({
        text: getUserFullName(signer.user),
        checked: signer.signature?.status === SignatureStatuses.SIGNED,
        link:
          user && [UserRoles.SUPER_ADMIN, UserRoles.ADMIN].includes(user.role)
            ? `/main/documents/user/${signer.user.id}`
            : undefined,
      }));
      return <CheckedList items={items} count={2} />;
    },
  },
  {
    title: t('DOCUMENTS.LABELS.UPDATED'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    shouldCellUpdate: () => false,
    render: (date) => getFormattedDate(date, MONTH_FORMAT),
    sorter: true,
    sortOrder: sorter?.field === 'updatedAt' ? sorter.order : null,
  },
  {
    title: t('DOCUMENTS.LABELS.ACTIONS'),
    key: 'actions',
    shouldCellUpdate: () => false,
    render: (_: any, record) => {
      const isSigned = record.signStatus === DocumentSignedStatuses.SIGNED;
      const isOwner = record.owner.id === user?.id;
      const isArchived =
        record.status === DocumentStatuses.DELETED || record.status === DocumentStatuses.ARCHIVED;
      return (
        <Space>
          <Button justIcon={true} onClick={() => navigate(`/main/documents/view/${record.id}`)}>
            <Icon icon={IconsTypes.SHOW} size={20} />
          </Button>
          {isOwner && !isArchived ? (
            <Button
              justIcon={true}
              onClick={() =>
                isSigned
                  ? archiveDocumentConfirmModal(record.id, t, dispatch, navigate)
                  : deleteDocumentConfirmModal(record.id, t, dispatch)
              }
            >
              <Icon icon={IconsTypes.BUCKET} size={20} />
            </Button>
          ) : null}
        </Space>
      );
    },
  },
];
