import React, { Component } from 'react';
import axios from 'axios';
import storage from '../utils/storage';

const withAuth = (WrappedComponent) => {
  class ComposedComponent extends Component {
    constructor() {
      super();
      this.isRefreshing = false;
      this.refreshSubscribers = [];
    }

    requestInterceptor = axios.interceptors.request.use((req) => {
      req.headers.Authorization = `Bearer ${storage.getFromStorage('token')}`;
      return req;
    });

    responseInterceptor = axios.interceptors.response.use(
      (res) => res,
      async (err) => {
        const { logoutRequest, isLoggedIn, postRefreshTokenRequest } = this.props;
        const {
          config: originalRequest,
          response: { status, data },
        } = err;
        console.log('status', status);
        console.log('err', err);
        if ((status === 401 || status === 403) && originalRequest.url !== 'auth/refresh-tokens') {
          if (isLoggedIn && postRefreshTokenRequest) {
            console.log('start refreshing');
            if (!this.isRefreshing) {
              this.isRefreshing = true;
              postRefreshTokenRequest().then(({ payload }) => {
                this.isRefreshing = false;
                if (!payload.token) {
                  return;
                }
                this.onRefreshed(payload.token);
              });
            }
            const retryOrigReq = new Promise((resolve, reject) => {
              this.subscribeTokenRefresh((token) => {
                originalRequest.headers.token = token;
                resolve(axios(originalRequest));
              });
            });
            return retryOrigReq;
          }
          console.log('logout');
          await logoutRequest();
          return Promise.reject(err);
        }
        if (status >= 500) {
          return Promise.reject(err);
        }
        return Promise.reject(err);
      },
    );

    subscribeTokenRefresh(cb) {
      this.refreshSubscribers.push(cb);
    }

    onRefreshed(token) {
      this.refreshSubscribers.map((cb) => cb(token));
    }

    componentWillUnmount() {
      axios.interceptors.request.eject(this.requestInterceptor);
      axios.interceptors.response.eject(this.responseInterceptor);
    }

    render() {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WrappedComponent {...this.props} />;
    }
  }

  return ComposedComponent;
};

export default withAuth;
