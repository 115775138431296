import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../components/ui/Modal';
import UserForm from 'components/UserForm';
import Heading from 'components/ui/Heading';

interface IInviteUserModalProps {
  onCancel: () => void;
  open: boolean;
}

const InviteUserModal: FC<IInviteUserModalProps> = ({ open, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Modal
      width='max-content'
      className='userPage-modal--invite'
      open={open}
      onCancel={onCancel}
      footer={null}
    >
      {
        <div className='userPage-modal-content--invite'>
          <Heading
            title={t('USER_ADD_PAGE.HEADING.TITLE')}
            description={t('USER_ADD_PAGE.HEADING.DESCRIPTION')}
          />
          <UserForm onCloseModal={onCancel} />
        </div>
      }
    </Modal>
  );
};

export default InviteUserModal;
