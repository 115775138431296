import axios from 'axios';
import 'dayjs/locale/en';

const configureAxios = () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
};

export const configureApp = () => {
  configureAxios();
};
