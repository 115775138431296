import axios from 'axios';
import {
  ISignInData,
  ISignUpData,
  ILogOutData,
  IRefreshTokensData,
  IResetPasswordData,
  IConfirmationResetPasswordData,
  ICreateNewPasswordData,
  IConfirmInvitationData,
  ISendEmailData,
} from './types';

class AuthService {
  signIn = ({ email, password }: ISignInData) =>
    axios.post('auth/signin', {
      email,
      password,
    });
  signUp = ({ email, password }: ISignUpData) =>
    axios.post('auth/signup', {
      email,
      password,
    });
  logOut = ({ refreshToken }: ILogOutData) =>
    axios.post('auth/log-out', {
      refreshToken,
    });
  refreshTokens = ({ refreshToken }: IRefreshTokensData) =>
    axios.post('auth/refresh-tokens', {
      refreshToken,
    });
  resetPassword = ({ email }: IResetPasswordData) =>
    axios.post('auth/reset-password/send/email', {
      email,
    });
  confirmationResetPassword = ({ email, code }: IConfirmationResetPasswordData) =>
    axios.post('/auth/validate/email/code', {
      email,
      code,
    });
  createNewPassword = ({ email, password, code }: ICreateNewPasswordData) =>
    axios.post('/auth/reset-password', {
      email,
      password,
      code,
    });
  confirmInvitation = ({ email, password, oldPassword }: IConfirmInvitationData) =>
    axios.patch('/auth/invitation/password', {
      email,
      password,
      oldPassword,
    });
  sendEmail = (data: ISendEmailData) => axios.post('/auth/send/email', data);
}

export default new AuthService();
