import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThemeTypes } from '../../utils/types';

interface IAppState {
  isCollapsedSidebar: boolean;
  theme: ThemeTypes;
}

const initialState = {
  isCollapsedSidebar: false,
  theme: ThemeTypes.LIGHT,
} as IAppState;

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleIsCollapsedSidebar: (state: IAppState) => {
      state.isCollapsedSidebar = !state.isCollapsedSidebar;
    },
    setTheme: (state: IAppState, action: PayloadAction<ThemeTypes>) => {
      state.theme = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { toggleIsCollapsedSidebar, setTheme } = appSlice.actions;

export default appSlice.reducer;
