import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { IconsTypes } from '../Icon/types';
import Icon from '../Icon';

import './BackBtn.scss';

interface IBackBtnProps {
  to?: string | number;
  className?: string;
}

const BackBtn: React.FC<IBackBtnProps> = ({ to = -1, className }) => {
  const { t } = useTranslation();
  const classes = classNames({
    backBtn: true,
    [className as string]: !!className,
  });
  return (
    // @ts-ignore
    <Link to={to} className={classes}>
      <Icon icon={IconsTypes.ARROW_LEFT} size={24} />
    </Link>
  );
};

export default BackBtn;
