import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PageTable from 'components/PageTable';
import { useNavigate, useParams } from 'react-router-dom';
import { IDocumentsOptions } from '../../../store/documents/types';
import Heading from '../../../components/ui/Heading';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  getDocuments,
  initialOptions,
  resetDocuments,
  setDocumentsFilter,
  setDocumentsOptions,
} from '../../../store/documents/documentsSlice';
import './DocumentsPage.scss';
import { getDocumentsByCategory } from './utils/getDocumentsByCategory';
import { getTableHeading } from '../UserDocumentsPage/utils/getTableHeading';
import { getDocsColumns } from './utils/getDocsColumns';
import { TFiltersChangeHandler, TTableChangeHandler } from '../UserDocumentsPage/types';
import { debounce } from 'lodash';
import { useMemoFilters } from '../../../hooks/useMemoFilters';
import { FILTERS_TO_PAGE } from './constants/filtersToPage';

const DocumentsPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const { user } = useAppSelector((state) => state.profile);
  const { documents, getDocumentsLoading, options, filter } = useAppSelector(
    (state) => state.documents,
  );
  const isUserChanges = useRef(false);
  const isFilterChanges = useRef(false);

  const filters = useMemoFilters(
    filter,
    id && FILTERS_TO_PAGE[id] ? Object.keys(FILTERS_TO_PAGE[id]) : undefined,
  );

  const tableChangeHandler: TTableChangeHandler = ({ sorter, ...newOptions }) => {
    isUserChanges.current = true;
    isFilterChanges.current = false;

    dispatch(
      setDocumentsOptions({
        ...((sorter?.order !== options.sorter?.order ||
          sorter?.field !== options.sorter?.field) && {
          sorter: sorter?.order ? { field: sorter.field, order: sorter.order } : null,
        }),
        ...newOptions,
      }),
    );
  };

  const filtersChangeHandler: TFiltersChangeHandler = (filters) => {
    isUserChanges.current = true;
    isFilterChanges.current = true;

    dispatch(
      setDocumentsFilter(
        filters
          ? { ...filter, ...filters }
          : id && FILTERS_TO_PAGE[id]
          ? FILTERS_TO_PAGE[id]
          : null,
      ),
    );
  };

  const getDocumentsHandler = (options: IDocumentsOptions) => dispatch(getDocuments(options));

  const debouncedGetDocuments = useMemo(() => debounce(getDocumentsHandler, 400), []);
  useEffect(() => {
    if (!isUserChanges.current) return;
    if (isFilterChanges.current) {
      isFilterChanges.current = false;
      return;
    }

    debouncedGetDocuments(options);
  }, [options.sorter, options.search]);

  useEffect(() => {
    if (!isUserChanges.current) return;

    getDocumentsHandler(options);
  }, [options.pagination.current, filter]);

  useEffect(() => {
    getDocumentsByCategory({ id, dispatch });
    getDocumentsHandler(initialOptions);
    return () => {
      debouncedGetDocuments.cancel();
      dispatch(resetDocuments());
    };
  }, []);

  const tableHeading = getTableHeading({ id, t });

  return (
    <div className='documentsPage'>
      <Heading
        title={t('DOCUMENTS.HEADING.TITLE')}
        description={t('DOCUMENTS.HEADING.DESCRIPTION')}
        withBackBtn
      />
      <PageTable
        header={{
          title: tableHeading.title,
          meta: t('DOCUMENTS.TOTAL', { total: options.pagination.total }),
          // description: tableHeading.description,
        }}
        columns={getDocsColumns(
          t,
          navigate,
          tableChangeHandler,
          filtersChangeHandler,
          filter,
          options,
          dispatch,
          user,
          id,
        )}
        data={documents}
        pagination={{ ...options.pagination }}
        loading={getDocumentsLoading}
        filters={{
          translationKey: 'DOCUMENTS.FILTERS',
          filters: filters,
          onRemove: filtersChangeHandler,
        }}
        onChange={(pagination, filters, sorter) =>
          tableChangeHandler({ pagination, filters, sorter })
        }
      />
    </div>
  );
};

export default DocumentsPage;
