import { RcFile } from 'antd/es/upload/interface';
import i18n from '../../../i18n';

export const beforeAvatarUpload = (file: RcFile, setError: (message: string) => void): boolean => {
  const isImage = file.type.match(/image\/jpeg|image\/png|image\/heic/);
  const isLt10M = file.size / 1024 / 1024 < 10;

  if (!isImage) {
    setError(i18n.t('PROFILE.ERRORS.WRONG_TYPE'));
    return false;
  }

  if (!isLt10M) {
    setError(i18n.t('NOTIFICATIONS.FILE_TOO_LARGE'));
    return false;
  }

  return true;
};
