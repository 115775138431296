import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import './UserDocumentsPage.scss';
import UserInfo from './components/UserInfo';
import Heading from '../../../components/ui/Heading';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  getUserDocuments,
  initialOptions,
  resetDocuments,
  setDocumentsFilter,
  setDocumentsOptions,
} from '../../../store/documents/documentsSlice';
import PageTable from '../../../components/PageTable';
import { getDocsColumns } from './utils/getDocsColumns';
import { TFiltersChangeHandler, TTableChangeHandler } from './types';
import { debounce } from 'lodash';
import { IPageTableFilter } from '../../../components/PageTable/types';
import { getUser, resetCurrentUser } from '../../../store/users/usersSlice';
import Loader from '../../../components/Loader';
import { IUserDocumentsOptions } from '../../../store/documents/types';
import { useMemoFilters } from '../../../hooks/useMemoFilters';

const UserDocumentsPage = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { documents, getDocumentsLoading, options, filter } = useAppSelector(
    (state) => state.documents,
  );
  const { user, getUserLoading } = useAppSelector((state) => state.users);

  const isUserChanges = useRef(false);
  const isFilterChanges = useRef(false);

  const filters = useMemoFilters(filter);

  const tableChangeHandler: TTableChangeHandler = ({ sorter, ...newOptions }) => {
    isUserChanges.current = true;
    isFilterChanges.current = false;

    dispatch(
      setDocumentsOptions({
        ...((sorter?.order !== options.sorter?.order ||
          sorter?.field !== options.sorter?.field) && {
          sorter: sorter?.order ? { field: sorter.field, order: sorter.order } : null,
        }),
        ...newOptions,
      }),
    );
  };

  const filtersChangeHandler: TFiltersChangeHandler = (filters) => {
    isUserChanges.current = true;
    isFilterChanges.current = true;

    dispatch(setDocumentsFilter(filters ? { ...filter, ...filters } : null));
  };

  const getUserDocumentsHandler = (options: IUserDocumentsOptions) =>
    dispatch(getUserDocuments(options));

  const debouncedGetUserDocuments = useMemo(() => debounce(getUserDocumentsHandler, 400), []);

  useEffect(() => {
    if (!id) return;
    if (!isUserChanges.current) return;
    if (isFilterChanges.current) {
      isFilterChanges.current = false;
      return;
    }

    debouncedGetUserDocuments({ ...options, id });
  }, [options.sorter, options.search]);

  useEffect(() => {
    if (!id) return;
    if (!isUserChanges.current) return;

    getUserDocumentsHandler({ ...options, id });
  }, [options.pagination.current, filter]);

  useEffect(() => {
    if (!id) return;

    getUserDocumentsHandler({ ...initialOptions, id });
    dispatch(getUser({ id }));
    return () => {
      debouncedGetUserDocuments.cancel();
      dispatch(resetCurrentUser());
      dispatch(resetDocuments());
    };
  }, []);

  if (getUserLoading) return <Loader />;

  return (
    <div>
      {user && (
        <Heading
          className='user-documents-heading'
          title={user.name}
          withBackBtn
          description={user.role}
        />
      )}
      {user && <UserInfo user={user} />}
      <PageTable
        header={{
          title: t('USER_DOCUMENTS.DOCUMENTS.TITLE'),
          meta: t('DOCUMENTS.TOTAL', { total: options.pagination.total }),
        }}
        columns={getDocsColumns(
          t,
          navigate,
          tableChangeHandler,
          filtersChangeHandler,
          filter,
          options,
        )}
        filters={{
          translationKey: 'DOCUMENTS.FILTERS',
          filters: filters,
          onRemove: filtersChangeHandler,
        }}
        data={documents}
        pagination={{ ...options.pagination }}
        loading={getDocumentsLoading}
        onChange={(pagination, filters, sorter) =>
          tableChangeHandler({ pagination, filters, sorter })
        }
      />
    </div>
  );
};

export default UserDocumentsPage;
