import React from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { configureApp } from './config/appConfig';
import Main from './pages/Main';
import Index from './pages/Auth';
import { logOut, refreshTokens } from './store/auth/authSlice';
import { useAppDispatch, useAppSelector } from './hooks';
import { ToastContainer } from 'react-toastify';
import { ConfigProvider } from 'antd';

import './global/styles/index.scss';
import Loader from 'components/Loader';
import { antdTheme } from 'theme/antd-theme';

configureApp();

const App: React.FC = () => {
  const { isLoggedIn } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <>
      <ConfigProvider theme={antdTheme}>
        <Routes>
          {isLoggedIn && (
            <Route
              path='main/*'
              element={
                <Main
                  isLoggedIn={isLoggedIn}
                  logoutRequest={() => dispatch(logOut({ navigate }))}
                  postRefreshTokenRequest={() => dispatch(refreshTokens({ navigate }))}
                />
              }
            />
          )}
          <Route path='auth/*' element={<Index />} />
          <Route index element={<Navigate to='/auth' />} />
          <Route path='*' element={<Navigate to='/auth' />} />
        </Routes>
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          closeOnClick
          pauseOnHover
        />
      </ConfigProvider>
    </>
  );
};

export default App;
