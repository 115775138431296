import axios from 'axios';
import {
  ICreateUserData,
  IDeleteUserData,
  IGetUserData,
  IGetUsersData,
  IUpdateUserData,
} from './types';
import qs from 'qs';

class UsersService {
  createUser = (data: ICreateUserData) => axios.post('users', data);
  getUsers = (params: IGetUsersData) =>
    axios.get('users', {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  getUser = ({ id, selection }: IGetUserData) =>
    axios.get(`users/${id}`, {
      params: {
        selection,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  updateUser = ({ id, ...data }: IUpdateUserData) => axios.patch(`users/${id}`, data);
  deleteUser = ({ id }: IDeleteUserData) => axios.delete(`users/${id}`);
}

export default new UsersService();
