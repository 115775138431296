export enum UserRoles {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export enum UserGenders {
  'MALE' = 'MALE',
  'FEMALE' = 'FEMALE',
  'UNKNOWN' = 'UNKNOWN',
}

export enum UserStatuses {
  DEACIVATED = 'DEACIVATED',
  ACTIVATED = 'ACTIVATED',
  PENDING = 'PENDING',
}

export enum ThemeTypes {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
}

export enum SignatureStatuses {
  UNSIGNED = 'UNSIGNED',
  REJECTED = 'REJECTED',
  VIEWED = 'VIEWED',
  SIGNED = 'SIGNED',
}

export enum DocumentStatuses {
  ARCHIVED = 'ARCHIVED',
  DELETED = 'DELETED',
  ACTIVE = 'ACTIVE',
}

export enum DocumentVerificationStatuses {
  NOT_APPROVED = 'NOT_APPROVED',
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  VIEWED = 'VIEWED',
  NONE = 'NONE',
}

export enum DocumentSignedStatuses {
  UNSIGNED = 'UNSIGNED',
  REJECTED = 'REJECTED',
  VIEWED = 'VIEWED',
  SIGNED = 'SIGNED',
}

export interface IObjId {
  id: string | null;
}

export interface IUser {
  id: string;
  status: UserStatuses;
  role: UserRoles;
  email: string;
  name: string;
  lastName: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  gender?: UserGenders;
  dateOfBirth?: string;
  phones?: string[];
  cover?: string;
}

export interface IFile {
  id: string;
  src: string;
  key: string;
  filename: string;
  fileSize: string;
  mimetype: string;
  encoding: string;
  extname: string;
  createdAt: string;
  updatedAt: string;
}

export interface ISignature {
  id: string;
  status: SignatureStatuses;
  createdAt: string;
  updatedAt: string;
}

export interface ISigner {
  id: string;
  user: IUser;
  signature: ISignature;
  createdAt: string;
  updatedAt: string;
}

export interface ISupervisor {
  id: string;
  createdAt: string;
  updatedAt: string;
  role: 'ADMIN' | 'USER';
}

export interface IDocumentType {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface IDocument {
  id: string;
  status: DocumentStatuses;
  name: string;
  type: IDocumentType;
  file: IFile;
  signers: ISigner[];
  supervisor?: IUser;
  owner: IUser;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  verificationStatus: DocumentVerificationStatuses;
  signStatus: DocumentSignedStatuses;
  signWithSignature: boolean;
  signWithPicture: boolean;
}

export type SortingDirection = 'ascend' | 'descend';
