import React, { useEffect } from 'react';
import './CreatePasswordPage.scss';
import { useTranslation } from 'react-i18next';
import HookedField from '../../../components/HookedField';
import { FormProvider, useForm } from 'react-hook-form';
import { FieldsTypes } from 'components/HookedField/types';
import { ICreateNewPasswordData } from 'store/auth/types';
import Button from 'components/ui/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { createNewPasswordSchema } from './schema';
import { createNewPassword } from 'store/auth/authSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Spin } from 'antd';

const CreatePasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const { createNewPasswordLoading } = useAppSelector((state) => state.auth);

  const email = searchParams.get('email');
  const code = searchParams.get('code');

  const methods = useForm<ICreateNewPasswordData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    resolver: yupResolver(createNewPasswordSchema),
    defaultValues: {
      email: email || '',
      password: '',
      code: code || '',
    },
  });

  useEffect(() => {
    if (!code) {
      navigate(`/auth/confirm-password?email=${email}`);
    }
  }, [code]);

  const submitHandler = async (data: ICreateNewPasswordData) => {
    await dispatch(createNewPassword({ data, navigate }));
  };

  const {
    formState: { isValid },
  } = methods;

  return (
    <div className='createPasswordPage'>
      <h2 className='createPasswordPage-title'>{t('CREATE_PASSWORD.TITLE')}</h2>
      <FormProvider {...methods}>
        <Spin spinning={createNewPasswordLoading}>
          <form
            className='createPasswordPage-form'
            autoComplete='off'
            onSubmit={methods.handleSubmit(submitHandler)}
          >
            <HookedField
              name='password'
              type={FieldsTypes.PASSWORD}
              label={t('CREATE_PASSWORD.FIELDS.CREATE_PASSWORD')}
              placeholder={t('CREATE_PASSWORD.FIELDS.PASSWORD_PLACEHOLDER')}
            />
            <HookedField
              name='repeatPassword'
              type={FieldsTypes.PASSWORD}
              label={t('CREATE_PASSWORD.FIELDS.REPEAT_PASSWORD')}
              placeholder={t('CREATE_PASSWORD.FIELDS.PASSWORD_PLACEHOLDER')}
            />
            <Button fullWidth={true} htmlType='submit' disabled={!isValid}>
              {t('CREATE_PASSWORD.BUTTON_SUBMIT_NAME')}
            </Button>
          </form>
        </Spin>
      </FormProvider>
      <div className='createPasswordPage-btnLogin-box'>
        <p className='createPasswordPage-btnLogin-description'>
          {t('CREATE_PASSWORD.ACC_DESCRIPTION')}
        </p>
        <Button
          className='createPasswordPage-btnLogin'
          type='link-secondary'
          onClick={() => navigate('/sign-in')}
        >
          {t('CREATE_PASSWORD.BUTTON_LOGIN_NAME')}
        </Button>
      </div>
    </div>
  );
};

export default CreatePasswordPage;
