import React from 'react';
import { useTranslation } from 'react-i18next';
import { Popover, Typography } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import './CheckedList.scss';
import { Link } from 'react-router-dom';

const { Text } = Typography;

interface ICheckedListItemProps {
  text: string;
  checked: boolean;
  link?: string;
}

interface ICheckedListProps {
  items: ICheckedListItemProps[];
  count: number;
}

const CheckedItem: React.FC<ICheckedListItemProps> = ({ text, checked, link }) => {
  const Body = () => (
    <div className='checkedItem'>
      <span className='checkedItem-text'>
        <Text ellipsis={{ tooltip: text }}>{text}</Text>
      </span>

      <span className='checkedItem-value'>
        {checked ? (
          <CheckOutlined style={{ color: '#2B8E09' }} />
        ) : (
          <CloseOutlined style={{ color: '#F9371C' }} />
        )}
      </span>
    </div>
  );

  return link ? (
    <Link to={link}>
      <Body />
    </Link>
  ) : (
    <Body />
  );
};

const CheckedList: React.FC<ICheckedListProps> = ({ items, count }) => {
  const { t } = useTranslation();
  return (
    <div className='checkedList'>
      {items.slice(0, count).map((item, index) => (
        <CheckedItem key={index} {...item} />
      ))}
      {items.length > count ? (
        <Popover
          placement='bottom'
          content={() => {
            return items.slice(count).map((item, index) => <CheckedItem key={index} {...item} />);
          }}
          arrowPointAtCenter
        >
          <span className='checkedList-more'>{t('BUTTONS.SHOW_MORE')}</span>
        </Popover>
      ) : null}
    </div>
  );
};

export default CheckedList;
