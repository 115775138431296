import { showConfirmModal } from '../../../../utils/functions';
import { TFunction, Trans } from 'react-i18next';
import { archiveDocument, deleteDocument } from '../../../../store/documents/documentsSlice';
import React from 'react';
import { AppDispatch } from '../../../../store';
import { NavigateFunction } from 'react-router-dom';

export const archiveDocumentConfirmModal = (
  documentId: string,
  t: TFunction,
  dispatch: AppDispatch,
  navigate: NavigateFunction,
) => {
  showConfirmModal({
    title: t('MODALS.DOCUMENT_ARCHIVE.TITLE'),
    content: (
      <Trans
        i18nKey='MODALS.DOCUMENT_ARCHIVE.CONTENT'
        components={{
          br: <br />,
        }}
      />
    ),
    onOk: () => {
      if (!documentId) {
        return;
      }
      dispatch(archiveDocument({ data: { id: documentId }, navigate }));
    },
  });
};

export const deleteDocumentConfirmModal = (
  documentId: string,
  t: TFunction,
  dispatch: AppDispatch,
) => {
  showConfirmModal({
    title: t('MODALS.DOCUMENT_DELETE.TITLE'),
    content: (
      <Trans
        i18nKey='MODALS.DOCUMENT_DELETE.CONTENT'
        components={{
          br: <br />,
        }}
      />
    ),
    onOk: () => {
      if (!documentId) {
        return;
      }
      dispatch(deleteDocument({ data: { id: documentId } }));
    },
  });
};
