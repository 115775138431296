import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import HookedField from '../../../../../../components/HookedField';
import { FieldsTypes } from '../../../../../../components/HookedField/types';
import Button from '../../../../../../components/ui/Button';
import Icon from '../../../../../../components/ui/Icon';
import { IconsTypes } from '../../../../../../components/ui/Icon/types';
import './AdditionalInfo.scss';
import AvatarUploader from '../../../../../../components/form/AvatarUploader';
import { UserGenders } from '../../../../../../utils/types';
import { IUpdateProfileCoverData, IUpdateProfileData } from '../../../../../../store/profile/types';
import { updateProfile, updateProfileCover } from '../../../../../../store/profile/profileSlice';
import dayjs from 'dayjs';

interface IAdditionalForm extends Omit<IUpdateProfileData, 'phones'> {
  phones?: { value: string }[];
}

const AdditionalInfo = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.profile);

  const changeProfileCoverForm = useForm<IUpdateProfileCoverData>({
    shouldFocusError: true,
    defaultValues: {
      file: user?.cover,
    },
  });

  const changeProfileForm = useForm<IAdditionalForm>({
    mode: 'onSubmit',
    shouldFocusError: true,
    defaultValues: {
      gender: user?.gender,
      dateOfBirth: user?.dateOfBirth,
      phones: user?.phones?.map((i) => ({ value: i })),
    },
  });
  const phones = useFieldArray({ control: changeProfileForm.control, name: 'phones' });

  const updateProfileCoverHandler = (data: IUpdateProfileCoverData) =>
    dispatch(updateProfileCover(data));
  const updateProfileHandler = ({ phones, ...data }: IAdditionalForm) =>
    dispatch(updateProfile({ ...data, phones: phones?.map((i) => i.value.replace('+', '')) }));

  return (
    <div className='profile-info'>
      <h4 className='title'>{t('PROFILE.PERSONAL_INFO')}</h4>
      <div className='profile-info-container'>
        <FormProvider {...changeProfileCoverForm}>
          <AvatarUploader name='file' onSubmit={updateProfileCoverHandler} />
        </FormProvider>
        <FormProvider {...changeProfileForm}>
          <form
            className='form'
            autoComplete='off'
            onSubmit={changeProfileForm.handleSubmit(updateProfileHandler)}
          >
            <HookedField
              name='gender'
              type={FieldsTypes.RADIOS}
              label={t('PROFILE.FIELDS.GENDER')}
              options={[
                {
                  label: t('PROFILE.GENDERS.MALE'),
                  value: UserGenders.MALE,
                },
                {
                  label: t('PROFILE.GENDERS.FEMALE'),
                  value: UserGenders.FEMALE,
                },
              ]}
            />
            <HookedField
              name='dateOfBirth'
              type={FieldsTypes.DATE_PICKER}
              label={t('PROFILE.FIELDS.BIRTHDAY')}
              placeholder='dd.mm.yyyy'
              disabledDate={(current) => current && current > dayjs().subtract(18, 'year')}
            />
            {phones.fields.map((item, index) => (
              <div key={item.id} className='phone-input-wrapper'>
                <HookedField
                  type={FieldsTypes.PHONE}
                  name={`phones.${index}.value`}
                  label={t('PROFILE.FIELDS.PHONE_NUMBER')}
                  placeholder='+1 (555) 000-0000'
                />
                <Button
                  className='phone-input-button'
                  onClick={() => phones.remove(index)}
                  type='link'
                >
                  <Icon
                    className='phone-input-icon phone-input-icon_remove'
                    icon={IconsTypes.PLUS}
                  />
                  {t('BUTTONS.REMOVE')}
                </Button>
              </div>
            ))}
            {phones.fields.length < 3 && (
              <Button
                onClick={phones.append}
                className='phone-input-add phone-input-button'
                type='link'
              >
                <Icon className='phone-input-icon' icon={IconsTypes.PLUS} />
                {t('BUTTONS.ADD_PHONE_NUMBER')}
              </Button>
            )}
            <Button htmlType='submit' fullWidth>
              {t('BUTTONS.SAVE_CHANGES')}
            </Button>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default AdditionalInfo;
