import React from 'react';
import { Collapse } from 'antd';
import { DocumentVerificationStatuses, IUser } from '../../../../../utils/types';
import UserItem from '../UserItem';
import { useTranslation } from 'react-i18next';

import { BadgeTypes } from '../../../../../components/ui/Badge/types';

import './Supervisor.scss';
import { getDocumentVerificationBadge } from '../../../../../utils/functions';

const { Panel } = Collapse;

interface ISupervisorProps {
  supervisor: IUser;
  verificationStatus: DocumentVerificationStatuses;
}

const Supervisor: React.FC<ISupervisorProps> = ({ supervisor, verificationStatus }) => {
  const { t } = useTranslation();

  return (
    <Collapse activeKey={['1']} className='supervisor'>
      <Panel header={t('DOCUMENT_VIEW_PAGE.SUPERVISOR.TITLE')} key='1' showArrow={false}>
        <UserItem
          user={supervisor}
          status={getDocumentVerificationBadge({ status: verificationStatus, t })}
        />
      </Panel>
    </Collapse>
  );
};

export default Supervisor;
