import { message, Modal, ModalFuncProps } from 'antd';
import { RcFile } from 'antd/es/upload/interface';
import dayjs from 'dayjs';

import {
  DocumentSignedStatuses,
  DocumentStatuses,
  DocumentVerificationStatuses,
  IUser,
  SignatureStatuses,
} from 'utils/types';
import storage from './storage';
import { TFunction } from 'react-i18next';
import { BadgeTypes } from '../components/ui/Badge/types';

export const getUserFullName = ({ name, lastName, email }: Partial<IUser>) =>
  name && lastName ? `${name} ${lastName}` : email;

export const getUserAbbreviation = (user: IUser) => {
  const { name, email, lastName } = user;
  if (!name && !lastName && !email) {
    return '';
  }
  if (name && lastName) {
    return `${(name[0] + lastName[0]).toUpperCase()}`;
  }
  if (email) {
    return email[0].toUpperCase();
  }
};

export const getFormattedDate = (date: string, format: string) => {
  return dayjs(date).format(format);
};

export const getUserDataFromJwt = () =>
  JSON.parse(atob(storage.getFromStorage('token')?.split('.')[1] ?? ''));

export const beutifyTableData = <T = any>(data: T): T =>
  Object.fromEntries(Object.entries(data as any).map(([key, value]) => [key, value ?? ''])) as T;

export const showConfirmModal = (props: ModalFuncProps) =>
  Modal.confirm({
    icon: null,
    centered: true,
    maskClosable: true,
    closable: true,
    ...props,
    onOk: () => {
      if (props.onOk) props.onOk();
    },
  });

export const showNoControlsModal = (props: ModalFuncProps) =>
  Modal.success({
    icon: null,
    centered: true,
    maskClosable: true,
    closable: true,
    className: `ant-modal-confirm-no-btns ${props.className}`,
    ...props,
  });

export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export const beforeUpload = (file: RcFile) => {
  console.log(file);
  const isPdf = file.type === 'application/pdf';
  if (!isPdf) {
    message.error('You can only upload PDF file!');
    return false;
  }
  const isLt10M = file.size / 1024 / 1024 < 10;
  if (!isLt10M) {
    message.error('Image should be smaller than 10Mb');
    return false;
  }
  return true;
};

export const getResendTime = (resendDate: string) => {
  const startTime = dayjs(resendDate).add(80, 'second');
  const endTime = dayjs();
  return startTime.diff(endTime, 'second') > 0 ? startTime.diff(endTime, 'second') : 0;
};

export const readFileAsDataURL = (file: File) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => resolve(fileReader.result);
    fileReader.readAsDataURL(file);
  });
};

export interface IParentSize {
  width: number;
  height: number;
}

export interface ISignatureOptions {
  width: number;
  height: number;
  x: number;
  y: number;
}

export interface IRelativeSignatureOptions {
  widthPercent: number;
  heightPercent: number;
  xPercent: number;
  yPercent: number;
}

export const getRelativeSignatureOptions = ({
  parentSize,
  signatureOptions,
}: {
  parentSize: IParentSize;
  signatureOptions: ISignatureOptions;
}): IRelativeSignatureOptions => {
  return {
    widthPercent: (signatureOptions.width / parentSize.width) * 100,
    heightPercent: (signatureOptions.height / parentSize.height) * 100,
    xPercent: (signatureOptions.x / parentSize.width) * 100,
    yPercent: (signatureOptions.y / parentSize.height) * 100,
  };
};

export const getSignatureOptions = ({
  parentSize,
  relativeSignatureOptions,
}: {
  parentSize: IParentSize;
  relativeSignatureOptions: IRelativeSignatureOptions;
}): ISignatureOptions => {
  return {
    width: (parentSize.width * relativeSignatureOptions.widthPercent) / 100,
    height: (parentSize.height * relativeSignatureOptions.heightPercent) / 100,
    x: (parentSize.width * relativeSignatureOptions.xPercent) / 100,
    y:
      parentSize.height -
      (parentSize.height * relativeSignatureOptions.yPercent) / 100 -
      (parentSize.height * relativeSignatureOptions.heightPercent) / 100,
  };
};

export const getArrayBufferFromFile = async (url: string) => {
  const response = await fetch(url);
  const arrayBuffer = await response.arrayBuffer();
  return new Uint8Array(arrayBuffer);
};

export const getDocumentSignBadge = ({
  status,
  t,
}: {
  status?: SignatureStatuses;
  t: TFunction;
}): { text: string; type: BadgeTypes } => {
  if (!status) {
    return {
      text: t('DOCUMENT_VIEW_PAGE.SIGNERS.UNSIGNED'),
      type: BadgeTypes.ERROR,
    };
  }

  const statusesToBadges: {
    [K in DocumentSignedStatuses]: BadgeTypes;
  } = {
    [DocumentSignedStatuses.UNSIGNED]: BadgeTypes.ERROR,
    [DocumentSignedStatuses.SIGNED]: BadgeTypes.SUCCESS,
    [DocumentSignedStatuses.VIEWED]: BadgeTypes.SECONDARY,
    [DocumentSignedStatuses.REJECTED]: BadgeTypes.ERROR,
  };

  return {
    type: statusesToBadges[status],
    text: t(`DOCUMENT_VIEW_PAGE.SIGNERS.${status}`),
  };
};

// let verificationType;
// switch (history.verificationStatus) {
//   case DocumentVerificationStatuses.APPROVED:
//     verificationType = BadgeTypes.SUCCESS;
//     break;
//   case DocumentVerificationStatuses.NONE:
//     verificationType = BadgeTypes.SECONDARY;
//     break;
//   case DocumentVerificationStatuses.NOT_APPROVED:
//     verificationType = BadgeTypes.ERROR;
//     break;
//   case DocumentVerificationStatuses.PENDING:
//     verificationType = BadgeTypes.WARNING;
//     break;
// }

export const getDocumentVerificationBadge = ({
  status,
  t,
}: {
  status: DocumentVerificationStatuses;
  t: TFunction;
}): { text: string; type: BadgeTypes } => {
  const statusesToBadges: {
    [K in DocumentVerificationStatuses]: BadgeTypes;
  } = {
    [DocumentVerificationStatuses.APPROVED]: BadgeTypes.SUCCESS,
    [DocumentVerificationStatuses.NOT_APPROVED]: BadgeTypes.ERROR,
    [DocumentVerificationStatuses.PENDING]: BadgeTypes.WARNING,
    [DocumentVerificationStatuses.VIEWED]: BadgeTypes.SECONDARY,
    [DocumentVerificationStatuses.NONE]: BadgeTypes.SECONDARY,
  };

  return {
    type: statusesToBadges[status],
    text: t(`DOCUMENT_VIEW_PAGE.SUPERVISOR.${status}`),
  };
};

export const getDocumentStatusBadge = ({
  status,
  t,
}: {
  status: DocumentStatuses;
  t: TFunction;
}): { text: string; type: BadgeTypes } => {
  const statusesToBadges: {
    [K in DocumentStatuses]: BadgeTypes;
  } = {
    [DocumentStatuses.ACTIVE]: BadgeTypes.SUCCESS,
    [DocumentStatuses.DELETED]: BadgeTypes.ERROR,
    [DocumentStatuses.ARCHIVED]: BadgeTypes.WARNING,
  };

  return {
    type: statusesToBadges[status],
    text: t(`DOCUMENTS.STATUSES.${status}`),
  };
};
