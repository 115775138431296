import React, { useEffect } from 'react';
import Logo from '../../ui/Logo';
import { Badge, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Icon from '../../ui/Icon';
import { IconsTypes } from '../../ui/Icon/types';
import './Sidebar.scss';
import { useAppSelector, useAppDispatch } from '../../../hooks';
import { getDocumentsStats } from '../../../store/documents/documentsSlice';

const Sidebar = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { isCollapsedSidebar } = useAppSelector((state) => state.app);
  const { stats, getDocumentsStatsLoading } = useAppSelector((state) => state.documents);

  useEffect(() => {
    dispatch(getDocumentsStats());
  }, []);

  const menuItems = [
    {
      label: t('PAGES.DOCUMENTS'),
      key: '/documents',
      icon: <Icon icon={IconsTypes.DOCUMENTS} size={24} />,
      children: [
        {
          label: (
            <div className='sidebar-subItem'>
              <span className='sidebar-subItem-text'>{t('PAGES.DOCUMENTS_ALL')}</span>
            </div>
          ),
          key: '/main/documents',
          onClick: () => {
            if (location.pathname !== '/main/documents') navigate('/main/documents');
          },
        },
        {
          label: (
            <div className='sidebar-subItem'>
              <span className='sidebar-subItem-text'>{t('PAGES.DOCUMENTS_INCOME')}</span>
              {stats?.income ? (
                <Badge count={stats?.income} overflowCount={99} className='sidebar-subItem-count' />
              ) : null}
            </div>
          ),
          key: '/main/documents/income',
          onClick: () => {
            if (location.pathname !== '/main/documents/income') navigate('/main/documents/income');
          },
        },
        {
          label: (
            <div className='sidebar-subItem'>
              <span className='sidebar-subItem-text'>{t('PAGES.DOCUMENTS_OUTCOME')}</span>
            </div>
          ),
          key: '/main/documents/outcome',
          onClick: () => {
            if (location.pathname !== '/main/documents/outcome')
              navigate('/main/documents/outcome');
          },
        },
        {
          label: (
            <div className='sidebar-subItem'>
              <span className='sidebar-subItem-text'>{t('PAGES.DOCUMENTS_UNVERIFIED')}</span>
              {stats?.unverified ? (
                <Badge
                  count={stats?.unverified}
                  overflowCount={99}
                  className='sidebar-subItem-count'
                />
              ) : null}
            </div>
          ),
          key: '/main/documents/unverified',
          onClick: () => {
            if (location.pathname !== '/main/documents/unverified')
              navigate('/main/documents/unverified');
          },
        },
        {
          label: (
            <div className='sidebar-subItem'>
              <span className='sidebar-subItem-text'>{t('PAGES.DOCUMENTS_REJECTED')}</span>
            </div>
          ),
          key: '/main/documents/rejected',
          onClick: () => {
            if (location.pathname !== '/main/documents/rejected')
              navigate('/main/documents/rejected');
          },
        },
        {
          label: (
            <div className='sidebar-subItem'>
              <span className='sidebar-subItem-text'>{t('PAGES.DOCUMENTS_ARCHIVED')}</span>
            </div>
          ),
          key: '/main/documents/archived',
          onClick: () => {
            if (location.pathname !== '/main/documents/archived')
              navigate('/main/documents/archived');
          },
        },
      ],
    },
    {
      label: t('PAGES.USERS'),
      key: '/main/users',
      onClick: () => navigate('/main/users'),
      icon: <Icon icon={IconsTypes.USERS} size={24} />,
    },
  ];

  return (
    <div className='sidebar'>
      <Link to='/main/documents'>{<Logo isCollapsed={isCollapsedSidebar} />}</Link>
      <Menu
        className='menu'
        mode='inline'
        items={menuItems}
        selectedKeys={[location.pathname || '/main/documents']}
        defaultOpenKeys={
          location.pathname.indexOf('/main/documents') !== -1 ? ['/main/documents'] : []
        }
      />
    </div>
  );
};

export default Sidebar;
