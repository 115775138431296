import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import HookedField from '../../../components/HookedField';
import { FieldsTypes } from 'components/HookedField/types';
import Button from 'components/ui/Button';
import './AskUsPage.scss';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Spin } from 'antd';
import { askUsSchema } from './schema';
import { ESendEmailSubjects, ISendEmailData } from '../../../store/auth/types';
import { sendEmail } from '../../../store/auth/authSlice';

type TAskUsForm = Omit<ISendEmailData, 'subject'>;

const AskUsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { sendEmailLoading } = useAppSelector((state) => state.auth);

  const methods = useForm<TAskUsForm>({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: yupResolver(askUsSchema),
  });

  const submitHandler = async (data: TAskUsForm) =>
    dispatch(
      sendEmail({ data: { ...data, subject: ESendEmailSubjects.REQUEST_ACCESS }, navigate }),
    );

  const {
    formState: { isValid },
  } = methods;

  return (
    <div className='askUsPage'>
      <div className='askUsPage-container'>
        <h2 className='askUsPage-title'>{t('ASK_US.TITLE')}</h2>
        <p className='askUsPage-description'>{t('ASK_US.DESCRIPTION')}</p>
        <FormProvider {...methods}>
          <Spin spinning={sendEmailLoading}>
            <form
              className='askUsPage-form'
              autoComplete='off'
              onSubmit={methods.handleSubmit(submitHandler)}
            >
              <HookedField
                name='email'
                type={FieldsTypes.TEXT}
                label={t('LABELS.EMAIL')}
                placeholder='ein-des-ein@ein-des-ein.com'
              />
              <HookedField
                name='message'
                type={FieldsTypes.TEXT}
                label={t('ASK_US.LABELS.MESSAGE')}
                placeholder={t('ASK_US.FIELDS.MESSAGE')}
              />
              <Button
                className='askUsPage-btn-submit'
                fullWidth={true}
                htmlType='submit'
                disabled={!isValid}
              >
                {t('BUTTONS.SUBMIT')}
              </Button>
            </form>
          </Spin>
        </FormProvider>
      </div>
    </div>
  );
};

export default AskUsPage;
