import React, { Dispatch, SetStateAction } from 'react';
import Button from '../ui/Button';
import Icon from '../ui/Icon';
import { IconsTypes } from '../ui/Icon/types';
import { Rnd, RndDragCallback, RndResizeCallback } from 'react-rnd';

import './SignatureDragger.scss';
import { Space, Tooltip } from 'antd';
import { IParentSize, ISignatureOptions } from '../../utils/functions';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks';

interface ISignatureDragger {
  element: ISignatureOptions;
  onChange: (newData: Partial<ISignatureOptions>) => void;
  onRemove: () => void;
  relativeOptions: IParentSize;
  signatureSrc: string;
  onAddSignatureOnPage: () => void;
  onDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

const SignatureDragger: React.FC<ISignatureDragger> = ({
  signatureSrc,
  element: { x, y, width, height },
  onChange,
  onRemove,
  relativeOptions,
  onAddSignatureOnPage,
  onDrawerOpen,
}) => {
  const { t } = useTranslation();

  const { deleteDocumentLoading, rejectDocumentLoading, signDocumentLoading, uploadFileLoading } =
    useAppSelector((state) => state.documents);

  const dragHandler: RndDragCallback = (_event, { x, y }) => {
    onChange({ x: x / (relativeOptions.width / 100), y: y / (relativeOptions.height / 100) });
  };

  const resizeHandler: RndResizeCallback = (_event, _direction, ref, _delta, position) => {
    onChange({
      width: parseFloat(ref.style.width) / (relativeOptions.width / 100),
      height: parseFloat(ref.style.height) / (relativeOptions.height / 100),
      x: position.x / (relativeOptions.width / 100),
      y: position.y / (relativeOptions.height / 100),
    });
  };

  const isDisabled =
    deleteDocumentLoading || rejectDocumentLoading || uploadFileLoading || signDocumentLoading;

  return (
    <Rnd
      className='signatureDragger'
      onDragStop={dragHandler}
      onResizeStop={resizeHandler}
      bounds='parent'
      lockAspectRatio={4 / 3}
      position={{ x: (relativeOptions.width / 100) * x, y: (relativeOptions.height / 100) * y }}
      size={{
        width: (relativeOptions.width / 100) * width,
        height: (relativeOptions.height / 100) * height,
      }}
    >
      <div className='signatureDragger-item'>
        <img className='signatureDragger-picture' src={signatureSrc} alt='signature' />
        <Space.Compact block className='signatureDragger-actions'>
          <Tooltip title={t('BUTTONS.EDIT_SIGNATURE')}>
            <Button
              type='primary'
              justIcon={true}
              onClick={() => onDrawerOpen(true)}
              disabled={isDisabled}
              spinning={signDocumentLoading}
            >
              <Icon icon={IconsTypes.EDIT} size={16} />
            </Button>
          </Tooltip>
          <Tooltip title={t('BUTTONS.COPY')}>
            <Button
              type='primary'
              justIcon={true}
              onClick={onAddSignatureOnPage}
              disabled={isDisabled}
              spinning={signDocumentLoading}
            >
              <Icon icon={IconsTypes.COPY} size={16} />
            </Button>
          </Tooltip>
          <Tooltip title={t('BUTTONS.DELETE')}>
            <Button
              type='primary'
              justIcon={true}
              onClick={onRemove}
              disabled={isDisabled}
              spinning={signDocumentLoading}
            >
              <Icon icon={IconsTypes.BUCKET} size={16} />
            </Button>
          </Tooltip>
        </Space.Compact>
      </div>
      <div className='top-left'></div>
      <div className='top-right'></div>
      <div className='bottom-left'></div>
      <div className='bottom-right'></div>
    </Rnd>
  );
};

export default SignatureDragger;
