import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import UsersService from './usersService';
import {
  ICreateUserData,
  IDeleteUserData,
  IGetUserData,
  IUpdateUserData,
  IUsersOptions,
} from './types';
import { IUser } from '../../utils/types';
import { toast } from 'react-toastify';
import i18n from '../../i18n';
import { RootState } from '../index';
import { NavigateFunction } from 'react-router-dom';

export const initialOptions: IUsersOptions = {
  pagination: {
    total: 0,
    current: 1,
    pageSize: 10,
  },
  filters: null,
  sorter: {
    field: 'updatedAt',
    order: 'descend',
  },
};

interface IUsersState {
  users: IUser[];
  user: IUser | null;
  options: IUsersOptions;
  getUsersLoading: boolean;
  deleteUserLoading: boolean;
  getUserLoading: boolean;
  updateUserLoading: boolean;
}

const initialState = {
  users: [],
  user: null,
  options: initialOptions,
  getUsersLoading: false,
  deleteUserLoading: false,
  getUserLoading: false,
  updateUserLoading: false,
} as IUsersState;

export const getUsers = createAsyncThunk(
  'users/getUsers',
  async (data: IUsersOptions, thunkAPI) => {
    console.log('data', data);
    try {
      const { pagination, sorter, filters, search, status } = data;
      const options: any = {};
      if (sorter) {
        const { field, order } = sorter;
        if (order === 'ascend') {
          options.asc = [field];
        } else if (order === 'descend') {
          options.desc = [field];
        }
      }
      const response = await UsersService.getUsers({
        page: pagination.current,
        take: pagination.pageSize,
        search,
        ...options,
        status,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (data: IDeleteUserData, thunkAPI) => {
    try {
      const response = await UsersService.deleteUser(data);
      const { users: state } = thunkAPI.getState() as RootState;
      const { options } = state;
      thunkAPI.dispatch(getUsers(options));
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const getUser = createAsyncThunk('users/getUser', async ({ id }: IGetUserData, thunkAPI) => {
  try {
    const response = await UsersService.getUser({ id });
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async ({ data }: { data: IUpdateUserData }, thunkAPI) => {
    try {
      const response = await UsersService.updateUser(data);
      const { users: state } = thunkAPI.getState() as RootState;
      const { options } = state;
      thunkAPI.dispatch(getUsers(options));
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

export const createUser = createAsyncThunk(
  'users/createUser',
  async ({ data }: { data: ICreateUserData }, thunkAPI) => {
    try {
      const response = await UsersService.createUser(data);
      const { users: state } = thunkAPI.getState() as RootState;
      const { options } = state;
      thunkAPI.dispatch(getUsers(options));
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  },
);

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsersOptions: (state: IUsersState, action: PayloadAction<Partial<IUsersOptions>>) => {
      state.options = { ...state.options, ...action.payload };
    },
    resetUsersOptions: (state: IUsersState) => {
      state.options = initialOptions;
    },
    resetCurrentUser: (state: IUsersState) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    // getUsers
    builder
      .addCase(getUsers.pending, (state: IUsersState) => {
        state.getUsersLoading = true;
      })
      .addCase(getUsers.fulfilled, (state: IUsersState, action) => {
        console.log('getUsers.fulfilled', action.payload);
        const { result, count } = action.payload;
        const options = action.meta.arg;
        state.users = result;
        state.options = { ...options, pagination: { ...options.pagination, total: count } };
        state.getUsersLoading = false;
      })
      .addCase(getUsers.rejected, (state: IUsersState, action) => {
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.getUsersLoading = false;
      });
    // deleteUser
    builder
      .addCase(deleteUser.pending, (state: IUsersState) => {
        state.deleteUserLoading = true;
      })
      .addCase(deleteUser.fulfilled, (state: IUsersState) => {
        toast(i18n.t('NOTIFICATIONS.USER_DELETED') as string, { type: 'success' });
        state.deleteUserLoading = false;
      })
      .addCase(deleteUser.rejected, (state: IUsersState, action) => {
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.deleteUserLoading = false;
      });
    // getUser
    builder
      .addCase(getUser.pending, (state: IUsersState) => {
        state.getUserLoading = true;
      })
      .addCase(getUser.fulfilled, (state: IUsersState, action) => {
        state.user = action.payload;
        state.getUserLoading = false;
      })
      .addCase(getUser.rejected, (state: IUsersState, action) => {
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.getUserLoading = false;
      });
    // updateUser
    builder
      .addCase(updateUser.pending, (state: IUsersState) => {
        state.updateUserLoading = true;
      })
      .addCase(updateUser.fulfilled, (state: IUsersState, action) => {
        console.log('updateUser.fulfilled payload', action.payload);
        toast(i18n.t('NOTIFICATIONS.USER_UPDATED') as string, { type: 'success' });
        state.updateUserLoading = false;
      })
      .addCase(updateUser.rejected, (state: IUsersState, action) => {
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.updateUserLoading = false;
      });
    // createUser
    builder
      .addCase(createUser.pending, (state: IUsersState) => {
        state.updateUserLoading = true;
      })
      .addCase(createUser.fulfilled, (state: IUsersState, action) => {
        console.log('createUser.fulfilled payload', action.payload);
        toast(i18n.t('NOTIFICATIONS.USER_CREATED') as string, { type: 'success' });
        state.updateUserLoading = false;
      })
      .addCase(createUser.rejected, (state: IUsersState, action) => {
        const errors = action.payload as string[];
        errors.forEach((error) => {
          toast(i18n.t(`NOTIFICATIONS.${error}`) as string, { type: 'error' });
        });
        state.updateUserLoading = false;
      });
  },
});

export const { setUsersOptions, resetUsersOptions, resetCurrentUser } = usersSlice.actions;

export default usersSlice.reducer;
