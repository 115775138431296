import { useMemo } from 'react';
import { IPageTableFilter } from '../components/PageTable/types';
import { IDocumentsFilter } from '../store/documents/types';

export const useMemoFilters = (filters: IDocumentsFilter | null, exclude?: string[]) =>
  useMemo<IPageTableFilter[]>(() => {
    if (!filters) return [];

    const filteredKeys = Object.keys(filters).filter((key) => filters[key as keyof typeof filters]);

    if (!filteredKeys.length) return [];

    let formattedFilters = filteredKeys
      .map((key) => {
        const typedKey = key as keyof typeof filters;
        if (typeof filters[typedKey] === 'string')
          return {
            filter: key,
            value: filters[typedKey],
          };

        if (Array.isArray(filters[typedKey])) {
          return (filters[typedKey] as Array<string>).map((deepKey: string) => ({
            filter: key,
            value: deepKey,
          }));
        }
      })
      .flat() as Array<{ filter: string; value: string }>;

    if (exclude?.length) {
      formattedFilters = formattedFilters.filter((filter) => !exclude.includes(filter.filter));
    }

    return formattedFilters;
  }, [filters]);
