import * as yup from 'yup';
import i18n from '../../../i18n';

export const createNewPasswordSchema = yup.object().shape({
  email: yup.string().required(i18n.t('ERRORS.REQUIRED')).email(),
  password: yup.string().min(8, i18n.t('ERRORS.PASSWORD')).required(i18n.t('ERRORS.REQUIRED')),
  repeatPassword: yup
    .string()
    .test('passwords-match', i18n.t('ERRORS.PASSWORD_MUST_MATCH'), function (value) {
      return this.parent.password === value;
    }),
});
