import React, { ChangeEvent, useRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../ui/Button';
import SignatureCanvas from 'react-signature-canvas';
import { readFileAsDataURL } from '../../utils/functions';
import ReactSignatureCanvas from 'react-signature-canvas';

interface IPictureSignatureModalProps {
  onUpload?: (signatureUrl: string) => void;
}

const SignatureDrawer = forwardRef<ReactSignatureCanvas, IPictureSignatureModalProps>(
  ({ onUpload }, ref) => {
    const { t } = useTranslation();
    const uploaderPNGRef = useRef<HTMLInputElement>(null);

    const uploadPNGHandler = async (event: ChangeEvent<HTMLInputElement>) => {
      const pngFile = event.target.files?.item(0);
      if (!pngFile) {
        return;
      }
      const signatureUrl = (await readFileAsDataURL(pngFile)) as string;
      onUpload && onUpload(signatureUrl);
    };

    return (
      <div className='drawASignature-container'>
        <div className='drawASignature-btn-upload-box'>
          <p>{t('MODALS.DRAW_A_SIGNATURE.CONTENT.TEXT')}</p>
          <Button
            type='link-secondary'
            onClick={() => {
              uploaderPNGRef.current?.click();
              close();
            }}
          >
            {t('MODALS.DRAW_A_SIGNATURE.CONTENT.BUTTON_UPLOAD')}
          </Button>
          <input
            type='file'
            accept='.png'
            ref={uploaderPNGRef}
            hidden={true}
            onChange={uploadPNGHandler}
          />
        </div>
        <div className='drawASignature-сanvas-box'>
          <SignatureCanvas
            ref={ref}
            canvasProps={{ width: 350, height: 262.5, className: 'sigCanvas' }}
          />
        </div>
      </div>
    );
  },
);

SignatureDrawer.displayName = 'SignatureDrawer';

export default SignatureDrawer;
