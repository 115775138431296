import React, { FC, SyntheticEvent } from 'react';
import './UserInfo.scss';
import { Avatar, Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/ui/Button';
import Icon from '../../../../../components/ui/Icon';
import { IconsTypes } from '../../../../../components/ui/Icon/types';
import { UserOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { IUser } from '../../../../../utils/types';
import { getFormattedDate } from '../../../../../utils/functions';

const { Panel } = Collapse;

interface IUserInfo {
  user: IUser;
}

const UserInfo: FC<IUserInfo> = ({ user: { cover, phones, dateOfBirth, gender, email, role } }) => {
  const { t } = useTranslation();

  return (
    <Collapse
      expandIcon={({ isActive }) => (
        <Icon
          className={classNames('user-info-collapse-icon', {
            'user-info-collapse-icon-active': isActive,
          })}
          icon={IconsTypes.ARROW_UP}
        />
      )}
      className='user-info'
      expandIconPosition='end'
    >
      <Panel
        key={t('USER_DOCUMENTS.USER_INFO.TITLE')}
        header={t('USER_DOCUMENTS.USER_INFO.TITLE')}
        // FOR FUTURE FEATURES
        // extra={
        //   <Button className='send-message-btn' onClick={(e: SyntheticEvent) => e.stopPropagation()}>
        //     <Icon icon={IconsTypes.PLANE} size={16} />
        //     <span>{t('BUTTONS.SEND_MESSAGE')}</span>
        //   </Button>
        // }
      >
        <Avatar className='user-info-avatar' shape='square' src={cover} icon={<UserOutlined />} />
        <table className='user-info-body'>
          <tbody>
            <tr>
              <th>{t('LABELS.ROLE')}</th>
              <td>{role}</td>
            </tr>
            {gender && (
              <tr>
                <th>{t('LABELS.GENDER')}</th>
                <td>{t(`PROFILE.GENDERS.${gender}`)}</td>
              </tr>
            )}
            {dateOfBirth && (
              <tr>
                <th>{t('LABELS.BIRTHDAY')}</th>
                <td>{getFormattedDate(dateOfBirth, 'D MMM YYYY')}</td>
              </tr>
            )}
            <tr>
              <th>{t('LABELS.EMAIL')}</th>
              <td>{email}</td>
            </tr>
            {phones?.map((phone, index) => (
              <tr key={index}>
                <th>{t('LABELS.PHONE_NUMBER')}</th>
                <td>{phone}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Panel>
    </Collapse>
  );
};

export default UserInfo;
