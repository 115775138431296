import React from 'react';
import './Heading.scss';
import BackBtn from '../BackBtn';
import classNames from 'classnames';
interface IHeadingProps {
  title: string;
  description?: string;
  withBackBtn?: boolean;
  className?: string;
}

const Heading: React.FC<IHeadingProps> = ({
  title,
  description,
  withBackBtn = false,
  className,
}) => {
  return (
    <div className={classNames('heading', className)}>
      <div className='heading-content'>
        <div className='heading-content--header'>
          {withBackBtn && <BackBtn className='heading-backBtn' />}
          <h2 className='heading-title'>{title}</h2>
        </div>
        <p className='heading-description'>{description}</p>
      </div>
    </div>
  );
};

export default Heading;
