import React, { FC } from 'react';
import classNames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { FieldsTypes, HookedFieldProps, FieldProps } from './types';
import { get } from 'lodash';
import { Input, Select, Checkbox, Radio, InputNumber } from 'antd';
import dayjs from 'dayjs';
import Icon from '../ui/Icon';
import { IconsTypes } from '../ui/Icon/types';
import Field from '../ui/Field';
import { DAY_FORMAT, TIME_FORMAT } from '../../utils/constants';
import DatePicker from '../form/DatePicker';
import VerificationInput from 'react-verification-input';
import InputMask from 'react-input-mask';

import './HookedField.scss';

dayjs.locale('en');

const { Password, TextArea } = Input;

const { RangePicker } = DatePicker;

const HookedField: FC<HookedFieldProps> = ({
  name,
  type,
  label,
  caption,
  placeholder,
  disabled,
  className,
  options,
  showSearch,
  defaultValue,
  inOneRow,
  maxLength,
  showCount,
  onSelect,
  onSearch,
  loading,
  optionFilterProp,
  customChangeTags,
  mode,
  length,
  autoFocus,
  validChars,
  hidden,
  disabledDate,
  ...rest
}): JSX.Element => {
  const id = uuidv4();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const fields: { [key in FieldsTypes]: FC<FieldProps> } = {
    [FieldsTypes.TEXT]: ({ value, onChange }) => (
      <Input
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={onChange}
        id={id}
        defaultValue={defaultValue}
        maxLength={maxLength}
      />
    ),
    [FieldsTypes.PHONE]: ({ value, onChange }) => (
      <InputMask
        mask='+999999999999999'
        className={['ant-input', className].join(' ')}
        type='tel'
        placeholder={placeholder}
        disabled={disabled}
        value={value || ''}
        onChange={onChange}
        id={id}
        // @ts-ignore
        maskChar={null}
      />
    ),
    [FieldsTypes.TEXTAREA]: ({ value, onChange }) => (
      <TextArea
        className={className}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        id={id}
        autoSize={{ minRows: 4, maxRows: 4 }}
        maxLength={maxLength}
        showCount={showCount}
      />
    ),
    [FieldsTypes.PASSWORD]: ({ value, onChange }) => (
      <Password
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={onChange}
        id={id}
      />
    ),
    [FieldsTypes.SELECT]: ({ value, onChange }) => (
      <Select
        options={options}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={onChange}
        showSearch={showSearch}
        id={id}
        mode={mode}
        defaultValue={defaultValue}
        suffixIcon={<Icon size={16} icon={IconsTypes.SELECT} color='#130F26' />}
      />
    ),
    [FieldsTypes.SELECT_TAGS]: ({ value, onChange }) => (
      <Select
        options={options}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={(tags: string[], option: any) => {
          onChange && customChangeTags && customChangeTags(tags, option, onChange);
        }}
        showSearch={showSearch}
        id={id}
        defaultValue={defaultValue}
        mode='multiple'
        onSelect={onSelect}
        onSearch={onSearch}
        loading={loading}
        optionFilterProp={optionFilterProp}
      />
    ),
    [FieldsTypes.CHECKBOX]: ({ value, onChange }) => (
      <Checkbox
        className={className}
        onChange={(e) => onChange && onChange(e.target.checked)}
        checked={value}
        disabled={disabled}
        id={id}
      >
        {placeholder}
      </Checkbox>
    ),
    [FieldsTypes.CHECKBOXES]: ({ value, onChange }) => (
      <Checkbox.Group
        className={className}
        onChange={onChange}
        disabled={disabled}
        options={options}
        defaultValue={defaultValue}
        value={value}
      />
    ),
    [FieldsTypes.RADIOS]: ({ value, onChange }) => (
      <Radio.Group
        id={id}
        disabled={disabled}
        onChange={onChange}
        value={value}
        options={options}
        defaultValue={defaultValue}
      />
    ),
    [FieldsTypes.NUMBER]: ({ value, onChange }) => (
      <InputNumber
        className={className}
        type='number'
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={onChange}
        id={id}
        defaultValue={defaultValue}
        controls={false}
        {...rest}
      />
    ),
    [FieldsTypes.TIME]: ({ value, onChange }) => (
      <RangePicker
        id={id}
        disabled={disabled}
        onChange={onChange}
        value={value}
        name={name}
        defaultValue={defaultValue && dayjs(defaultValue).toISOString()}
        showNow={false}
        format={TIME_FORMAT}
      />
    ),
    [FieldsTypes.TIME_RANGE]: ({ value, onChange }) => (
      <RangePicker
        id={id}
        disabled={disabled}
        onChange={onChange}
        name={name}
        defaultValue={defaultValue}
        showNow={false}
        format={TIME_FORMAT}
        picker='time'
        mode={undefined}
      />
    ),
    [FieldsTypes.DATE_PICKER]: ({ value, onChange }) => (
      <DatePicker
        id={id}
        disabled={disabled}
        onChange={(date) => (date ? onChange?.(date.toISOString()) : onChange?.(null))}
        name={name}
        value={value && dayjs(value)}
        format={DAY_FORMAT}
        className={'ant-input'}
        placeholder={placeholder}
        disabledDate={disabledDate}
      />
    ),
    [FieldsTypes.VERIFICATION]: ({ value, onChange }) => (
      <VerificationInput
        value={value}
        onChange={onChange}
        length={length}
        autoFocus={autoFocus}
        placeholder={placeholder}
        validChars={validChars}
      />
    ),
  };

  const error = get(errors, name);

  const classes = classNames({
    hookedField: true,
    'hookedField--row': inOneRow,
    'hookedField--hidden': hidden,
    invalid: !!error,
    [className as string]: !!className,
  });

  return (
    <Field
      className={classes}
      label={label}
      id={id}
      caption={caption}
      error={error && error.message?.toString()}
    >
      <Controller
        control={control}
        name={name}
        render={
          // @ts-ignore
          ({ field: { value, onChange } }) => fields[type]({ value, onChange })
        }
      />
    </Field>
  );
};

export default HookedField;
