import * as React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { publicRoutes } from '../../router';
import NotFoundPage from '../NotFoundPage';
import AuthLayout from '../../components/layout/AuthLayout';
import { useAppSelector } from 'hooks';

const Auth = () => {
  const { isLoggedIn } = useAppSelector((state) => state.auth);

  return isLoggedIn ? (
    <Navigate to='/main/documents' />
  ) : (
    <Routes>
      <Route path='*' element={<NotFoundPage />} />
      <Route
        path='/'
        element={
          <AuthLayout>
            <Outlet />
          </AuthLayout>
        }
      >
        {publicRoutes.map((route, idx) => {
          const Component = route.component;
          return <Route key={idx} path={route.path} element={<Component />} />;
        })}
      </Route>
      <Route index element={<Navigate to='sign-in' />} />
    </Routes>
  );
};

export default Auth;
