import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import Loader from 'components/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DocumentVerificationStatuses } from 'utils/types';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import Heading from '../../../components/ui/Heading';
import {
  getDocument,
  resetCurrentDocument,
  signDocument,
  updateDocumentVerificationStatus,
  updateFile,
  viewDocument,
} from '../../../store/documents/documentsSlice';
import PdfViewer from '../../../components/PdfViewer';
import SignersList from './components/SignersList';

import Supervisor from './components/Supervisor';
import Owner from './components/Owner';
import { IParentSize, ISignatureOptions } from '../../../utils/functions';

import SignatureDragger from '../../../components/SignatureDragger';

import './DocumentViewPage.scss';
import ReactSignatureCanvas from 'react-signature-canvas';
import SignerButtons from './components/SignerButtons';
import SupervisorButtons from './components/SupervisorButtons';
import TitleButtons from './components/TitleButtons';
import { TElementsByPages } from './utils/types';
import DrawerModal from './components/modals/DrawerModal';
import SignDocumentModal from './components/modals/SignDocumentModal';

const iFrameSrc = process.env.REACT_APP_FORM_URL || 'https://iframe.docs.edein.name/';

const DocumentViewPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const uploaderRef = useRef<HTMLInputElement>(null);
  const canvasRef = useRef<ReactSignatureCanvas>(null);
  const [openSignDocument, setOpenSignDocument] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const { pageNumber } = useAppSelector((state) => state.documents.pdf);
  const [signatureSrc, setSignatureSrc] = useState<string>('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [elementsByPages, setElementsByPages] = useState<TElementsByPages>({});
  const [relativeOptions, setRelativeOptions] = useState({ width: 0, height: 0 });

  const { user } = useAppSelector((state) => state.profile);
  const { document, getDocumentLoading } = useAppSelector((state) => state.documents);

  const defaultSignatureSize = useMemo(
    () => ({
      x: 0,
      y: 0,
      width: 25,
      height: ((relativeOptions.width / 100) * 18.75) / (relativeOptions.height / 100),
    }),
    [relativeOptions],
  );

  const addSignatureOnPageHandler = () =>
    setElementsByPages((oldElements) => ({
      ...oldElements,
      [pageNumber]: oldElements[pageNumber]
        ? [...oldElements[pageNumber], defaultSignatureSize]
        : [defaultSignatureSize],
    }));

  const changeSignatureOptionsHandler = (index: number, newData: Partial<ISignatureOptions>) =>
    setElementsByPages((oldElements) => ({
      ...oldElements,
      [pageNumber]: [
        ...oldElements[pageNumber].slice(0, index),
        { ...oldElements[pageNumber][index], ...newData },
        ...oldElements[pageNumber].slice(index + 1),
      ],
    }));

  const removeSignatureHandler = (removeIndex: number) => {
    setElementsByPages((oldElements) => ({
      ...oldElements,
      [pageNumber]: oldElements[pageNumber].filter((_element, index) => index !== removeIndex),
    }));
  };

  const arePagesEmpty = useMemo(
    () =>
      Object.keys(elementsByPages).reduce<boolean>(
        (previousValue, currentValue) =>
          elementsByPages[currentValue].length ? false : previousValue,
        true,
      ),
    [elementsByPages],
  );

  const onMessageFrame = (e: any) => {
    if (!document) {
      return;
    }
    if (e.origin + '/' === iFrameSrc || e.origin === 'http://localhost:4242') {
      const signature = e.data;
      if (signature) {
        const data = {
          file: new File(signature.sign, signature.fileName, { type: 'application/octet-stream' }),
        };
        dispatch(signDocument({ id: document.id, file: data }));
        setOpenSignDocument(false);
      }
    }
  };

  const uploadFileHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (!id) {
      return;
    }
    const file = event.target.files?.item(0);
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    dispatch(updateFile({ id, file: formData }));
  };

  const viewerResizeHandler = (options: IParentSize) => setRelativeOptions(options);

  const updateVerificationsStatus = (verificationStatus: DocumentVerificationStatuses) => {
    if (!id) {
      return;
    }
    dispatch(updateDocumentVerificationStatus({ id, verificationStatus }));
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    dispatch(getDocument({ id }));
    return () => {
      dispatch(resetCurrentDocument());
    };
  }, [id]);

  useEffect(() => {
    if (document) {
      const file = new File([document.file.src], document.file.filename, {
        type: 'application/octet-stream',
      });
      setFile(file);

      const userSignature = document.signers.find((signature) => signature.user.id === user?.id);
      if (
        userSignature &&
        !userSignature.signature &&
        (document.verificationStatus === DocumentVerificationStatuses.NONE ||
          document.verificationStatus === DocumentVerificationStatuses.APPROVED)
      )
        dispatch(viewDocument({ id: document.id }));

      if (
        document.verificationStatus === DocumentVerificationStatuses.PENDING &&
        document.supervisor?.id === user?.id
      )
        updateVerificationsStatus(DocumentVerificationStatuses.VIEWED);
    }

    window.addEventListener('message', onMessageFrame);
    return () => window.removeEventListener('message', onMessageFrame);
  }, [document]);

  if (getDocumentLoading) {
    return <Loader />;
  }

  return (
    <div className='documentViewPage'>
      {document?.name && (
        <Heading
          title={t('DOCUMENT_VIEW_PAGE.HEADING.TITLE', { name: document.name })}
          withBackBtn
          description={t('DOCUMENT_VIEW_PAGE.HEADING.DESCRIPTION', { type: document?.type.name })}
        />
      )}
      <div className='documentViewPage-grid'>
        <div className='documentViewPage-file disable-text-selection'>
          {document?.file.src ? (
            <PdfViewer file={document?.file.src} onResize={viewerResizeHandler}>
              {signatureSrc && elementsByPages[pageNumber]
                ? elementsByPages[pageNumber].map((element, index) => (
                    <SignatureDragger
                      key={index}
                      element={element}
                      onChange={(newData) => changeSignatureOptionsHandler(index, newData)}
                      onRemove={() => removeSignatureHandler(index)}
                      signatureSrc={signatureSrc}
                      relativeOptions={relativeOptions}
                      onAddSignatureOnPage={addSignatureOnPageHandler}
                      onDrawerOpen={setIsDrawerOpen}
                    />
                  ))
                : null}
            </PdfViewer>
          ) : null}
        </div>
        <div className='documentViewPage-info'>
          <TitleButtons uploaderRef={uploaderRef} />
          {document?.signers ? <SignersList signers={document.signers} /> : null}
          {document?.supervisor ? (
            <Supervisor
              supervisor={document.supervisor}
              verificationStatus={document.verificationStatus}
            />
          ) : null}
          {document?.owner ? (
            <>
              <Owner owner={document.owner} />
              <input
                type='file'
                accept='.pdf'
                ref={uploaderRef}
                hidden={true}
                onChange={uploadFileHandler}
              />
            </>
          ) : null}
          <div className='documentViewPage-actions'>
            <SupervisorButtons onSetVerificationStatus={updateVerificationsStatus} />
            <SignerButtons
              elementsByPages={elementsByPages}
              onAddSignatureOnPage={addSignatureOnPageHandler}
              onDrawerOpen={setIsDrawerOpen}
              onSetElementsByPages={setElementsByPages}
              onOpenSignDocument={setOpenSignDocument}
              signatureSrc={signatureSrc}
              onSetSignatureSrc={setSignatureSrc}
              openSignDocument={openSignDocument}
              arePagesEmpty={arePagesEmpty}
            />
          </div>
        </div>
      </div>
      <SignDocumentModal
        iFrameSrc={iFrameSrc}
        openSignDocument={openSignDocument}
        onOpenSignDocument={setOpenSignDocument}
        file={file}
      />
      <DrawerModal
        ref={canvasRef}
        canvasRef={canvasRef}
        isDrawerOpen={isDrawerOpen}
        onSetDrawerOpen={setIsDrawerOpen}
        onSetSignatureSrc={setSignatureSrc}
        onAddSignatureOnPage={addSignatureOnPageHandler}
        arePagesEmpty={arePagesEmpty}
      />
    </div>
  );
};

export default DocumentViewPage;
